import inRange from 'lodash/inRange';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import {action, toJS} from 'mobx';
import {
    type OperatorFunction,
    type MonoTypeOperatorFunction,
    type Observable,
    fromEvent,
    merge,
    timer,
    EMPTY,
    combineLatest,
    from,
    BehaviorSubject,
    of,
    iif,
    NEVER,
    asyncScheduler,
} from 'rxjs';
import {
    filter,
    tap,
    map,
    shareReplay,
    throttleTime,
    startWith,
    mapTo,
    pairwise,
    share,
    distinctUntilChanged,
    switchMap,
    switchMapTo,
    combineLatestWith,
    takeUntil,
    first,
    delay,
    endWith,
    withLatestFrom,
    take,
    catchError,
    scan,
} from 'rxjs/operators';

import {defaultPlaybackData} from '..';
// eslint-disable-next-line no-duplicate-imports
import type PlayerState from '..';
import {
    type PlayerLayoutStyleValue,
    PLAYER_LAYOUT_STYLE,
    PLAYER_LAYOUT_STYLE_TO_NUM_VISIBLE_SCREENS,
    SKIP_MARKER_HIDE_DELAY_MS,
    TRANSITION_DURATION_MS,
} from '../../utils/constants';
import getNextLayout from '../../utils/get-next-layout';
import observableFromCallback from '../../utils/observable-from-callback';
import type {
    GlobalState,
    GlobalStateStreamsToSubscribe,
    Interaction,
    Marker,
    PlayerStateSubjects,
    StillWatching,
    UpNext,
    VideoState,
    VideoStreamsPerScreen,
} from '../types';
import type {InferVideoStateValueType} from './video-state';

/**
 *
 * @param options - see below
 * @param videoStreams - An array of observables representing the video state of each screen
 * @param playerStateInstance - The current state instance
 * @param subjects - an object containing our subjects
 * @returns - The global state streams
 */

type Params = {
    playerStateInstance: PlayerState;
    videoStreams?: VideoStreamsPerScreen | null;
    subjects: PlayerStateSubjects;
};

export type CleanUp = () => void | Observable<never>;

export default function createGlobalStateStreams({
    playerStateInstance,
    videoStreams,
    subjects,
}: Params): {
    streamsToSubscribe: GlobalStateStreamsToSubscribe;
    streams: Record<string, never>;
} {
    const cleanUpNextFnSubject: BehaviorSubject<null | CleanUp> =
        new BehaviorSubject<CleanUp | null>(null);
    const cleanStillWatchingFnSubject: BehaviorSubject<CleanUp | null> =
        new BehaviorSubject<CleanUp | null>(null);

    const {globalState, screenConfigs, generalConfig, globalActions} =
        playerStateInstance;

    type InferValueType<T extends keyof GlobalState> = GlobalState[T];

    const updateGlobalState = <T extends keyof GlobalState>(
        fieldName: T
    ): OperatorFunction<InferValueType<T>, InferValueType<T>> =>
        tap(
            action((value: InferValueType<T>) => {
                if (globalState) {
                    globalState[fieldName] = value;
                }
            })
        );

    const updateTheme = (
        fieldName: string
    ): MonoTypeOperatorFunction<string | boolean | Record<string, string>> =>
        tap(
            action((value: string | boolean | Record<string, string>) => {
                if (generalConfig?.theme) {
                    generalConfig.theme[fieldName] = value;
                }
            })
        );

    const updateVideoStateByIndex = <T extends keyof VideoState>(
        fieldName: T
    ): MonoTypeOperatorFunction<[number, InferVideoStateValueType<T>]> =>
        tap(
            action(([index, value]: [number, InferVideoStateValueType<T>]) => {
                // On split view, we only show centre controls on hovered.
                // -1 means user is not hoverring on any tile.
                if (index === -1) {
                    screenConfigs?.forEach((config) => {
                        config.videoState[fieldName] =
                            false as InferVideoStateValueType<T>;
                    });

                    return;
                }

                const currentScreenConfig = screenConfigs?.[index];

                if (currentScreenConfig) {
                    currentScreenConfig.videoState[fieldName] = value;
                }
            })
        );

    const INTERACTION_THROTTLE_MS = 1000;
    const throttledInteractionType$ = subjects.interactionSubject.pipe(
        throttleTime(INTERACTION_THROTTLE_MS, asyncScheduler, {
            trailing: false,
            leading: true,
        }),
        startWith({type: null}),
        share()
    );

    const clicks$ = throttledInteractionType$.pipe(
        filter(({type}) => type === 'click'),
        mapTo(true),
        share()
    );

    const mouseMoves$ = throttledInteractionType$.pipe(
        filter(({type}) => type === 'mousemove'),
        mapTo(true),
        share()
    );

    const wheels$ = throttledInteractionType$.pipe(
        filter(({type}) => type === 'wheel'),
        mapTo(true),
        share()
    );

    const isLeftOrRightKey = (key?: string): boolean =>
        !!key && ['ArrowLeft', 'ArrowRight'].includes(key);
    const skipByArrowKeyDown$ = throttledInteractionType$.pipe(
        filter(
            ({type, key}: Interaction) =>
                type === 'keydown' && isLeftOrRightKey(key)
        ),
        mapTo(true),
        share()
    );

    const tabKeyDown$ = throttledInteractionType$.pipe(
        filter(
            ({type, key}: Interaction) => type === 'keydown' && key === 'Tab'
        ),
        mapTo(true),
        share()
    );

    // The index of a screen that is being hovered / focused for Split View.
    const focusedScreenIndex$ = subjects.focusedScreenIndexSubject.pipe(
        distinctUntilChanged(),
        startWith(-1),
        updateGlobalState('focusedScreenIndex'),
        shareReplay({refCount: true, bufferSize: 1})
    );

    // The index of a screen that is being clicked for Split View.
    // Active video's playback (play, volume, etc) can be changed using lower controls.
    const activeScreenIndex$ = subjects.activeScreenIndexSubject.pipe(
        distinctUntilChanged(),
        tap(
            action((index: number) => {
                if (globalState) {
                    globalState.activeScreenIndex = index;
                }
            })
        ),
        shareReplay({refCount: true, bufferSize: 1})
    );

    const activeScreenStreams$ = activeScreenIndex$.pipe(
        map((index) => videoStreams?.[index]),
        shareReplay({refCount: true, bufferSize: 1})
    );

    /**
     * Up Next & Are You Still Watching
     */

    const lastInteractedTimeStamp$ = throttledInteractionType$.pipe(
        map(() => new Date().getTime()),
        startWith(new Date().getTime()),
        shareReplay({refCount: true, bufferSize: 1})
    );

    const getUpNextStartTimeInSeconds = (
        assetDurationInSeconds: number,
        creditStartTimeInSeconds?: number,
        upNextDurationInSeconds?: number
    ): number | undefined =>
        Number.isFinite(creditStartTimeInSeconds)
            ? creditStartTimeInSeconds
            : assetDurationInSeconds - Number(upNextDurationInSeconds);

    const isUpNextEnabled = ({
        upNext,
        layoutType,
    }: {
        upNext: UpNext | null;
        layoutType: PlayerLayoutStyleValue;
    }): boolean => !!upNext && layoutType === PLAYER_LAYOUT_STYLE.LAYOUT_SINGLE;

    const hasReachedUpNextStartTime = ({
        upNext,
        currentTime,
    }: {
        upNext: UpNext | null;
        currentTime: number;
    }): boolean => {
        const videoState = playerStateInstance.activeScreenConfig?.videoState;

        if (!videoState) {
            return false;
        }

        const {duration, isLiveStream, isLiveEnded} = videoState;

        if (isLiveStream && !isLiveEnded) {
            return false;
        }

        const videoElement =
            playerStateInstance.screenConfigs?.[0]?.videoElement;

        let currentTimeToUse: number;
        let durationToUse: number;

        if (
            isLiveEnded &&
            videoElement?.currentTime &&
            videoElement?.duration &&
            Number.isFinite(videoElement.duration)
        ) {
            currentTimeToUse = videoElement.currentTime;
            durationToUse = videoElement.duration;
        } else {
            currentTimeToUse = currentTime;
            durationToUse = duration;
        }

        const startTime = getUpNextStartTimeInSeconds(
            durationToUse, // This is durationAdsRemoved
            upNext?.startTimeInSeconds,
            upNext?.durationInSeconds
        );

        return Number(startTime) > 0 && currentTimeToUse >= Number(startTime);
    };

    const activeScreenCurrentTime$ = activeScreenStreams$.pipe(
        switchMap((activeScreenStream) =>
            activeScreenStream
                ? activeScreenStream.currentTimeAdsRemoved$
                : EMPTY
        )
    );

    const activeScreenFirstPlay$ = activeScreenStreams$.pipe(
        switchMap((activeScreenStream) =>
            activeScreenStream ? activeScreenStream.firstPlay$ : EMPTY
        )
    );

    const activeScreenPlaybackData$ = activeScreenStreams$.pipe(
        switchMap((activeScreenStream) =>
            activeScreenStream ? activeScreenStream.playbackData$ : EMPTY
        ),
        shareReplay({refCount: true, bufferSize: 1})
    );

    const upNextData$: Observable<UpNext | null> =
        subjects.upNextDataSubject.pipe(
            // To make sure we get the latest upNext object
            distinctUntilChanged(isEqual),
            tap(() => {
                subjects.clearUpNextSubject.next();
                subjects.clearStillWatchingSubject.next();
            }),
            shareReplay({refCount: true, bufferSize: 1})
        );

    const stillWatchingData$: Observable<StillWatching> =
        activeScreenPlaybackData$.pipe(
            map(
                (activeScreenPlaybackData) =>
                    activeScreenPlaybackData?.stillWatching
            ),
            map((data) => toJS(data)),
            distinctUntilChanged(isEqual),
            shareReplay({refCount: true, bufferSize: 1})
        );

    const metadataLoaded$ = activeScreenStreams$.pipe(
        switchMap((activeScreenStream) =>
            activeScreenStream ? activeScreenStream.eventMetadataLoaded$ : EMPTY
        ),
        share()
    );

    const hasCreditMarkerReached$ = combineLatest({
        upNext: upNextData$,
        layoutType: subjects.splitViewLayoutTypeSubject,
    }).pipe(
        map(isUpNextEnabled),
        switchMap((isAvailable) =>
            iif(
                () => isAvailable,
                upNextData$.pipe(
                    switchMap((upNext) =>
                        activeScreenCurrentTime$.pipe(
                            map((currentTime) => {
                                const hasFiredFirstPlay =
                                    playerStateInstance.activeScreenConfig
                                        ?.videoState?.hasFiredFirstPlay;

                                if (hasFiredFirstPlay) {
                                    return hasReachedUpNextStartTime({
                                        upNext,
                                        currentTime,
                                    });
                                } else {
                                    return false;
                                }
                            }),
                            takeUntil(subjects.clearUpNextSubject),
                            endWith(false)
                        )
                    )
                ),
                of(false)
            )
        ),
        startWith(false),
        distinctUntilChanged(),
        shareReplay({refCount: true, bufferSize: 1})
    );

    const closeOverlaysOnUpNext$ = hasCreditMarkerReached$.pipe(
        filter(Boolean),
        tap(() => {
            if (playerStateInstance.globalActions === null) {
                return;
            }

            const {setTrayVisibility, setHudVisibility} =
                playerStateInstance.globalActions;

            setTrayVisibility({lower: false, upper: false});
            setHudVisibility(false);
        })
    );

    const isStillWatchingMode$ = merge(
        hasCreditMarkerReached$,
        subjects.clearStillWatchingSubject.pipe(mapTo(false))
    ).pipe(
        switchMap((hasCreditMarkerReached) =>
            hasCreditMarkerReached
                ? stillWatchingData$.pipe(
                      map(
                          (stillWatchingData) =>
                              stillWatchingData?.inactiveTimeInSeconds
                      ),
                      withLatestFrom(lastInteractedTimeStamp$),
                      map(
                          ([inactiveSeconds, lastIntreactedTime]) =>
                              !!inactiveSeconds && // Disable are you still watching feature if not provided
                              new Date() >
                                  new Date(
                                      lastIntreactedTime +
                                          Number(inactiveSeconds) * 1_000
                                  )
                      )
                  )
                : of(false)
        ),
        startWith(false),
        distinctUntilChanged(),
        updateGlobalState('isStillWatchingMode'),
        shareReplay({refCount: true, bufferSize: 1})
    );

    const renderStillWatchingElement$ = isStillWatchingMode$.pipe(
        filter(Boolean),
        switchMapTo(
            stillWatchingData$.pipe(
                combineLatestWith(subjects.stillWatchingElementSubject),
                switchMap(([stillWatchingData, element]) => {
                    if (!element || !stillWatchingData) {
                        return EMPTY;
                    }

                    return from(
                        stillWatchingData?.render({
                            element,
                            onContinue: () => {
                                subjects.clearStillWatchingSubject.next();
                            },
                        })
                    );
                }),
                catchError(() => EMPTY),
                tap(() => {
                    // Pause video when are you still watching is enabled.
                    const playerTech =
                        playerStateInstance.activeScreenConfig?.playerTech;

                    if (playerTech?.isPlaying) {
                        playerTech?.pause();
                    }
                }),
                tap(
                    (cleanUp) => void cleanStillWatchingFnSubject.next(cleanUp)
                ),
                takeUntil(subjects.clearStillWatchingSubject)
            )
        ),
        mapTo(null),
        distinctUntilChanged()
    );

    const stillWatchingCleanUp$ = subjects.clearStillWatchingSubject.pipe(
        withLatestFrom(cleanStillWatchingFnSubject),
        tap(([, cleanUp]) => {
            if (!cleanUp) {
                return;
            }

            cleanUp();
        }),
        mapTo(null),
        distinctUntilChanged()
    );

    const isUpNextMode$: Observable<boolean> = isStillWatchingMode$.pipe(
        combineLatestWith(hasCreditMarkerReached$),
        // We only show Up Next when it is not in still watching mode
        map(
            ([isStillWatching, hasCreditMarkerReached]) =>
                hasCreditMarkerReached && // only go into up-next mode if the end credits marker has been reached
                !isStillWatching && // don't go into up-next mode if the user isn't still watching
                !!generalConfig?.shouldAutoplayUpNext?.() // only go into up-next mode if autoplay is enabled
        ),
        startWith(false),
        distinctUntilChanged(),
        updateGlobalState('isUpNextMode'),
        shareReplay({refCount: true, bufferSize: 1})
    );

    const renderUpNextElement$ = isUpNextMode$.pipe(
        switchMap((isInUpNextMode) =>
            isInUpNextMode
                ? upNextData$.pipe(
                      withLatestFrom(
                          subjects.upNextElementSubject,
                          activeScreenIndex$
                      ),
                      switchMap(([upNextData, element, activeScreenIndex]) => {
                          if (!element) {
                              return EMPTY;
                          } else if (upNextData?.render) {
                              return from(
                                  upNextData.render({
                                      element,
                                      onCancel: () =>
                                          void subjects.clearUpNextSubject.next(),
                                      screenIndex: activeScreenIndex,
                                  })
                              );
                          } else {
                              return EMPTY;
                          }
                      }),
                      catchError(() => EMPTY),
                      tap(() => {
                          // Play video after the video is paused by Are You Still Watching.
                          const playerTech =
                              playerStateInstance.activeScreenConfig
                                  ?.playerTech;

                          if (!playerTech?.isPlaying) {
                              playerTech?.play();
                          }
                      }),
                      tap((cleanUp) => void cleanUpNextFnSubject.next(cleanUp)),
                      takeUntil(subjects.clearUpNextSubject)
                  )
                : EMPTY
        )
    );

    // When user is ending the up next state, we will use the last clean up function,
    // which is provided by the user to umount the element from DOM.
    const upNextCleanUp$ = subjects.clearUpNextSubject.pipe(
        withLatestFrom(cleanUpNextFnSubject),
        tap(([, cleanUp]) => {
            if (!cleanUp) {
                return;
            }

            cleanUp();
        })
    );

    /**
     * Prolonged Pause
     */
    const activeIsOutOfLongPausing$ = activeScreenStreams$.pipe(
        switchMap((activeScreenStream) =>
            activeScreenStream ? activeScreenStream.isOutOfLongPausing$ : EMPTY
        )
    );

    // The duration is specified by user (default 5 seconds)
    const timerToShowLongPause$ = timer(
        Number(generalConfig?.waitMsShowingLongPause)
    );
    const after10SecondsOfPause$ = activeScreenStreams$.pipe(
        switchMap((activeScreenStream) =>
            activeScreenStream ? activeScreenStream.isPausedFor5Seconds$ : EMPTY
        ),
        switchMapTo(
            timerToShowLongPause$.pipe(takeUntil(activeIsOutOfLongPausing$))
        )
    );

    /**
     * Visibility Controls
     */
    const uiInteraction$ = merge(
        clicks$,
        mouseMoves$,
        skipByArrowKeyDown$,
        wheels$,
        tabKeyDown$
    ).pipe(share());

    // Show the controls when the active video is paused or errored
    const generalControlsVisibility$ = activeScreenStreams$.pipe(
        switchMap((activeScreenStreams) => {
            const isPlaying$ = activeScreenStreams
                ? activeScreenStreams.isPlaying$
                : EMPTY;
            const playerTechErrorCode$ = activeScreenStreams
                ? activeScreenStreams.playerTechErrorCode$
                : EMPTY;

            return combineLatest([isPlaying$, playerTechErrorCode$]);
        }),
        map(([isPlaying, errorCode]) => !isPlaying || !!errorCode),
        shareReplay({refCount: true, bufferSize: 1})
    );

    const TRAY_TIMEOUT_IN_MS = 15_000;

    const trayAutoRetractTimer$ = uiInteraction$.pipe(
        startWith(null), // start timer immediately regardless emission of uiInteractions$
        switchMap(() => timer(TRAY_TIMEOUT_IN_MS).pipe(mapTo(false))),
        take(1),
        startWith(true)
    );

    const isUpperTrayVisible$ = subjects.isUpperTrayVisibleSubject.pipe(
        switchMap((visible) =>
            iif(() => visible, trayAutoRetractTimer$, of(false))
        ),
        distinctUntilChanged(),
        startWith(false),
        updateGlobalState('isUpperTrayVisible'),
        shareReplay({refCount: true, bufferSize: 1})
    );

    const isLowerTrayVisible$ = subjects.isLowerTrayVisibleSubject.pipe(
        switchMap((visible) =>
            iif(() => visible, trayAutoRetractTimer$, of(false))
        ),
        distinctUntilChanged(),
        startWith(false),
        updateGlobalState('isLowerTrayVisible'),
        shareReplay({refCount: true, bufferSize: 1})
    );

    const isHudVisible$ = subjects.isHudVisibleSubject.pipe(
        combineLatestWith(isUpNextMode$, isStillWatchingMode$),
        // Hide the HUD when in Up-Next/Are-You-Still-Watching mode.
        map(
            ([isVisible, isUpNextMode, isStillWatchingMode]) =>
                !isUpNextMode && !isStillWatchingMode && isVisible
        ),
        distinctUntilChanged(),
        startWith(false),
        updateGlobalState('isHudVisible'),
        updateTheme('isHudOpen'),
        shareReplay({refCount: true, bufferSize: 1})
    );

    const showingUpperAndLowerControls$ = merge(
        uiInteraction$,
        generalControlsVisibility$.pipe(filter(Boolean))
    ).pipe(mapTo(true), share());

    const CONTROLS_TIMEOUT_IN_MS = 5 * 1000;
    const countDownTimer$ = generalControlsVisibility$.pipe(
        switchMap((shouldShow) =>
            shouldShow ? EMPTY : timer(CONTROLS_TIMEOUT_IN_MS)
        )
    );
    const hidingUpperAndLowerControls$ = merge(
        after10SecondsOfPause$,
        showingUpperAndLowerControls$.pipe(switchMapTo(countDownTimer$))
    ).pipe(mapTo(false), share());

    const isScrubberKeyInteracted$ = merge(
        skipByArrowKeyDown$.pipe(mapTo(true)),
        hidingUpperAndLowerControls$
    ).pipe(
        distinctUntilChanged(),
        updateGlobalState('isScrubberKeyInteracted')
    );

    // that is, whether controls should be visible if not for tray, upnext or still-watching
    const shouldControlsBeVisibile$ = merge(
        showingUpperAndLowerControls$,
        hidingUpperAndLowerControls$
    ).pipe(distinctUntilChanged(), share());

    const updateUpNextData$ = merge(activeScreenFirstPlay$).pipe(
        switchMap(() =>
            observableFromCallback((callback: (value: UpNext) => void) =>
                globalActions?.updateUpNextData(
                    playerStateInstance?.activeScreenConfig?.playbackData,
                    callback
                )
            )
        ),
        tap(
            action((upNext: UpNext) => {
                playerStateInstance.setUpNext(upNext);
            })
        )
    );

    /**
     * The goal of this stream is to emit when a marker button's visibility toggles
     * Example: if the third button is to be hidden, it emits `[2, false]`
     */
    const markerButtonsVisibilityMap$ = activeScreenPlaybackData$
        .pipe(
            switchMap((playbackData) =>
                merge(
                    ...(playbackData?.markers || []).map<
                        Observable<[number, boolean]>
                    >((marker: Marker, index: number) => {
                        const isMarkerInRange$ = activeScreenCurrentTime$.pipe(
                            map((currentTime) =>
                                inRange(
                                    currentTime,
                                    marker.startTime,
                                    marker.endTime
                                )
                            ),
                            distinctUntilChanged(),
                            share()
                        );
                        const markerEntersRangeSignal$ = isMarkerInRange$.pipe(
                            filter(Boolean)
                        );

                        // Within 15 seconds of events of
                        // 1, it entering range
                        // 2, UI interaction,
                        // 3, initialisation (force restart timer when playback starts)
                        const mayMarkerShow$ = merge(
                            metadataLoaded$,
                            markerEntersRangeSignal$,
                            shouldControlsBeVisibile$.pipe(filter(Boolean))
                        ).pipe(
                            switchMap(() =>
                                timer(SKIP_MARKER_HIDE_DELAY_MS).pipe(
                                    mapTo(false),
                                    startWith(true)
                                )
                            ),
                            startWith(false),
                            distinctUntilChanged()
                        );

                        return combineLatest(
                            isMarkerInRange$,
                            mayMarkerShow$
                        ).pipe(
                            map(
                                ([isMarkerInRange, mayMarkerShow]) =>
                                    isMarkerInRange && mayMarkerShow
                            ),
                            distinctUntilChanged(),
                            map((isMarkerVisible) => [index, isMarkerVisible])
                        );
                    })
                )
            )
        )
        .pipe(
            tap(([markerIndex, markerVisibility]) => {
                /** Doesn't need a reset between playbacks - new data should just work */
                if (globalState?.markerButtonsVisibilityMap) {
                    globalState.markerButtonsVisibilityMap[markerIndex] =
                        markerVisibility;
                }
            })
        );

    const controlsShownOnUserInteractionSeq$ = shouldControlsBeVisibile$.pipe(
        filter(Boolean),
        scan((counter) => counter + 1, 0),
        updateGlobalState('controlsShownOnUserInteractionSeq')
    );

    const upperControlsVisibility$ = shouldControlsBeVisibile$.pipe(
        combineLatestWith(
            isLowerTrayVisible$,
            isUpperTrayVisible$,
            isUpNextMode$,
            isStillWatchingMode$
        ),
        // Hide upper controls during Are You Still Watching mode, or when upper tray is visible.
        // Upper controls will show during Up Next mode
        map(
            ([
                isShowing,
                isLowerTrayVisible,
                isUpperTrayVisible,
                isUpNextMode,
                isStillWatchingMode,
            ]) =>
                !isLowerTrayVisible &&
                !isUpperTrayVisible &&
                !isStillWatchingMode &&
                (isUpNextMode || isShowing)
        ),
        startWith(true),
        distinctUntilChanged(),
        updateGlobalState('isUpperControlsVisible'),
        share()
    );

    const lowerControlsVisibility$ = merge(
        showingUpperAndLowerControls$,
        hidingUpperAndLowerControls$
    ).pipe(
        combineLatestWith(
            isLowerTrayVisible$,
            isUpNextMode$,
            isStillWatchingMode$
        ),
        // Hide lower controls when lower tray is visible, or in Up-Next/Are-You-Still-Watching mode.
        map(
            ([
                isShowing,
                isLowerTrayVisible,
                isUpNextMode,
                isStillWatchingMode,
            ]) =>
                !isLowerTrayVisible &&
                !isStillWatchingMode &&
                !isUpNextMode &&
                isShowing
        ),
        startWith(true),
        distinctUntilChanged(),
        updateGlobalState('isLowerControlsVisible'),
        share()
    );

    const isCentreControlsVisible$ = focusedScreenIndex$.pipe(
        combineLatestWith(
            lowerControlsVisibility$,
            generalControlsVisibility$,
            isUpNextMode$,
            isStillWatchingMode$
        ),
        map(
            ([
                index,
                lowerControlsVisibility,
                shouldShow,
                isUpNextMode,
                isStillWatchingMode,
            ]) => [
                index,
                // Hide centre controls during Up Next and Are You Still Watching modes
                !isStillWatchingMode &&
                    !isUpNextMode &&
                    (shouldShow || lowerControlsVisibility),
            ]
        ),
        map(([index, isVisible]) => [
            index,
            // On Split View, the Centre Overlay is controlled by whether the screen
            // is being focused (hovered) or not.
            playerStateInstance.isSingleLayout ? isVisible : true,
        ]),
        distinctUntilChanged(isEqual),
        updateVideoStateByIndex('isCentreControlsVisible'),
        shareReplay({refCount: true, bufferSize: 1})
    );

    /**
     * Video Title
     *
     * The title of the video should replace default copy of the left arrow on upper controls,
     * after a duration has passed.
     */
    const shouldUseVideoTitle$ = upperControlsVisibility$.pipe(
        filter((isVisible) => !isVisible),
        first(),
        delay(TRANSITION_DURATION_MS),
        mapTo(true),
        updateGlobalState('shouldUseVideoTitle')
    );

    /**
     * Volume Control
     */
    const activeScreenVolumeAndIsMuted$ = merge(
        // One for each screen, so we know when any change
        ...(playerStateInstance.screenConfigs?.map(
            ({videoElement, playerTech}, screenIndex) =>
                fromEvent(videoElement, 'volumechange').pipe(
                    // Ignore it if it doesn't happen to the active screen (e.g. because we changed active screen and muted the old one)
                    filter(
                        () => screenIndex === globalState?.activeScreenIndex
                    ),
                    // Intentionally using `map` instead of `mapTo` because we want to sample playerTech at execution time only
                    map(() => ({
                        volume: playerTech.volume,
                        isMuted: playerTech.muted,
                    }))
                )
        ) ?? [])
    ).pipe(
        tap(
            action(({volume, isMuted}: {isMuted: boolean; volume: number}) => {
                if (globalState) {
                    globalState.volume = volume;
                    globalState.isMuted = isMuted;
                }
            })
        )
    );

    const muteInactiveScreens$ = activeScreenIndex$.pipe(
        delay(0), // TODO: investigate async issue
        tap((activeScreenIndex) => {
            playerStateInstance?.screenConfigs?.forEach(
                ({playerTech}, screenIndex) => {
                    if (screenIndex === activeScreenIndex) {
                        if (globalState === null) {
                            return;
                        }

                        // It's active now! Give it the volume!
                        playerTech.volume = globalState.volume;
                        playerTech.muted = globalState.isMuted;
                    } else {
                        // It's inactive now - mute it!
                        playerTech.muted = true;
                    }
                }
            );
        })
    );

    /**
     * Fullscreen
     */
    const isFullscreen$ = merge(
        // Safari only emits the `webkitfullscreenchange` event. Firefox used to only emit `mozfullscreenchange`, but that's not the case anymore
        // Chrome et al use the standard `fullscreenchange`
        ...['fullscreenchange', 'webkitfullscreenchange'].map((eventName) =>
            fromEvent(document, eventName)
        )
    ).pipe(
        map(() => {
            const fullscreenElement =
                playerStateInstance.generalConfig?.fullscreenElement;

            return playerStateInstance.activeScreenConfig?.playerTech.checkElementIsFullscreen(
                fullscreenElement ?? document.body
            );
        }),
        distinctUntilChanged(),
        tap(
            action((isFullscreen?: boolean) => {
                if (globalState) {
                    globalState.isFullscreen = !!isFullscreen;
                }
            })
        )
    );

    /**
     * Split view
     */

    // hasReservedScreenAddedSubject will send pings when a screen is due to have content (reserved)
    // numOfScreensWithContent will take that into consideration to allocate a space for the loading content.
    const numOfScreensWithContent$ = merge(
        subjects.incomingPlaybackDataSubject,
        subjects.hasReservedScreenAddedSubject
    ).pipe(
        map(() => playerStateInstance.numOfScreensWithContent),
        shareReplay({refCount: true, bufferSize: 1})
    );

    const setNewLayout$ = subjects.splitViewLayoutTypeSubject.pipe(
        distinctUntilChanged(),
        tap(function resetInvisibleScreens(layoutStyle) {
            const {globalState, screenConfigs} = playerStateInstance;

            globalState?.screenOrder.forEach((positionIndex, screenIndex) => {
                // reset a screen if it will be hidden from view (only reset screens that need to be reset)
                const screenWillBeHidden =
                    positionIndex >=
                    PLAYER_LAYOUT_STYLE_TO_NUM_VISIBLE_SCREENS[layoutStyle];
                const hasOrWillHaveContent =
                    (screenConfigs?.[screenIndex]?.playbackData?.sources
                        .length ?? 0) > 0 ||
                    !isNil(screenConfigs?.[screenIndex]?.reservationId);

                if (screenWillBeHidden && hasOrWillHaveContent) {
                    // This screen is now hidden! Reset its source
                    playerStateInstance.setPlaybackData(
                        {...defaultPlaybackData, sources: []},
                        screenIndex
                    );
                    const currentScreenConfig = screenConfigs?.[screenIndex];

                    if (currentScreenConfig) {
                        currentScreenConfig.reservationId = null;
                    }
                }
            });
        }),

        // Sync layout style to state
        tap((layoutStyle) => {
            if (playerStateInstance.globalState) {
                playerStateInstance.globalState.layoutStyle = layoutStyle;
            }
        }),

        pairwise(),

        // Analytics: Enter split view
        tap(([oldLayoutStyle]) => {
            if (
                playerStateInstance.generalConfig &&
                oldLayoutStyle === 'Standard'
            ) {
                playerStateInstance.generalConfig.onPlayerInteractionByType(
                    'enter-splitview'
                );
            }
        }),

        // HACK: Disable Up Next in split view because its state should be in video-state.js, not here, but we're not ready to refactor.
        // TODO: Move up next state to video-state
        switchMap(([oldLayoutStyle, layoutStyle]) => {
            // Disable Up Next for split view
            if (oldLayoutStyle === 'Standard') {
                return of(null);
            }

            // Refresh and enable Up Next for single view
            if (layoutStyle === 'Standard') {
                return observableFromCallback(
                    (callback: (value: UpNext) => void) =>
                        globalActions?.updateUpNextData(
                            playerStateInstance.activeScreenConfig
                                ?.playbackData,
                            callback
                        )
                );
            }

            // Switching between two different split view layouts? Nothing to do.
            return NEVER;
        }),

        // Sync up next to player state
        tap(
            action((upNext: UpNext | null) => {
                playerStateInstance.setUpNext(upNext);
            })
        )
    );

    const selectNewActiveScreenOnLayoutChange$ =
        subjects.splitViewLayoutTypeSubject.pipe(
            distinctUntilChanged(),
            withLatestFrom(activeScreenIndex$),
            tap(([layoutType, activeScreenIndex]) => {
                const {globalState} = playerStateInstance;
                const availableScreenSlots =
                    PLAYER_LAYOUT_STYLE_TO_NUM_VISIBLE_SCREENS[layoutType];
                const hasRemoved =
                    Number(globalState?.screenOrder[activeScreenIndex]) >
                    availableScreenSlots - 1;

                // When an active screen is removed by layout change,
                // select screen 0 as active screen
                if (hasRemoved) {
                    const firstScreenIndex = globalState?.screenOrder.findIndex(
                        (position) => position === 0
                    );

                    if (firstScreenIndex !== undefined) {
                        playerStateInstance.globalActions?.setActiveScreenIndex(
                            firstScreenIndex
                        );
                    }
                }
            })
        );

    const openLowerTrayForSplitView$ = subjects.splitViewLayoutTypeSubject.pipe(
        withLatestFrom(numOfScreensWithContent$),
        tap(([layoutType, numOfContent]) => {
            const availableScreenSlots =
                PLAYER_LAYOUT_STYLE_TO_NUM_VISIBLE_SCREENS[layoutType];
            const isSingle = layoutType === PLAYER_LAYOUT_STYLE.LAYOUT_SINGLE;
            const hasEmptyScreens = availableScreenSlots > Number(numOfContent);

            // When selected a bigger layout, open tray for user to insert content.
            if (!isSingle && hasEmptyScreens) {
                playerStateInstance.globalActions?.setTrayVisibility({
                    lower: true,
                });
            }
        })
    );

    const setSplitViewLayout$ = combineLatest([
        subjects.isInSplitViewModeSubject,
        isLowerTrayVisible$,
    ]).pipe(
        map(
            ([isInSplitViewMode, isLowerTrayVisible]) =>
                isInSplitViewMode && isLowerTrayVisible
        ),
        switchMap((shouldAutoSwitchLayout) =>
            iif(
                () => shouldAutoSwitchLayout,
                // When the Lower Tray is opened and it is in Split View Mode,
                // we automatically add an extra screen (by upgrading Split View layout).
                numOfScreensWithContent$.pipe(
                    withLatestFrom(subjects.splitViewLayoutTypeSubject),
                    tap(([numOfContent, layoutType]) => {
                        const availableScreenSlots =
                            PLAYER_LAYOUT_STYLE_TO_NUM_VISIBLE_SCREENS[
                                layoutType
                            ];
                        const shouldKeepUserSelectedLayout =
                            availableScreenSlots > Number(numOfContent) ||
                            numOfContent ===
                                playerStateInstance.generalConfig?.numScreens;

                        const nextLayout = shouldKeepUserSelectedLayout
                            ? // If user already selected a layout that has empty screens, use it.
                              layoutType
                            : // Upgrade the current layout to add one additional screen.
                              getNextLayout(Number(numOfContent) + 1);

                        playerStateInstance.globalActions?.setLayoutStyle(
                            nextLayout
                        );
                    })
                ),
                // Removes any empty screens when an user closes the Lower Tray (by downgrading Split View layout)
                numOfScreensWithContent$.pipe(
                    withLatestFrom(subjects.splitViewLayoutTypeSubject),
                    take(1),
                    tap(([numOfContent, layoutType]) => {
                        if (layoutType === PLAYER_LAYOUT_STYLE.LAYOUT_SINGLE) {
                            return;
                        }

                        const availableScreenSlots =
                            PLAYER_LAYOUT_STYLE_TO_NUM_VISIBLE_SCREENS[
                                layoutType
                            ];
                        const hasUserAddedContentToAllScreens =
                            availableScreenSlots === numOfContent;

                        if (!hasUserAddedContentToAllScreens) {
                            const {globalState, screenConfigs} =
                                playerStateInstance;

                            if (
                                globalState === null ||
                                screenConfigs === null
                            ) {
                                return;
                            }

                            const sortedByPosition = globalState.screenOrder
                                .map((position, screenIndex) => ({
                                    screenIndex,
                                    position,
                                }))
                                .sort(
                                    (
                                        {position: positionA},
                                        {position: positionB}
                                    ) => positionA - positionB
                                );

                            /**
                             * We are swaping EMPTY screens on the lower positions with NON-EMPTY ones on the higher postions.
                             * By doing this, we make sure that lower screens are filled with videos and higher screens are filled with empty ones.
                             * getNextLayout downgrades the layout to match numOfContent and higher-postioned screens will be hidden + removed.
                             * This is how we make sure that when closing lower tray, only empty screens are hidden + removed.
                             *
                             * Screen positions: (low) 0th, 1st, 2th, 3rd (high)
                             **/
                            let left = 0;
                            let right = sortedByPosition.length - 1;

                            while (left < right) {
                                const leftScreenIndex =
                                    sortedByPosition?.[left]?.screenIndex;
                                const rightScreenIndex =
                                    sortedByPosition?.[right]?.screenIndex;

                                const isLeftScreenEmpty =
                                    leftScreenIndex === undefined
                                        ? true
                                        : !screenConfigs[leftScreenIndex]
                                              ?.playbackData?.sources?.length;

                                const isRightScreenEmpty =
                                    rightScreenIndex === undefined
                                        ? true
                                        : !screenConfigs[rightScreenIndex]
                                              ?.playbackData?.sources?.length;

                                const swapScreenOrder = (): void => {
                                    if (
                                        leftScreenIndex !== undefined &&
                                        rightScreenIndex !== undefined
                                    ) {
                                        playerStateInstance.globalActions?.swapScreenOrder(
                                            leftScreenIndex,
                                            rightScreenIndex
                                        );
                                    }
                                };

                                if (isLeftScreenEmpty && !isRightScreenEmpty) {
                                    swapScreenOrder();
                                    left++;
                                    right--;
                                } else if (
                                    isLeftScreenEmpty &&
                                    isRightScreenEmpty
                                ) {
                                    right--;
                                } else {
                                    left++;
                                }
                            }

                            const nextLayout = getNextLayout(numOfContent);

                            playerStateInstance.globalActions?.setLayoutStyle(
                                nextLayout
                            );

                            if (
                                nextLayout === PLAYER_LAYOUT_STYLE.LAYOUT_SINGLE
                            ) {
                                playerStateInstance.globalActions?.setIsInSplitViewMode(
                                    false
                                );
                            }
                        }
                    })
                )
            )
        )
    );

    /**
     * onVideoEnd Logic
     *
     * We call onVideoEnd when video is ended without up next
     * OR
     * Up Next is cancelled by User manually and user plays video till the end.
     */
    const onVideoEndFn$ = activeScreenPlaybackData$.pipe(
        // To make sure we get the latest upNext object
        map((activeScreenPlaybackData) => activeScreenPlaybackData?.onVideoEnd)
    );

    const videoEnded$ = subjects.splitViewLayoutTypeSubject.pipe(
        switchMap((layoutType) =>
            iif(
                () => layoutType === PLAYER_LAYOUT_STYLE.LAYOUT_SINGLE,
                activeScreenStreams$.pipe(
                    switchMap((activeScreenStreams) =>
                        activeScreenStreams
                            ? activeScreenStreams.eventEnded$
                            : EMPTY
                    ),
                    switchMap(() =>
                        isUpNextMode$.pipe(
                            take(1),
                            filter((isInUpNext) => !isInUpNext),
                            withLatestFrom(onVideoEndFn$),
                            tap(([, onVideoEnd]) => {
                                if (onVideoEnd) {
                                    onVideoEnd();
                                }
                            }),
                            catchError(() => of(null))
                        )
                    )
                ),
                EMPTY
            )
        )
    );

    return {
        streamsToSubscribe: [
            activeScreenVolumeAndIsMuted$,
            muteInactiveScreens$,
            activeScreenIndex$,
            isFullscreen$,
            after10SecondsOfPause$,
            isCentreControlsVisible$,
            focusedScreenIndex$,
            shouldUseVideoTitle$,
            isUpNextMode$,
            isStillWatchingMode$,
            upperControlsVisibility$,
            lowerControlsVisibility$,
            renderUpNextElement$,
            upNextCleanUp$,
            closeOverlaysOnUpNext$,
            isScrubberKeyInteracted$,
            renderStillWatchingElement$,
            stillWatchingCleanUp$,
            isUpperTrayVisible$,
            isLowerTrayVisible$,
            isHudVisible$,
            videoEnded$,
            lastInteractedTimeStamp$,
            setNewLayout$,
            setSplitViewLayout$,
            numOfScreensWithContent$,
            openLowerTrayForSplitView$,
            selectNewActiveScreenOnLayoutChange$,
            markerButtonsVisibilityMap$,
            controlsShownOnUserInteractionSeq$,
            updateUpNextData$,
        ],
        streams: {},
    };
}
