import PlayerTech from './player-tech';
import PlaybackDash from './playback/dash';
import PlaybackEmpty from './playback/empty';
import PlaybackHls from './playback/hls';
import PlaybackNative from './playback/native';
import {
    IS_MUTED,
    USER_SELECTED_PLAYER_QUALITY,
    VOLUME,
} from './utils/storage-keys';
import {VIDEO_EVENTS} from './utils/video-events';

export type {
    PlayerTechOptions,
    SourceConfig,
    ErrorDetail,
    AudioPreferences,
    BaseBitrateInfo,
    ErrorDetailDiagnostics,
    PlayerQualityLevel,
    AudioTrack,
    DashDiagnostics,
    HlsDiagnostics,
    NativeDiagnostics,
    SupportedPlayerTechInstance
} from './types';
export type {TextTracks} from './playback/types';
export type {DashjsConfig} from './playback/dash/types';
export type {HlsjsConfig} from './playback/hls/types';

export type {default as MediaError} from './media-error';

export {ERROR_CODES} from './utils/error-codes';
export {
    setVideoQualityPreference,
    getVideoQualityPreference,
    removeVideoQualityPreference,
} from './utils/quality-settings';

export {version} from './version';
export default PlayerTech;

export const PLAYBACK_HANDLERS = {
    PlaybackDash,
    PlaybackEmpty,
    PlaybackHls,
    PlaybackNative,
};

export const STORAGE_KEYS = {
    IS_MUTED,
    USER_SELECTED_PLAYER_QUALITY,
    VOLUME,
};

export const EVENTS = {
    VIDEO: VIDEO_EVENTS,
};
