import React from 'react';
import ReactDOM from 'react-dom';
import bacon from 'baconjs';

import {renderToHtml} from '@fsa/fs-commons/lib/iso/render';
import {page as pageBoot}  from '@fsa/fs-commons/lib/iso/boot';
import {initialLaterOr, handleStreamForIsoRender} from '@fsa/fs-commons/lib/streams/helper';

import getPolledScorecard from '@fsa-streamotion/streamotion-web-fs-score-centre/src/js/widgets/card/polled-scorecard';
import getPenaltiesStream from '@fsa-streamotion/streamotion-web-fs-score-centre/src/js/streams/get-football-penalties-by-match-id';
import {DEFAULT_STATS_API_ENV, DEFAULT_COUPLER_API_ENV} from '@fsa-streamotion/streamotion-web-fs-score-centre/src/js/utils/constants';

import MatchCardComponent from '../components/match-card';

const EXPIRY_SECONDS = 30;

function MatchCard(element, settings) {
    this.element  = element;
    this.settings = settings;

    this.config = {
        bettingProvider: settings.bettingProvider,
        couplerApiEnv:   settings.couplerApiEnv || DEFAULT_COUPLER_API_ENV,
        matchId:         settings.matchId,
        sport:           settings.sport,
        statsApiEnv:     settings.statsApiEnv || DEFAULT_STATS_API_ENV,
        statsApiKey:     settings.statsApiKey
    };
}

MatchCard.prototype.init = function (initialData = false) {
    this.closeStreams = this.getData(initialData)
        .onValue(this.render.bind(this));
};

MatchCard.prototype.initIso = function () {
    return new Promise((onResolve, onReject) => {
        this.closeStreams = this.getData()
            .take(1)
            .subscribe((event) => handleStreamForIsoRender({
                onReject,
                onResolve,
                event,

                identifier:            'MatchCard',
                expirySecondOnFailure: 10,
                expirySecondOnSuccess: EXPIRY_SECONDS,
                render:                this.render.bind(this)
            }));
    });
};

MatchCard.prototype.initComponentStream = function (initialData = false) {
    const data         = this.getData(initialData);
    const reactElement = data.map((data) => <MatchCardComponent {...data.view} />);
    const iso          = data.map('.iso');

    return bacon.combineTemplate({data, reactElement, iso});
};

MatchCard.prototype.getData = function (initialData) {
    const scorecardStream = initialLaterOr(
        initialData && initialData.scorecardStream,
        getPolledScorecard({
            bettingProvider: this.config.bettingProvider,
            couplerApiEnv:   this.config.couplerApiEnv,
            matchId:         this.config.matchId,
            sport:           this.config.sport,
            statsApiEnv:     this.config.statsApiEnv,
            statsApiKey:     this.config.statsApiKey
        })
    );

    const penaltiesStream = initialLaterOr(
        initialData && initialData.penaltiesStream,
        scorecardStream
            .map('.period')
            .map((period) => ({
                couplerApiEnv: this.config.couplerApiEnv,
                matchId:       this.config.matchId,
                period,
                sport:       this.config.sport,
                statsApiEnv: this.config.statsApiEnv,
                statsApiKey: this.config.statsApiKey
            }))
            .flatMapLatest((game) => {
                if (game.sport === 'football' && game.period === 5) {
                    return getPenaltiesStream(game);
                } else {
                    return bacon.later(0, undefined);
                }
            })
    );

    return bacon.combineTemplate({
        iso: bacon.combineTemplate({scorecardStream, penaltiesStream}),
        view: bacon.combineWith(
            scorecardStream, penaltiesStream,
            (scorecard, penalties) => Object.assign({penalties}, scorecard)
        )
    });
};

MatchCard.prototype.render = function (data) {
    if (this.element) {
        ReactDOM.render(
            <MatchCardComponent {...data.view} />,
            this.element
        );
    } else {
        return renderToHtml(
            <MatchCardComponent {...data.view} />,
            'hawkwidgets-match-card',
            {
                settings: this.settings,
                data:     data.iso
            }
        );
    }
};

/**
 * Removes the widget from the page and closes streams
 *
 * @return {undefined}
 */
MatchCard.prototype.remove = function () {
    try {
        this.closeStreams();
    } catch (e) {} // eslint-disable-line no-empty

    try {
        if (this.element) {
            ReactDOM.unmountComponentAtNode(this.element);
        }
    } catch (e) {} // eslint-disable-line no-empty
};

export default function (element, settings) {
    return new MatchCard(element, settings);
}

/**
 * Calls the bootloader for the widget. The bootloader name is defined in fiso.js, e.g. 'video-mosaic'
 */
pageBoot(MatchCard, 'hawkwidgets-match-card');
