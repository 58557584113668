import type PlayerTech from '@fsa-streamotion/player-tech';

import {
    type Observable,
    type OperatorFunction,
    combineLatest,
    concat,
    distinctUntilChanged,
    EMPTY,
    filter,
    first,
    map,
    of,
    share,
    shareReplay,
    startWith,
    switchMap,
    tap,
    withLatestFrom,
} from 'rxjs';

import {
    type AdBreak,
    type Ad,
    calculateAdSecondsPassed,
    getCurrentAdData,
} from '../../utils/ad-capabilities';
import fromPromiseCallback from '../../utils/from-promise-callback';
import type {PlaybackData, VideoState} from '../types';
import type {
    InferVideoStateValueType,
    PeriodSwitchCompleted,
    PeriodSwitchStarted,
    ToStreamInfo,
} from './video-state';

export type SkipPlayedAdBreaks = [
    {
        toStreamInfo: ToStreamInfo;
    },
    {
        fromStreamInfo: {
            id?: string;
        };
        toStreamInfo: ToStreamInfo;
    },
    Map<string | undefined, AdBreak>
];

type Params = {
    playbackData$: Observable<PlaybackData | undefined>;
    eventDataLoaded$: Observable<Event>;
    duration$: Observable<number>;
    updateVideoState: <T extends keyof VideoState>(
        fieldName: T
    ) => OperatorFunction<
        InferVideoStateValueType<T>,
        InferVideoStateValueType<T>
    >;
    currentTime$: Observable<number>;
    seekingTime$: Observable<number>;
    playerTech: PlayerTech;
    globalSetTrayVisibility?: ({
        lower,
        upper,
    }: {
        lower?: boolean;
        upper?: boolean;
    }) => void;
    eventFsPeriodSwitchCompleted$: Observable<PeriodSwitchCompleted>;
    eventFsPeriodSwitchStarted$: Observable<PeriodSwitchStarted>;
};

type AdDataStreams = {
    adData$: Observable<AdBreak[] | null | undefined>;
    skipAdWhenPlayed$: Observable<SkipPlayedAdBreaks>;
    totalAdTime$: Observable<number | undefined>;
    durationAdsRemoved$: Observable<number>;
    currentTimeAdsRemoved$: Observable<number>;
    adIndex$: Observable<number>;
    adCurrentTime$: Observable<number>;
    adDuration$: Observable<number | undefined>;
    isInAdBreak$: Observable<boolean>;
    adBreakAdCount$: Observable<number>;
    seekingTimeAdsRemoved$: Observable<number>;
};

export default function getAdDataStreams({
    playbackData$,
    eventDataLoaded$,
    duration$,
    updateVideoState,
    currentTime$,
    seekingTime$,
    playerTech,
    globalSetTrayVisibility,
    eventFsPeriodSwitchCompleted$,
    eventFsPeriodSwitchStarted$,
}: Params): AdDataStreams {
    const playedAdBreaks: Set<AdBreak> = new Set();

    // ----- Ad-View Streams ------
    // playbackData$       ------ {playbackData} ----------------------- {playbackData} ------------------------
    // adData$             ------ null --------------- [] -------------- null ----------------- [ads] ----------
    // totalAdTime$        ------ undefined ---------- 0 --------------- undefined ------------ x --------------
    // duration$           0 -------------------- t -------------------- 0 -------------- t --------------------
    // durationAdsRemoved$ ------ 0 ------------------ t - 0 ----------- 0 -------------------- t - x ----------
    const adData$ = playbackData$.pipe(
        switchMap((playbackData) =>
            concat(
                of(null), // set initial value to null to signify that we are yet to receive adData
                eventDataLoaded$.pipe(
                    first(),
                    switchMap(() => EMPTY)
                ), // wait until loadeddata media event so that adTrackingUrl is ready
                fromPromiseCallback(
                    async (signal?: AbortSignal): Promise<AdBreak[]> => {
                        // No playback data? No ad breaks.
                        if (!playbackData) {
                            return [];
                        }

                        // Ignore ad breaks for live streams
                        if (playbackData.metadata.playbackType === 'LIVE') {
                            return [];
                        }

                        // Figure out which source that PlayerTech has used and get the relevant adTrackingUrl
                        const selectedSource = playbackData.sources.find(
                            (source) => source.src === playerTech.src
                        );
                        const adTrackingUrl = selectedSource?.adTrackingUrl;

                        // No ad tracking URL? No ad breaks.
                        if (!adTrackingUrl) {
                            return [];
                        }

                        // If getAdBreaks hasn't been defined when we set playbackData then we want to auto-resolve to an empty array since our asset won't have ads
                        const adBreaks = await playbackData.getAdBreaks({
                            url: adTrackingUrl,
                            signal,
                        });

                        return adBreaks ?? [];
                    }
                )
            )
        ),
        updateVideoState('adData'),
        shareReplay(1) // prevent each subscription from calling playbackData.getAdBreaks() + allow currentTimeAdsRemoved$ get latest adData in switchMap
    );

    const totalAdTime$ = adData$.pipe(
        map((adData) =>
            adData?.reduce(
                (duration, curr) => duration + curr.durationInSeconds,
                0
            )
        ) // use optional chaining to return undefined if we are still waiting on a value
    );

    const durationAdsRemoved$ = combineLatest(duration$, totalAdTime$).pipe(
        map(([duration, totalAdTime]) => {
            if (typeof totalAdTime === 'undefined') {
                return 0; // map to 0 until totalAdTime is defined
            } else {
                return duration - totalAdTime;
            }
        }),
        distinctUntilChanged(),
        updateVideoState('duration')
    );

    const currentTimeAdsRemoved$ = combineLatest(
        currentTime$,
        adData$.pipe(
            filter(Boolean) // wait for ad breaks to resolve to avoid a flash of raw current time
        )
    ).pipe(
        map(
            ([currentTime, adData]) =>
                currentTime - calculateAdSecondsPassed(currentTime, adData)
        ),
        startWith(0),
        distinctUntilChanged(),
        tap((currentTimeAdsRemoved) => {
            playerTech.videoElement.dispatchEvent(
                new CustomEvent('fs-current-time-ads-removed', {
                    detail: currentTimeAdsRemoved,
                })
            );
        }),
        updateVideoState('currentTime')
    );

    const seekingTimeAdsRemoved$ = combineLatest(
        seekingTime$,
        adData$.pipe(filter(Boolean))
    ).pipe(
        map(
            ([seekingTime, adData]) =>
                seekingTime - calculateAdSecondsPassed(seekingTime, adData)
        ),
        startWith(0),
        distinctUntilChanged(),
        updateVideoState('seekingTime')
    );

    const adBreak$ = combineLatest(currentTime$, adData$).pipe(
        map(([currentTime, adData]) =>
            getCurrentAdData(currentTime, playedAdBreaks, adData)
        ),
        share()
    );

    const currentAdsInBreak$: Observable<Ad[]> = adBreak$.pipe(
        map((adBreak) => adBreak?.ads ?? []),
        startWith([]),
        distinctUntilChanged()
    );

    const adIndex$ = combineLatest(currentAdsInBreak$, currentTime$).pipe(
        map(([currentAdsInBreak, currentTime]) =>
            currentAdsInBreak.findIndex(
                ({startTimeInSeconds, durationInSeconds}) => {
                    const endTimeInSeconds =
                        startTimeInSeconds + durationInSeconds;

                    return (
                        currentTime >= startTimeInSeconds &&
                        currentTime < endTimeInSeconds
                    );
                }
            )
        ),
        startWith(-1),
        distinctUntilChanged(),
        updateVideoState('adIndex')
    );

    const adCurrentTime$ = combineLatest(
        currentAdsInBreak$,
        currentTime$,
        adIndex$
    ).pipe(
        map(([currentAdsInBreak, currentTime, adIndex]) => {
            const currentAd = currentAdsInBreak[adIndex];

            return adIndex >= 0 && currentAd
                ? currentTime - currentAd?.startTimeInSeconds
                : 0;
        }),
        startWith(0),
        distinctUntilChanged(),
        updateVideoState('adCurrentTime')
    );

    const adDuration$ = combineLatest(currentAdsInBreak$, adIndex$).pipe(
        map(([currentAdsInBreak, adIndex]) =>
            adIndex >= 0 ? currentAdsInBreak[adIndex]?.durationInSeconds : 0
        ),
        startWith(0),
        distinctUntilChanged(),
        updateVideoState('adDuration')
    );

    const isInAdBreak$ = adBreak$.pipe(
        map(Boolean),
        distinctUntilChanged(),
        updateVideoState('isInAdBreak'),
        tap((isInAdBreak) => {
            if (isInAdBreak && globalSetTrayVisibility) {
                globalSetTrayVisibility({lower: false, upper: false});
            }
        })
    );

    const adBreakAdCount$ = currentAdsInBreak$.pipe(
        map(({length}) => length),
        updateVideoState('adBreakAdCount')
    );

    const skipPlayedAdBreaks = (): Observable<SkipPlayedAdBreaks> => {
        const currentPeriod$ = eventFsPeriodSwitchStarted$.pipe(
            map(
                (eventFsPeriodSwitchStarted) =>
                    eventFsPeriodSwitchStarted.detail.periodInfo
            )
        );
        const nextPeriod$ = eventFsPeriodSwitchCompleted$.pipe(
            map(
                (eventFsPeriodSwitchCompleted) =>
                    eventFsPeriodSwitchCompleted.detail.periodInfo
            )
        );
        const adBreaksByAdId$: Observable<Map<string | undefined, AdBreak>> =
            adData$.pipe(
                map(
                    (adData) =>
                        new Map(
                            adData?.flatMap((adBreak) =>
                                adBreak.ads.map(({adId}) => [adId, adBreak])
                            )
                        )
                )
            );

        // Current ad break that's playing based on period switch events.
        return nextPeriod$.pipe(
            withLatestFrom(currentPeriod$, adBreaksByAdId$),
            tap(([, currentPeriod, adBreaksByAdId]) => {
                const currentAdBreak = adBreaksByAdId.get(
                    currentPeriod.fromStreamInfo?.id
                );

                // last ad,mark it as watched.
                if (
                    currentAdBreak &&
                    !adBreaksByAdId.get(currentPeriod.toStreamInfo?.id)
                ) {
                    playedAdBreaks.add(currentAdBreak);

                    playerTech.removeBreak({
                        ...currentAdBreak,
                        breakClipIds: currentAdBreak.ads.map((ad) => ad.adId),
                    });
                }
            })
        );
    };

    const skipAdWhenPlayed$ = playbackData$.pipe(
        switchMap(skipPlayedAdBreaks),
        share() // avoid skipping per subscription in case there are multiple subscribers
    );

    return {
        adData$,
        skipAdWhenPlayed$,
        totalAdTime$,
        durationAdsRemoved$,
        currentTimeAdsRemoved$,
        adIndex$,
        adCurrentTime$,
        adDuration$,
        isInAdBreak$,
        adBreakAdCount$,
        seekingTimeAdsRemoved$,
    };
}
