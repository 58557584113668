import React from 'react';
import ReactDOM from 'react-dom';
import bacon from 'baconjs';
import isEqual from 'lodash/isEqual';

import VideoModalComponent from '../components/video-modal';

import HawkPlayer from './hawk-player';

let modalWrapperInstance;

function VideoModal(settings = null) {
    this.createElement();

    this.closeStreams = this.getData()
        .onValue(this.render.bind(this));

    this.play(settings);
}

VideoModal.prototype.createElement = function () {
    if (process.browser) {
        this.element = document.createElement('div');
        document.body.appendChild(this.element);
    }
};

VideoModal.prototype.play = function (settings) {
    this.playerSettingsBus.push(settings);
};

VideoModal.prototype.getData = function () {
    this.playerSettingsBus = new bacon.Bus();

    const player = this.playerSettingsBus
        .skipDuplicates(isEqual)
        .map((settings) => {
            if (settings) {
                return HawkPlayer(null, {
                    autoplay: true,
                    sport: settings.sport,
                    supportUrl: settings.supportUrl,
                    videoDetails: settings.videoDetails,
                    onClose: () => { this.playerSettingsBus.push(null); },
                    vimondEnv: settings.vimondEnv || 'prod'
                });
            } else {
                return HawkPlayer(null, {oneClickPlayer: true});
            }
        })
        .flatMapLatest((hawkPlayer) => hawkPlayer.initComponentStream())
        .map('.reactElement');

    const isOpen = this.playerSettingsBus
        .skipDuplicates(isEqual)
        .map((settings) => settings !== null);

    return bacon.combineTemplate({
        view: bacon.combineTemplate({
            isOpen,

            player,

            onClickClose: () => {
                this.playerSettingsBus.push(null);
            }
        }),
        iso: {}
    });
};

VideoModal.prototype.render = function (data) {
    if (this.element) {
        ReactDOM.render(
            <VideoModalComponent {...data.view} />,
            this.element
        );
    }
};

VideoModal.prototype.remove = function () {
    try {
        this.closeStreams();
    } catch (e) {} // eslint-disable-line no-empty

    try {
        if (this.element) {
            ReactDOM.unmountComponentAtNode(this.element);
        }
    } catch (e) {} // eslint-disable-line no-empty

    modalWrapperInstance = null;
};

export default function (settings) {
    if (!modalWrapperInstance) {
        modalWrapperInstance = new VideoModal(settings);
    }

    return modalWrapperInstance;
}
