import React from 'react';
import ReactDOM from 'react-dom';
import bacon from 'baconjs';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';

import {page as pageBoot}  from '@fsa/fs-commons/lib/iso/boot';
import {renderToHtml} from '@fsa/fs-commons/lib/iso/render';
import {initialLaterOr, handleStreamForIsoRender} from '@fsa/fs-commons/lib/streams/helper';
import pollUntil from '@fsa/fs-commons/lib/streams/poll-until';

import getSeasonConfig from '../streams/season-config/get-season-config';
import getScoresPre from '../streams/matches/scores-pre';
import getScoresPost from '../streams/matches/scores-post';
import getSeriesConfig from '../streams/fixture-list/get-series-config';
import NonLiveMatchComponent from '../components/scores-pre-post';

const EXPIRY_SECONDS = 180; // 3 mins

function ScoresPrePost(element, settings) {
    this.element  = element;
    this.settings = settings;

    this.config = {
        count:     settings.count,
        sport:     settings.sport,

        vimondEnv: settings.vimondEnv || 'prod'
    };
}

ScoresPrePost.prototype.init = function (initialData = false) {
    this.closeStreams = this.getData(initialData)
        .onValue(this.render.bind(this));
};

ScoresPrePost.prototype.initIso = function () {
    return new Promise((onResolve, onReject) => {
        this.closeStreams = this.getData()
            .take(1)
            .subscribe((event) => handleStreamForIsoRender({
                onReject,
                onResolve,
                event,

                identifier:            'HAWK: ScoresPrePost',
                expirySecondOnFailure: 10,
                expirySecondOnSuccess: EXPIRY_SECONDS,
                render:                this.render.bind(this)
            }));
    });
};

ScoresPrePost.prototype.initComponentStream = function (initialData = false) {
    const data         = this.getData(initialData);
    const reactElement = data.map((data) => <NonLiveMatchComponent {...data.view} />);
    const iso          = data.map('.iso');

    return bacon.combineTemplate({data, reactElement, iso});
};

ScoresPrePost.prototype.getData = function (initialData) {
    const {count, sport, vimondEnv} = this.config;
    const displaySpoilersBus = new bacon.Bus();
    const selectedTabBus = new bacon.Bus();

    const seriesIds$ = pollUntil({
        streamToPoll: getSeriesConfig({sport})
            .map(
                (seriesList) => seriesList.map((series) => series.id)
            ),
        pollWhileCallback: () => true,
        delayMs: 0,
        initialData: initialData && initialData.seriesIds
    });

    // This is purely for the spoilers overlay, so if the season/config.json isn’t there, then start
    // with an empty array instead of the whole widget erroring out.
    const seasonConfig$ = initialLaterOr(
        initialData && initialData.seasonConfig,
        getSeasonConfig({sport})
    )
        .startWith([]);

    const completedMatches$ = initialLaterOr(
        initialData && initialData.completedMatches,
        seriesIds$.flatMapLatest((seriesIds) =>
            getScoresPost({
                count,
                sport,
                seriesIds,
                vimondEnv
            })
        )
    );

    const upcomingMatches$ = initialLaterOr(
        initialData && initialData.upcomingMatches,
        seriesIds$.flatMapLatest((seriesIds) =>
            getScoresPre({
                count,
                sport,
                seriesIds,
                vimondEnv
            })
        )
    );

    const seriesList$ = bacon.combineWith(
        completedMatches$, upcomingMatches$,
        (completedMatches = [], upcomingMatches = []) =>
            completedMatches
                .concat(upcomingMatches)
                .map(({series}) => series && series.id)
                .filter(Boolean)
    )
        .map(uniq);

    const showSpoilerProp = displaySpoilersBus.toProperty(false);

    const displaySpoilers$ = bacon.combineWith(
        upcomingMatches$, showSpoilerProp, seasonConfig$,
        (upcomingMatches, showSpoiler, seasonConfig) => {
            if (showSpoiler || isEmpty(seasonConfig)) {
                return true;
            }

            const foundMatches = find(upcomingMatches, (upcomingMatch) => (
                find(seasonConfig, (config) => (
                    config.series === upcomingMatch.series.id &&
                    config.round <= upcomingMatch.round.number &&
                    (config.season ? config.season === upcomingMatch.season.id : true) // season optional
                ))
            ));

            return foundMatches === undefined;
        }
    );

    const selectedTab = bacon.update(0, [selectedTabBus], (prevSelectedTab, selectedTab) => selectedTab);

    return bacon.combineTemplate({
        view: bacon.combineTemplate({
            isContentVisible: displaySpoilers$,

            completedMatches: completedMatches$,
            selectedTab,
            seriesList: seriesList$,
            sport,
            upcomingMatches: upcomingMatches$,

            onShowSpoilers: () => {
                displaySpoilersBus.push(true);
            },
            onTabClick: (tabId) => {
                selectedTabBus.push(tabId);
            }
        }),
        iso: bacon.combineTemplate({
            seriesIds: seriesIds$,
            completedMatches: completedMatches$,
            seasonConfig: seasonConfig$,
            upcomingMatches: upcomingMatches$
        })
    });
};

ScoresPrePost.prototype.render = function (data) {
    if (this.element) {
        ReactDOM.render(
            <NonLiveMatchComponent {...data.view} />,
            this.element
        );
    } else {
        return renderToHtml(
            <NonLiveMatchComponent {...data.view} />,
            'hawkwidgets-scores-pre-post',
            {
                settings: this.settings,
                data:     data.iso
            }
        );
    }
};

ScoresPrePost.prototype.remove = function () {
    try {
        this.closeStreams();
    } catch (e) {} // eslint-disable-line no-empty

    try {
        if (this.element) {
            ReactDOM.unmountComponentAtNode(this.element);
        }
    } catch (e) {} // eslint-disable-line no-empty
};

ScoresPrePost.prototype.pageBoot = function () {
    pageBoot(ScoresPrePost, 'hawkwidgets-scores-pre-post');
};

pageBoot(ScoresPrePost, 'hawkwidgets-scores-pre-post');

export default function (element, settings) {
    return new ScoresPrePost(element, settings);
}
