import React from 'react';
import ReactDOM from 'react-dom';
import bacon from 'baconjs';
import get from 'lodash/get';
import omit from 'lodash/omit';

import {asBool} from '@fsa/fs-commons/lib/utils/normalise-arg';
import {page as pageBoot}  from '@fsa/fs-commons/lib/iso/boot';
import {renderToHtml} from '@fsa/fs-commons/lib/iso/render';
import {getSportNames as getSportDetails} from '@fsa/fs-commons/lib/utils/sport-names';

import noop from 'lodash/noop';
import {filterEventOn} from '../utils/video-normalised-events';
import {getYouboraTracking} from '../utils/analytics-youbora';
import {getVimondTracking} from '../utils/analytics-vimond';
import {authorizationToken, getDeviceUdid, getDeviceLabel} from '../utils/api';
import getVideoPlayerData from '../streams/end-points/vimond/video-player-data';
import HawkPlayerComponent from '../components/hawk-player';

import {getLoginRedirectUrl} from '../utils/location';
import streamotionWebPlayer from './streamotion-player';

function HawkPlayer(element, settings) {
    this.element = element;
    this.settings = settings;

    this.config = {
        sport: this.settings.sport || 'afl',
        autoplay: asBool(this.settings.autoplay, true),
        liveFromStart: this.settings.liveFromStart,
        oneClickPlayer: asBool(this.settings.oneClickPlayer, true),

        sportDetails: this.settings.sportDetails,
        supportUrl: this.settings.supportUrl,

        vimondEnv: this.settings.vimondEnv || 'prod',
        videoDetails: this.settings.videoDetails || {}
    };
}

HawkPlayer.prototype.init = function (initialData = false) {
    this.closeStreams = this.getData(initialData)
        .onValue(this.render.bind(this));
};

HawkPlayer.prototype.initIso = function () {
    return new Promise((resolve, reject) => {
        console.error('Hawk Player not to be used as a raw ISO player');

        reject({
            html: 'Hawk Player not to be used as a raw ISO player.'
        });
    });
};

HawkPlayer.prototype.initComponentStream = function (initialData = false) {
    const data = this.getData(initialData);
    const reactElement = data.map((data) => <HawkPlayerComponent {...data.view} />);
    const iso = data.map((data) => data.iso);

    return bacon.combineTemplate({data, reactElement, iso, player: this});
};

HawkPlayer.prototype.getData = function () {
    const authToken = authorizationToken();
    const deviceUdid = getDeviceUdid();
    const defaultPlayerSettings = {
        oneClickPlayer: true,
        theme: {
            killNormalise: true
        },
        onClose: this.settings.onClose || noop,
        uuid: deviceUdid // (VideoFS Calls it uuid, whereas Vimond calls it Udid)
    };
    const deviceLabel = getDeviceLabel();
    const videoConfig = omit(this.config, ['videoDetails']);
    const playUrl = get(this.config.videoDetails, 'playback.url', '');
    const sportDetails = this.config.sportDetails || getSportDetails(this.config.sport);
    let videoSources;

    if (!this.config.autoplay || !playUrl) {
        videoSources = bacon.later(0, {
            sources: [],
            maxSubscriptionStreams: 0
        });
    } else {
        videoSources = getVideoPlayerData({
            authToken,
            deviceLabel,
            deviceUdid,
            sport: this.config.sport,
            url: playUrl
        });
    }

    const videoPlayerStream = videoSources
        .map(({sources, ...otherConfigs}) => {
            const playerSettings = Object.assign(
                {},
                defaultPlayerSettings,
                videoConfig,
                {
                    autoplay: false,

                    allowDVR: true,
                    title: this.config.videoDetails.title || '',
                    poster: this.config.videoDetails.imageUrl,

                    errorFaqLink: this.config.supportUrl,
                    sources
                },
                otherConfigs
            );

            // Don't ever autoplay unless we have sources.
            if (sources && sources.length) {
                playerSettings.autoplay = videoConfig.autoplay;
            }

            return streamotionWebPlayer(playerSettings, null);
        })
        .startWith(streamotionWebPlayer(defaultPlayerSettings, null));

    const videoPlayer = videoPlayerStream
        .flatMapLatest((player) => {
            if (!process.browser) { // We won't be loading video player server side as this repo hasn't been properly setup for serverside styled component rendering.
                return null;
            }

            return player.initComponentStream();
        })
        .map('.reactElement');

    const videoNormalisedEvents = videoPlayerStream
        .flatMapLatest((player) => player.getVideoNormalisedEvents())
        .startWith(null);

    const youboraTracking = getYouboraTracking({
        sport: this.config.sport,
        videoNormalisedEvents,
        videoDetails: this.config.videoDetails,
        vimondEnv: this.config.vimondEnv
    });

    const videoPlayerInstance = videoNormalisedEvents
        .filter(filterEventOn(['firstplay']))
        .map('.playerTech')
        .startWith(null);

    const vimondTracking = videoSources
        .map('.metadata')
        .flatMapLatest((metadata) => getVimondTracking({
            authToken,
            sport: this.config.sport,
            videoNormalisedEvents,
            vimondEnv: this.config.vimondEnv,
            trackingData: Object.assign({}, this.config.videoDetails, metadata)
        }))
        .startWith(null)
        .map('.kickedClientIds');

    const concurrentStreamErrorBus = new bacon.Bus();

    const kickClientOnConcurrentLimit = vimondTracking
        .combine(videoPlayerInstance, (kickedClientIds = [], videoPlayerInstance) => {
            if (kickedClientIds.indexOf(deviceUdid) !== -1) {
                videoPlayerInstance.exitFullscreen();
                // videoPlayerInstance.exitFullWindow();
                videoPlayerInstance.pause();

                concurrentStreamErrorBus.push({
                    isLoginRequired: false,
                    isSubscriptionRequired: false,
                    message: 'Max concurrent stream limit reached'
                });
            }
        });

    const videoSourcesError = videoSources
        .errors()
        .mapError((error) => error);

    const videoError = bacon
        .update(
            null,
            [videoSourcesError], (prevError, sourcesError) => sourcesError,
            [concurrentStreamErrorBus], (prevError, concurrentStreamError) => concurrentStreamError
        );

    const isLoadingPlayUrl = bacon
        .mergeAll(
            videoSources.map(false),
            videoError.map((error) => error === null)
        );

    return bacon.combineTemplate({
        view: bacon.combineTemplate({
            isLoadingPlayUrl,

            videoPlayer,
            videoError,

            title: this.config.title,
            poster: this.config.poster,

            redirectUrl: getLoginRedirectUrl(),

            sportDetails
        }),
        iso: {},

        youboraTracking,

        kickClientOnConcurrentLimit
    });
};

HawkPlayer.prototype.render = function (data) {
    if (this.element) {
        ReactDOM.render(
            <HawkPlayerComponent {...data.view} />,
            this.element
        );
    } else {
        return renderToHtml(
            <HawkPlayerComponent {...data.view} />,
            'hawkwidgets-hawk-player',
            {
                settings: this.settings,
                data:     data.iso
            }
        );
    }
};

HawkPlayer.prototype.remove = function () {
    try {
        this.closeStreams();
    } catch (e) {} // eslint-disable-line no-empty

    try {
        if (this.element) {
            ReactDOM.unmountComponentAtNode(this.element);
        }
    } catch (e) {} // eslint-disable-line no-empty
};

HawkPlayer.prototype.pageBoot = function () {
    pageBoot(HawkPlayer, 'hawkwidgets-hawk-player');
};

pageBoot(HawkPlayer, 'hawkwidgets-hawk-player');

export default function (element, settings) {
    return new HawkPlayer(element, settings);
}
