import React from 'react';
import ReactDOM from 'react-dom';
import bacon from 'baconjs';
import isEqual from 'lodash/isEqual';

import {asNumber} from '@fsa/fs-commons/lib/utils/normalise-arg';
import {initialLaterOr} from '@fsa/fs-commons/lib/streams/helper';
import {page as pageBoot}  from '@fsa/fs-commons/lib/iso/boot';
import {handleStreamForIsoRender, renderToHtml} from '@fsa/fs-commons/lib/iso/render';
import {getSportNames as getSportDetails} from '@fsa/fs-commons/lib/utils/sport-names';

import getVimondAssetData from '../streams/end-points/video-vimond-asset';
import LiveVideoComponent from '../components/live-video';

import HawkPlayer from './hawk-player';

const ISO_ERROR_EXPIRES_IN = 10;
const ISO_SUCCESS_EXPIRES_IN = 5 * 60;

const FALLBACK_STARTCARD_IMG = 'https://media.foxsports.com.au/digital/images/livestreams/fsnlivestream/FSN_Live_Stream_Start.jpg';

function LiveVideo(element, settings) {
    this.element  = element;
    this.settings = settings;

    this.config = {
        sport: this.settings.sport || 'afl',
        supportUrl: this.settings.supportUrl,
        liveFromStart: false,
        assetId: asNumber(this.settings.assetId),

        vimondEnv: this.settings.vimondEnv || 'prod'
    };
}

LiveVideo.prototype.init = function (initialData = false) {
    this.closeStreams = this.getData(initialData)
        .onValue(this.render.bind(this));
};

LiveVideo.prototype.initIso = function () {
    return new Promise((onResolve, onReject) => {
        this.closeStreams = this.getData()
            .take(1)
            .subscribe((event) => handleStreamForIsoRender({
                onReject,
                onResolve,
                event,

                identifier: 'HAWK: Live Video',
                expirySecondOnFailure: ISO_ERROR_EXPIRES_IN,
                expirySecondOnSuccess: ISO_SUCCESS_EXPIRES_IN,
                render: this.render.bind(this)
            }));
    });
};

LiveVideo.prototype.initComponentStream = function (initialData = false) {
    const data = this.getData(initialData);
    const reactElement = data.map((data) => <LiveVideoComponent {...data.view} />);
    const iso = data.map('.iso');

    return bacon.combineTemplate({data, reactElement, iso});
};

LiveVideo.prototype.getData = function (initialData = false) {
    const playerOpenStatusBus = new bacon.Bus();

    const {
        assetId,
        liveFromStart,
        sport,
        supportUrl,
        vimondEnv
    } = this.config;

    const sportDetailsObj = getSportDetails(this.config.sport);

    // don’t need the function
    const sportDetails = {
        apiName: sportDetailsObj.apiName,
        label: sportDetailsObj.label,
        route: sportDetailsObj.route
    };

    const liveVideoDetailsStream = initialLaterOr(
        initialData && initialData.liveVideoDetailsStream,
        getVimondAssetData({
            assetId,
            sport,
            vimondEnv
        })
    );

    const shouldPlay = bacon.update(
        false,
        [playerOpenStatusBus], (_, isPlayerOpen) => isPlayerOpen
    );

    const handleVideoClosed = () => {
        playerOpenStatusBus.push(false);
    };

    const videoPlayer = liveVideoDetailsStream
        .combine(shouldPlay, (videoDetails, shouldPlay) => ({videoDetails, shouldPlay}))
        .filter(({shouldPlay}) => shouldPlay)
        .map(({videoDetails, shouldPlay}) => HawkPlayer(null, {
            autoplay: shouldPlay,
            onClose: handleVideoClosed,
            liveFromStart,
            sport,
            sportDetails,
            supportUrl,
            videoDetails,
            vimondEnv
        }))
        .startWith(HawkPlayer(null, {oneClickPlayer: true}))
        .flatMapLatest((playerInstance) => playerInstance.initComponentStream())
        .map('.reactElement');

    const shouldRenderStartCard = shouldPlay
        .not();

    return bacon.combineTemplate({
        view: bacon.combineTemplate({
            shouldRenderStartCard,

            fallbackStartCardImg: FALLBACK_STARTCARD_IMG,
            player: videoPlayer,
            startCardImg: liveVideoDetailsStream.map('.imageUrl'),
            title: liveVideoDetailsStream.map('.title') || `${sport.toUpperCase()} Live 24/7`,

            onClick: () => {
                playerOpenStatusBus.push(true);
            }
        }),
        iso: {
            liveVideoDetailsStream
        }
    }).skipDuplicates(isEqual);
};

LiveVideo.prototype.render = function (data) {
    if (this.element) {
        ReactDOM.render(
            <LiveVideoComponent {...data.view} />,
            this.element
        );
    } else {
        return renderToHtml(
            <LiveVideoComponent {...data.view} />,
            'hawkwidgets-live-video',
            {
                settings: this.settings,
                data:     data.iso
            }
        );
    }
};

LiveVideo.prototype.remove = function () {
    try {
        this.closeStreams();
    } catch (e) {} // eslint-disable-line no-empty

    try {
        if (this.element) {
            ReactDOM.unmountComponentAtNode(this.element);
        }
    } catch (e) {} // eslint-disable-line no-empty
};

LiveVideo.prototype.pageBoot = function () {
    pageBoot(LiveVideo, 'hawkwidgets-live-video');
};

pageBoot(LiveVideo, 'hawkwidgets-live-video');

export default function (element, settings) {
    return new LiveVideo(element, settings);
}
