import {isSafari, isPs4, isPs5} from '@fsa-streamotion/browser-utils';

import noop from 'lodash/noop';

import type PlaybackNative from '.';
import type {PlayerTechOptions, SupportedPlaybackHandler} from '../../types';
import type {OnComputeEdgeEvent, OnEdgeLeniencyEvent} from '../types';
import {triggerCustomEvent} from '../utils';

const DEFAULT_ON_EDGE_LENIENCY_SECONDS = 30;
const DEFAULT_BEST_GUESS_FRAGMENT_DURATION_SECONDS = 12;
const DURATION_CHANGE_INTERVAL_ON_LIVE_SECONDS = 2;
const PLAYBACK_RATE_RESET_THRESHOLD_SECONDS = 5;

export default class NativeLivestream {
    videoElement: HTMLVideoElement | null = null;
    isOnEdgeCallback: PlayerTechOptions['isOnEdgeCallback'];
    isLiveCallback: PlayerTechOptions['isLiveCallback'];
    playbackTech: PlaybackNative;
    playbackHandler: SupportedPlaybackHandler;
    timerDurationChange: number | null = null;
    timerDurationLastRecorded = 0;

    onEdge = false;
    live = false;
    #isInit = true;
    #shouldSuppressOnEdge = false;

    hasCurrentTimeBeenAdjustedOnPlay = false;

    bestGuessFragmentDuration = DEFAULT_BEST_GUESS_FRAGMENT_DURATION_SECONDS;
    onEdgeLeniency = DEFAULT_ON_EDGE_LENIENCY_SECONDS;

    constructor(
        playbackTech: PlaybackNative,
        playbackHandler: SupportedPlaybackHandler
    ) {
        this.videoElement = playbackTech.videoElement;
        this.isOnEdgeCallback = playbackTech.options.isOnEdgeCallback || noop;
        this.isLiveCallback = playbackTech.options.isLiveCallback || noop;
        this.playbackTech = playbackTech;
        this.playbackHandler = playbackHandler;

        this.setupLiveListeners();
        this.setupOnEdgeListeners();

        this.videoElement?.addEventListener(
            'fs-stalled-buffering',
            this.onStalledBuffering
        );
    }

    destroy(): void {
        if (this.timerDurationChange) {
            // Clear out possible live duration change timer
            clearInterval(this.timerDurationChange);
        }

        this.destroyLiveListeners();
        this.destroyOnEdgeListeners();

        this.videoElement?.removeEventListener(
            'fs-stalled-buffering',
            this.onStalledBuffering
        );
    }

    setupLiveListeners(): void {
        this.videoElement?.addEventListener(
            'loadeddata',
            this.onEventLoadedData
        );
        this.videoElement?.addEventListener('durationchange', this.computeLive);
        this.videoElement?.addEventListener('play', this.onEventPlay);
        this.videoElement?.addEventListener('ended', this.onEventEnded, true);
    }

    setupOnEdgeListeners(): void {
        this.videoElement?.addEventListener('pause', this.clearOnEdge);
        this.videoElement?.addEventListener('timeupdate', this.computeOnEdge);

        if (isPs4() || isPs5()) {
            this.videoElement?.addEventListener(
                'durationchange',
                this.computeOnEdge
            );
        }
    }

    destroyLiveListeners(): void {
        this.videoElement?.removeEventListener(
            'loadeddata',
            this.onEventLoadedData
        );
        this.videoElement?.removeEventListener(
            'durationchange',
            this.computeLive
        );
        this.videoElement?.removeEventListener('play', this.onEventPlay);
        this.videoElement?.removeEventListener(
            'ended',
            this.onEventEnded,
            true
        );
    }

    destroyOnEdgeListeners(): void {
        this.videoElement?.removeEventListener('pause', this.clearOnEdge);
        this.videoElement?.removeEventListener(
            'timeupdate',
            this.computeOnEdge
        );
    }

    onEventEnded = (e: Event): void => {
        if (!this.videoElement) {
            return;
        }
        // Safari specifically, because it's fuckin special, if users playback too fast,
        // or seek too close to the end, the video will 'end', even though we're live and shits still comin.
        // GOSH SAFARI.

        // Detect the above case, rollback currentTime by 1 second, reset playback rate,
        // hit play (while attempting to supress the event 'play' and 'ended').
        const {live, videoElement} = this;
        const {duration} = this.playbackTech;
        const {playbackRate, networkState, NETWORK_LOADING} = videoElement;
        const networkLoading = networkState === NETWORK_LOADING;

        if (
            isSafari() && // We on a safari browser?
            live && // Considered live?
            Number.isFinite(duration) && // Do we have a (even guessed) video duration?
            playbackRate > 1 && // Are we faster than we should be?
            networkLoading // Is the network suggesting we're loading?
        ) {
            const onNextPlaySuppressEvent = function (e: Event): void {
                e.stopPropagation();
                e.stopImmediatePropagation();

                videoElement.removeEventListener(
                    'play',
                    onNextPlaySuppressEvent,
                    true
                );
            };

            console.warn(
                'PlayerTech: Reset playback rate to 1 as we appear out of things to play right now.'
            );

            videoElement.addEventListener(
                'play',
                onNextPlaySuppressEvent,
                true
            );

            e.stopPropagation(); // stops propagation for bubbles.
            e.stopImmediatePropagation(); // stops any other event listening to this exact event. It's a fake play.

            this.videoElement.playbackRate = 1;
            this.videoElement.currentTime = duration - 1;

            this.videoElement.play();
        }
    };

    onEventPlay = (): void => {
        if (!this.videoElement) {
            return;
        }

        this.#shouldSuppressOnEdge = false;

        // When the first play comes through, we need to adjust
        // the currentTime of the video, we may need to be 'start from edge'
        // which is the default, or actually rewind.
        if (
            !this.hasCurrentTimeBeenAdjustedOnPlay &&
            !isFinite(this.videoElement.duration)
        ) {
            this.hasCurrentTimeBeenAdjustedOnPlay = true;

            const defaultStartAt = this.playbackTech.options.startAt;

            if (
                defaultStartAt !== undefined &&
                defaultStartAt !== -1 &&
                defaultStartAt !== 0
            ) {
                console.warn(
                    '@TODO remove this after testing Start from edge === false'
                );
                this.videoElement.currentTime = defaultStartAt;
            }
        }
    };

    // @TODO While this has given us live stream duration + durationchange events being triggered,
    // it needs to only trigger when it's actually changed, and it's currently out by 1 fragment duration.
    onEventLoadedData = (): void => {
        if (!this.videoElement) {
            return;
        }

        // When we load data up, we may be 'live' and not updating duration ticks as expected in
        // all other browsers.
        const isLive = !isFinite(this.videoElement.duration);

        if (this.timerDurationChange) {
            clearInterval(this.timerDurationChange);
        }

        if (isLive) {
            // Best guess fragment duration seconds, should not be the onEdgeLeniency.
            // If we're 10 second fragments, live should be considered @ 30s. Which by
            // default it's set on the class already.
            // Guessing at a leniency should be done at trySetOnEdgeLeniency which
            // would be based on the difference between a last seen duration, with a newly seen duration.
            // Setting it always to 12 makes no sense here.

            // this.onEdgeLeniency = DEFAULT_BEST_GUESS_FRAGMENT_DURATION_SECONDS;

            const sendDurationChangeFunc = (): void => {
                if (!this.videoElement) {
                    return;
                }

                const event = new Event('durationchange'); // No need to send payload here, it's not where details go...
                const isSeekable = this.videoElement.seekable.length > 0;
                const seekableDurationSeconds =
                    isSeekable &&
                    this.videoElement.seekable.end(
                        this.videoElement.seekable.length - 1
                    );

                if (
                    seekableDurationSeconds &&
                    seekableDurationSeconds !== this.timerDurationLastRecorded
                ) {
                    this.timerDurationLastRecorded = seekableDurationSeconds;

                    this.videoElement.dispatchEvent(event);
                }
            };

            sendDurationChangeFunc(); // Send the first event.
            this.timerDurationChange = window.setInterval(
                sendDurationChangeFunc,
                DURATION_CHANGE_INTERVAL_ON_LIVE_SECONDS * 1000
            );
        }
    };

    computeLive = (...args: OnEdgeLeniencyEvent): void =>
        void this.tryComputeLive(...args);

    tryComputeLive: (...args: OnEdgeLeniencyEvent) => void = () => {
        if (!this.videoElement) {
            return;
        }

        const isLive = !isFinite(this.videoElement.duration);

        this.triggerIsLive(isLive);
    };

    // Allows trySetOnEdgeLeniency() to be extended while bound.
    setOnEdgeLeniency = (...args: OnEdgeLeniencyEvent): void =>
        void this.trySetOnEdgeLeniency(...args);

    trySetOnEdgeLeniency: (...args: OnEdgeLeniencyEvent) => void = () => {
        // @TODO - This never appears called in native?
        this.onEdgeLeniency = DEFAULT_ON_EDGE_LENIENCY_SECONDS;
    };

    clearOnEdge = (): void => {
        this.#shouldSuppressOnEdge = true;
        this.triggerIsOnEdge(false);
    };

    // Allows tryComputeOnEdge() to be extended while bound.
    computeOnEdge = (...args: OnComputeEdgeEvent): void =>
        void this.tryComputeOnEdge(...args);

    tryComputeOnEdge: (...args: OnComputeEdgeEvent) => void = () => {
        const {
            currentTime: currentTimeSeconds,
            duration: durationSeconds,
            isPlaying,
        } = this.playbackTech;

        if (this.#shouldSuppressOnEdge && !isPlaying) {
            return;
        }

        // This check prevents a race condition where we make a bad TimeRanges check
        if (durationSeconds !== Infinity && durationSeconds > 0) {
            const leniencySeconds = this.onEdgeLeniency;
            const isOnEdge =
                currentTimeSeconds !== undefined &&
                currentTimeSeconds > durationSeconds - leniencySeconds;

            // console.warn(`drift on leniency ${leniency}: ${(duration - currentTime)}`);
            this.triggerIsOnEdge(isOnEdge);

            if (isOnEdge) {
                this.resetPlaybackRateIfOnEdge();
            }
        } else if (durationSeconds === Infinity) {
            // In the event we can't determine if we are, or aren't on the edge,
            // simply report we always are.
            this.triggerIsOnEdge(true);
        }
    };

    triggerIsOnEdge(isOnEdge: boolean): void {
        const wasOnEdge = this.onEdge;
        const nowOnEdge = isOnEdge && this.live;

        // Only fire if this has changed or liveStreamInstance just initialised.
        if (wasOnEdge !== nowOnEdge || this.#isInit) {
            this.onEdge = nowOnEdge;
            this.#isInit = false;

            this.isOnEdgeCallback?.(this.onEdge);
            triggerCustomEvent(this.videoElement, 'fs-live-stream-is-on-edge', {
                isOnEdge: this.onEdge,
            });
        }
    }

    triggerIsLive(isLive: boolean): void {
        this.live = isLive;
        this.isLiveCallback?.(this.live);
        triggerCustomEvent(this.videoElement, 'fs-live-stream-is-live', {
            isLive: this.live,
        });
    }

    setCurrentTimeToEdge(): void {
        if (!this.videoElement) {
            return;
        }

        const isSeekable = this.videoElement.seekable.length > 0; // If we haven't loaded enough to know how far we can seek ahead, don't do anything
        const isEdgeOfStream =
            isSeekable &&
            this.videoElement.seekable.end(
                this.videoElement.seekable.length - 1
            );

        // If we're already considered on edge, don't jump again.
        if (this.live && !this.onEdge && isEdgeOfStream && this.videoElement) {
            this.videoElement.currentTime =
                isEdgeOfStream - this.bestGuessFragmentDuration; // don't go all the way to the edge so we don't accidently trigger the 'ended' event.
        }
    }

    onStalledBuffering = (): void => {
        this.resetPlaybackRateIfOnEdge();
    };

    // If we're at a playback rate higher than 1, reset it.
    // This is because we're at the edge and reattempting to go faster than
    // normal time will result in continuous buffering scenarios.
    //
    // Allow extending playback handlers to override this if required. Unlikely though.
    resetPlaybackRateIfOnEdge(): void {
        if (!this.videoElement) {
            return;
        }

        const isSeekable = this.videoElement.seekable.length > 0;
        const seekableDurationSeconds =
            isSeekable &&
            this.videoElement.seekable.end(
                this.videoElement.seekable.length - 1
            );
        const {currentTime: currentTimeSeconds, playbackRate} =
            this.videoElement;
        const {live: isLive} = this;

        if (
            isLive &&
            playbackRate > 1 &&
            seekableDurationSeconds &&
            currentTimeSeconds &&
            seekableDurationSeconds - currentTimeSeconds <
                PLAYBACK_RATE_RESET_THRESHOLD_SECONDS
        ) {
            console.warn(
                'PlayerTech: Reset playback rate to 1 as we appear out of things to play right now.'
            );
            this.videoElement.playbackRate = 1;
        }
    }
}
