/* eslint-disable no-invalid-this */
import type Hls from 'hls.js';
// eslint-disable-next-line no-duplicate-imports
import {type HlsListeners} from 'hls.js';
import noop from 'lodash/noop';
import round from 'lodash/round';

import type PlaybackHls from '.';
import {type PlayerTechOptions} from '../../types';
import {triggerCustomEvent} from '../utils';
import {Events} from './types';

/*
 * MS Edge Is not exactly helpful to us.
 *
 * Stalled can occur during normal playback. In this case we typically investigate
 * the video element's networkState and readyState to confirm. However in Edge,
 * we're unable to do that check as it never toggles under MSE (maybe even native playback too).
 *
 * Then once you've hit stalled, 'playing' never fires after 'waiting' or 'stalled' occur.
 * Which makes things even more problematic.
 *
 * So when HLS is taking over the playback, we'll look at some specific video events for EDGE,
 * and supplement with events and details from HLS itself.
 */
export default class HlsBufferEdge {
    bufferingStartEvents = ['seeking'];
    bufferingFinishEvents = [
        'seeked',
        'playing',
        'canplaythrough',
        'error',
        'ended',
    ];

    // Interval that calculates how much % we have left during stalled buffers.
    bufferPercentage: number | null = 0;
    bufferingFragments: Record<
        string,
        {
            total?: number;
            loaded?: number;
        }
    > = {};

    videoElement: HTMLVideoElement | null;
    bufferingCallback: PlayerTechOptions['bufferingCallback'];
    bufferingPercentageCallback: PlayerTechOptions['bufferingPercentageCallback'];
    bufferingMonitorInterval: PlayerTechOptions['bufferingMonitorInterval'];
    playbackTech: PlaybackHls;
    playbackHandler: Hls;
    isSeeking = false;
    isBuffering = false;

    constructor(playbackTech: PlaybackHls, playbackHandler: Hls) {
        this.videoElement = playbackTech.videoElement;
        this.bufferingCallback = playbackTech.options.bufferingCallback || noop;
        this.bufferingPercentageCallback =
            playbackTech.options.bufferingPercentageCallback || noop;
        this.bufferingMonitorInterval =
            playbackTech.options.bufferingMonitorInterval;
        this.playbackTech = playbackTech;
        this.playbackHandler = playbackHandler;

        this.playbackHandler.on(Events.ERROR, this.eventHlsError);
        this.playbackHandler.on(
            Events.FRAG_LOADING,
            this.eventHlsFragLoadProgress
        );

        this.playbackHandler.on(Events.MANIFEST_LOADING, this.eventBufferStart);
        this.bufferingStartEvents.forEach((eventName) => {
            this.videoElement?.addEventListener(
                eventName,
                this.eventBufferStart
            );
        });

        this.playbackHandler.on(Events.FRAG_BUFFERED, this.eventBufferFinish);
        this.bufferingFinishEvents.forEach((eventName) => {
            this.videoElement?.addEventListener(
                eventName,
                this.eventBufferFinish
            );
        });
    }

    destroy(): void {
        this.playbackHandler.off(Events.ERROR, this.eventHlsError);
        this.playbackHandler.off(
            Events.FRAG_LOADING,
            this.eventHlsFragLoadProgress
        );

        this.playbackHandler.off(
            Events.MANIFEST_LOADING,
            this.eventBufferStart
        );
        this.bufferingStartEvents.forEach((eventName) => {
            this.videoElement?.removeEventListener(
                eventName,
                this.eventBufferStart
            );
        });

        this.playbackHandler.off(Events.FRAG_BUFFERED, this.eventBufferFinish);
        this.bufferingFinishEvents.forEach((eventName) => {
            this.videoElement?.removeEventListener(
                eventName,
                this.eventBufferFinish
            );
        });
    }

    eventBufferStart: (
        event: Event | Events.MANIFEST_LOADING | Events.ERROR
    ) => void = (event) => this.trySetBufferStart(event);

    trySetBufferStart(
        event: Event | Events.MANIFEST_LOADING | Events.ERROR
    ): void {
        if (event instanceof Event && event.type === 'seeking') {
            this.isSeeking = true;
        }

        if (this.isBuffering !== true) {
            this.isBuffering = true;
            this.triggerBufferingPercentage(0); // Because we are 'starting'.
            this.triggerBuffering(true);

            this.bufferPercentage = null;
            this.bufferingFragments = {};
        }
    }

    eventBufferFinish: () => void = () => this.trySetBufferFinished();

    trySetBufferFinished(): void {
        if (this.isBuffering !== false) {
            this.isBuffering = false;
            this.isSeeking = false;
            this.triggerBufferingPercentage(100); // Because we are 'done'.
            this.triggerBuffering(false);

            // Always clear out our previous buffer calculations on a finish.
            this.bufferPercentage = null;
            this.bufferingFragments = {};
        }
    }

    eventHlsError: HlsListeners[Events.ERROR] = (event, data) => {
        if (data.fatal) {
            this.eventBufferFinish();
        } else {
            switch (data.details) {
                case 'bufferStalledError':
                    this.eventBufferStart(event);
                    break;

                default:
                    break;
            }
        }
    };

    eventHlsFragLoadProgress: HlsListeners[Events.FRAG_LOADING] = (
        _,
        detail
    ) => {
        // Frags will always be loading, but we only
        // want to report if we are in a stalled buffer scenario.
        if (!this.isBuffering) {
            return;
        }

        const {url} = detail.frag;
        const loaded = detail.part?.stats?.loaded;
        const total = detail.part?.stats?.total;

        this.bufferingFragments[url] = {total, loaded};

        const requestsInFlight = Object.keys(this.bufferingFragments).reduce(
            (acc, fragmentUrl) => {
                acc.loaded += this.bufferingFragments[fragmentUrl]?.loaded ?? 0;
                acc.total += this.bufferingFragments[fragmentUrl]?.total ?? 0;

                return acc;
            },
            {
                loaded: 0,
                total: 0,
            }
        );

        let bufferPercentage =
            (requestsInFlight.loaded / requestsInFlight.total) * 100;

        if (isNaN(bufferPercentage)) {
            bufferPercentage = 0;
        }

        const bufferPercentageRounded = round(bufferPercentage, 2);

        // Throw new percentage if it's different!
        if (
            this.bufferPercentage === null ||
            this.bufferPercentage < bufferPercentage
        ) {
            this.bufferPercentage = bufferPercentage;
            this.triggerBufferingPercentage(bufferPercentageRounded);
        }
    };

    triggerBuffering(isBuffering: boolean): void {
        this.bufferingCallback?.(isBuffering);
        triggerCustomEvent(this.videoElement, 'fs-stalled-buffering', {
            isBuffering,
            isSeeking: this.isSeeking,
        });
    }

    triggerBufferingPercentage(bufferPercentage: number): void {
        this.bufferingPercentageCallback?.(bufferPercentage); // Hit any custom callback
        triggerCustomEvent(
            this.videoElement,
            'fs-stalled-buffering-percentage',
            {bufferPercentage}
        );
    }
}
