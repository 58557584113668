import React from 'react';
import ReactDOM from 'react-dom';
import bacon from 'baconjs';
import merge from 'lodash/merge';

import {handleStreamForIsoRender} from '@fsa/fs-commons/lib/streams/helper';
import {page as pageBoot}  from '@fsa/fs-commons/lib/iso/boot';
import {renderToHtml} from '@fsa/fs-commons/lib/iso/render';
import {getSportNames as getSportDetails} from '@fsa/fs-commons/lib/utils/sport-names';

import {DEFAULT_VIMOND_ENV} from '../utils/constants';
import {redirectNoLogin} from '../utils/auth';
import getUserStream from '../streams/user/user-get';
import ProfileComponent from '../components/profile';

const ISO_ERROR_EXPIRES_IN = 10; // 10 secs
const ISO_SUCCESS_EXPIRES_IN = 5 * 60; // 5 minutes.

function Profile(element, settings) {
    this.element  = element;
    this.settings = settings;

    this.config = {
        sport: this.settings.sport || 'afl',
        editDetailsUrl: this.settings.editDetailsUrl,
        changeBillingUrl: this.settings.changeBillingUrl,
        changePaymentUrl: this.settings.changePaymentUrl,
        changePasswordUrl: this.settings.changePasswordUrl,
        manageDevicesUrl: this.settings.manageDevicesUrl,
        manageSubscriptionUrl: this.settings.manageSubscriptionUrl,
        loginRoute: this.settings.loginRoute,
        vimondEnv: this.settings.vimondEnv || DEFAULT_VIMOND_ENV
    };
}

Profile.prototype.init = function (initialData = false) {
    redirectNoLogin(() => {
        this.closeStreams = this.getData(initialData).onValue(this.render.bind(this));
    }, {loginRoute: this.config.loginRoute});
};

Profile.prototype.initIso = function () {
    return new Promise((onResolve, onReject) => {
        this.closeStreams = this.getData()
            .take(1)
            .subscribe((event) => handleStreamForIsoRender({
                onReject,
                onResolve,
                event,

                identifier: 'HAWK: Profile',
                expirySecondOnFailure: ISO_ERROR_EXPIRES_IN,
                expirySecondOnSuccess: ISO_SUCCESS_EXPIRES_IN,
                render: this.render.bind(this)
            }));
    });
};

Profile.prototype.initComponentStream = function (initialData = false) {
    const data         = this.getData(initialData);

    const reactElement = data.map((data) => <ProfileComponent {...data.view} />);
    const iso          = data.map('.iso');

    return bacon.combineTemplate({data, reactElement, iso});
};

Profile.prototype.getData = function () {
    if (!process || !process.browser) {
        return bacon.later(0, {
            view: {
                sportDetails: getSportDetails(this.config.sport)
            },
            iso: {}
        });
    }

    const {
        editDetailsUrl,
        changeBillingUrl,
        changePaymentUrl,
        changePasswordUrl,
        manageDevicesUrl,
        manageSubscriptionUrl,
        sport,
        vimondEnv
    } = this.config;

    const updateUserBus = new bacon.Bus();

    const initialUserStream = getUserStream({sport, vimondEnv})
        .flatMapLatest((user) => (user));

    const currentUser = initialUserStream.combine(updateUserBus.toProperty(null), merge).startWith(null);

    return bacon.combineTemplate({
        view: bacon.combineTemplate({
            currentUser,
            editDetailsUrl,
            changeBillingUrl,
            changePaymentUrl,
            changePasswordUrl,
            manageDevicesUrl,
            manageSubscriptionUrl,
            sportDetails: getSportDetails(sport)
        })
    });
};

Profile.prototype.render = function (data) {
    if (this.element) {
        ReactDOM.render(
            <ProfileComponent {...data.view} />,
            this.element
        );
    } else {
        return renderToHtml(
            <ProfileComponent {...data.view} />,
            'hawkwidgets-profile',
            {
                settings: this.settings,
                data: data.iso
            }
        );
    }
};

Profile.prototype.remove = function () {
    try {
        this.closeStreams();
    } catch (e) {} // eslint-disable-line no-empty

    try {
        if (this.element) {
            ReactDOM.unmountComponentAtNode(this.element);
        }
    } catch (e) {} // eslint-disable-line no-empty
};

Profile.prototype.pageBoot = function () {
    pageBoot(Profile, 'hawkwidgets-profile');
};

export default function (element, settings) {
    return new Profile(element, settings);
}

/**
 * Calls the bootloader for the widget. The bootloader name is defined in fiso.js, e.g. 'video-mosaic'
 */
pageBoot(Profile, 'hawkwidgets-profile');
