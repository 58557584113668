import {isSafariComputer} from '@fsa-streamotion/browser-utils';

import get from 'lodash/get';
// eslint-disable-next-line import/no-nodejs-modules, node/no-deprecated-api
import {parse} from 'url';

const hlsFileExtensionCheck = /\.m3u8($|\?|;)/i;

export default class NativeDiagnostics {
    /**
     * The current source's CDN provider
     */
    #cdnProvider = '';

    /**
     * The playback container, if any. For example videos are 'packaged' into Dash and HLS formats so their container
     * will be Dash and HLS respectively. The native playback has no packaging, so the container
     * string is empty.
     */
    readonly #container = '';

    /**
     * Does the source have SSAI?
     */
    #hasSsai = false;

    /**
     * The current playback handler name, e.g. MSE/EME, Native, ...
     * The value depends on the playback handler type, and the technology the given playback handler uses.
     *  - Native - https://developer.mozilla.org/en-US/docs/Learn/JavaScript/Client-side_web_APIs/Video_and_audio_APIs
     *  - MSE/EME - Media Source Extensions / Encrypted Media Extensions - https://developers.google.com/web/fundamentals/media/eme
     *  - AVPlayer - Apple - https://developer.apple.com/documentation/avfoundation/avplayer
     *  - WebMAF - Playstation
     */
    #playbackHandlerName = 'Native';

    /**
     * The current playback handler type, e.g. native, dash, hls
     */
    playbackHandlerType = 'native';
    videoElement: HTMLVideoElement;
    src: string;

    /**
     * @param videoElement - The video element the playerTech will be attached to
     * @param src - The current source
     * @param cdnProvider - The current source's CDN provider
     * @param hasSsai - Does the source have SSAI?
     */
    constructor(
        videoElement: HTMLVideoElement,
        src: string,
        cdnProvider: string,
        hasSsai: boolean
    ) {
        this.videoElement = videoElement;
        this.src = src;
        this.#cdnProvider = cdnProvider;
        this.#hasSsai = hasSsai;
    }

    destroy(): void {
        return;
    }

    /**
     * Get the amount of dropped frames. Note that we don't override this for Dash because
     * Dash JS uses the same logic under the hood.
     */
    get droppedFrames(): number | undefined {
        // Ooooh we're a WebKit browser so we can get the value directly from the video element
        if (!isNaN(this.videoElement.webkitDroppedFrameCount)) {
            return this.videoElement.webkitDroppedFrameCount;
        }

        // For non-WebKit browsers, try this
        if (typeof this.videoElement.getVideoPlaybackQuality === 'function') {
            return get(
                this.videoElement.getVideoPlaybackQuality(),
                'droppedVideoFrames'
            );
        }

        // Browser doesn't have a way to get this value T___T
        return undefined;
    }

    get videoCodec(): string | undefined {
        return undefined;
    }

    /**
     * Get the amount of frames played so far. Note that we don't override this for Dash because
     * Dash JS uses the same logic under the hood.
     */
    get totalFrames(): number | undefined {
        // Ooooh we're a WebKit browser so we can get the value directly from the video element
        if (!isNaN(this.videoElement.webkitDecodedFrameCount)) {
            return this.videoElement.webkitDecodedFrameCount;
        }

        // For non-WebKit browsers, try this
        if (typeof this.videoElement.getVideoPlaybackQuality === 'function') {
            return get(
                this.videoElement.getVideoPlaybackQuality(),
                'totalVideoFrames'
            );
        }

        // Browser doesn't have a way to get this value T___T
        return undefined;
    }

    /**
     * The hostname of the current source
     */
    get host(): string | null {
        return parse(this.src).host;
    }

    /**
     * The height of the video element in px
     */
    get videoHeight(): number {
        return this.videoElement.videoHeight;
    }

    /**
     * The width of the video element in px
     */
    get videoWidth(): number {
        return this.videoElement.videoWidth;
    }

    /**
     * How many seconds ahead we have buffered
     */
    get bufferedSeconds(): number | undefined {
        const {buffered, currentTime} = this.videoElement;

        if (!buffered) {
            return undefined;
        }

        // buffered is not an array, it is a TimeRanges object
        for (let i = 0; i < buffered.length; i++) {
            if (
                currentTime >= buffered.start(i) &&
                currentTime <= buffered.end(i)
            ) {
                return buffered.end(i) - currentTime;
            }
        }

        return undefined;
    }

    /**
     * The playback handler's name.
     */
    get playbackHandlerName(): string {
        return this.#playbackHandlerName;
    }

    /**
     * The playback container.
     */
    get container(): string {
        if (hlsFileExtensionCheck.test(this.src) && isSafariComputer()) {
            // We are in Safari where HLS is natively supported.
            return 'HLS';
        }

        return this.#container;
    }

    /**
     * The CDN provider of the source.
     *
     * @returns - The CDN provider's name.
     */
    get cdnProvider(): string {
        return this.#cdnProvider;
    }

    /**
     * Does the source have SSAI?
     *
     * @returns - The SSAI flag.
     */
    get hasSsai(): boolean {
        return this.#hasSsai;
    }
}
