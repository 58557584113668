import {version as playerTechVersion} from '@fsa-streamotion/player-tech';
// eslint-disable-next-line no-duplicate-imports
import type PlayerTech from '@fsa-streamotion/player-tech';

import noop from 'lodash/noop';
import {EMPTY, NEVER, type Observable} from 'rxjs';
import {finalize} from 'rxjs/operators';
import YouboraAdapterHtml5 from 'youbora-adapter-html5';
import youbora from 'youboralib';

import type {YouboraAdapterConfig} from '../../state/types';
import {AUDIO_CODEC_CODE} from '../constants';

/**
 * Fire seek begin when seek happens, and not buffer
 *
 * @param this - YouboraAdapterHtml5
 */
function handleSeekBegin(this: YouboraAdapterHtml5): void {
    if (this.flags.isBuffering) {
        return; // if it is a buffer event, don't fire seek
    }

    this.fireSeekBegin();
}

/** Gets stream tracking single video
 *
 * @param payload - Payload for tracking single video (see below)
 * @param playerTech - Player Tech of the current video
 * @param youboraAccountId - youbora ID, eg foxsportsaustraliadev
 * @param udid - unique device ID
 * @param videoElement - current video element
 * @param getYouboraOptions - Youbora options for .setOptions()
 * @param getYouboraAdapterConfig - adapter config extension for YouboraAdapterHtml5.extend()
 * @param logger  - Custom logger
 *
 * @returns youbora tracking stream
 */

type Params = {
    youboraAccountId: string | null;
    udid?: string | null;
    videoElement: HTMLVideoElement;
    logger: {error: (value: string) => void};
    getYouboraAdapterConfig: (playerTech: PlayerTech) => YouboraAdapterConfig;
    getYouboraOptions: (playerTech: PlayerTech) => void;
    playerTech: PlayerTech;
};

type VideoErrorDetail = {
    detail?: {
        error?: MediaError;
    };
} & Event;

type VideoBufferDetail = {
    detail?: {
        isBuffering?: boolean;
        isSeeking?: boolean;
    };
} & Event;

export default function youboraTrackingForSingleVideo({
    playerTech,
    youboraAccountId,
    udid,
    videoElement,
    getYouboraOptions,
    getYouboraAdapterConfig,
    logger,
}: Params): Observable<never> {
    // Only track if we have a source.
    // Mounted _may_ have an initial source, but it's probably very unlikely (whole get the key thing)
    if (!playerTech.currentSource || !youboraAccountId) {
        return EMPTY;
    } else if (!getYouboraOptions || !getYouboraAdapterConfig) {
        logger.error(
            'StreamotionWebPlayer: Please provide getYouboraOptions & getYouboraAdapterConfig callbacks to enable Youbora tracking'
        );

        return EMPTY;
    }

    const youboraPlugin = new youbora.Plugin({
        'accountCode': youboraAccountId,
        'content.transactionCode': udid,
    });

    // We want to keep track of whether or not we've called stop, because otherwise we might accidentally call it twice (once on fatal error, once when we close player)
    let hasCalledStop = false;

    const videoErrorHandler = (event: VideoErrorDetail): void => {
        // Custom error fs-fatal-error-retry is in magical detail object from the error. This is because videoFS won't store the error
        // during a retry, and this is the only time we'll see what we're retrying.
        // If it's not set, go to the playerTech.error as you would normally.
        const error = event.detail?.error || playerTech.error;
        let errorDetailMessage;

        if (playerTech.errorDetail && 'message' in playerTech.errorDetail) {
            errorDetailMessage = playerTech.errorDetail.message;
        }

        const isFatalErrorRetry = event.type === 'fs-fatal-error-retry';

        // youboraPlugin.fireError(code, message, 'fatal'|'error')
        youboraPlugin.fireError(
            error?.code || 0,
            errorDetailMessage || error?.message || 'unknown',
            {
                fsFatalErrorRetry: isFatalErrorRetry,
            },
            isFatalErrorRetry ? 'error' : 'fatal'
        );

        if (!isFatalErrorRetry && !hasCalledStop) {
            // We're not retrying after this one
            youboraPlugin.fireStop();
            hasCalledStop = true;
        }
    };

    const videoBufferHandler = (event: VideoBufferDetail): void => {
        const {isBuffering = false, isSeeking = false} = event.detail || {};

        if (isSeeking) {
            return; // Handled by seek handler don't fire buffer
        }

        if (isBuffering) {
            youboraPlugin.getAdapter().fireBufferBegin();
        } else {
            youboraPlugin.getAdapter().fireBufferEnd();
        }
    };

    // Extend the HTML5 adapter with our fancy things for bitrate and error handling.
    const VideoFsHtmlAdapter = YouboraAdapterHtml5.extend({
        // Player name should be overridden by getYouboraAdapterConfig.
        getPlayerName: () => 'VideoFS',
        // Player version should be overridden by getYouboraAdapterConfig.
        getPlayerVersion: () => `pt${playerTechVersion}`,
        getResource: () => playerTech.currentSource,
        getRendition: () => {
            const {width, height, bitrate} = playerTech.currentBitrateLevel;

            return bitrate
                ? youbora.Util.buildRenditionString(width, height, bitrate)
                : null;
        },
        getBitrate: () =>
            playerTech.bitrateLevels?.[playerTech.bitrateCurrentIndex]
                ?.bitrate || null,
        getPlayrate: () => {
            // In before Ming comments on their invalid camelCase.
            // During pause apparently our playRate needs to say 0.
            if (!playerTech.isPlaying) {
                return 0;
            }

            // Otherwise return the current playbackRate.
            return playerTech.playbackRate;
        },
        getAudioCodec: () => {
            const fullAudioCodec =
                playerTech.audioTracks?.[playerTech.currentAudioTrackIndex]
                    ?.codec;
            const codec = fullAudioCodec?.match(/codecs="([^"]+)"/)?.[1] ?? '';
            
            return AUDIO_CODEC_CODE.get(codec) || fullAudioCodec;
        },
        getDroppedFrames: () => playerTech.diagnostics?.droppedFrames, // fantastically, this value resets when the source changes :D
        seekingListener: handleSeekBegin,
        loadStartListener: noop, // Prevent Youbora to `fireStop` due to `loadstart` event since the event might be triggered when switching DASH periods on some devices.
        endedListener: noop, //  Prevent Youbora call `fireStop` due to the playback handler still not fully destroyed, we'll call `fireStop` manually on the clean up function
        errorListener: videoErrorHandler,
        ...getYouboraAdapterConfig(playerTech), // Overrides default config
    });

    // Manually listen to the error retry event, and fire off like we would on a full .error.
    videoElement.addEventListener('fs-fatal-error-retry', videoErrorHandler);
    videoElement.addEventListener('fs-stalled-buffering', videoBufferHandler);

    youboraPlugin.setOptions(getYouboraOptions(playerTech));
    youboraPlugin.setAdapter(new VideoFsHtmlAdapter(videoElement));
    youboraPlugin.fireInit();

    // This stream's completion depends on it's subscribers.
    // So that we could run clean up code in finalize after it is ended.
    return NEVER.pipe(
        // Clean up
        finalize(() => {
            videoElement.removeEventListener(
                'fs-fatal-error-retry',
                videoErrorHandler
            );
            videoElement.removeEventListener(
                'fs-stalled-buffering',
                videoBufferHandler
            );

            // Youbora kill method.
            if (!hasCalledStop) {
                youboraPlugin.fireStop();
                hasCalledStop = true;
            }

            youboraPlugin.removeAdapter();
        })
    );
}
