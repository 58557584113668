import type {TextTrackInfo} from 'dashjs';
import isEqual from 'lodash/isEqual';

import type PlaybackNative from '.';
import type {SupportedPlaybackHandler} from '../../types';
import {getIsInPopOutMode} from '../../utils/browser';

const DEFAULT_TRACK_INDEX_WHEN_CC_ENABLED = 0;

export default class NativeCaptions {
    videoElement: HTMLVideoElement;
    playbackHandler: SupportedPlaybackHandler;

    currentTextTrackIndex = -1;
    enableClosedCaptions = false;
    textTracks: TextTrack[] | TextTrackInfo[] = [];
    shouldHideCue = false;
    startCCAtIndex: number;
    textTrackTimeout?: number;

    constructor(
        playbackTech: PlaybackNative,
        playbackHandler: SupportedPlaybackHandler
    ) {
        const {enableClosedCaptions = false, startCCAtIndex} =
            playbackTech.options || {};

        this.videoElement = playbackTech.videoElement as HTMLVideoElement;
        this.playbackHandler = playbackHandler;
        this.enableClosedCaptions = enableClosedCaptions;
        this.startCCAtIndex =
            startCCAtIndex || DEFAULT_TRACK_INDEX_WHEN_CC_ENABLED;

        this.setupPiPListeners();
        this.setup();
    }

    setup(): void {
        this.videoElement.textTracks.addEventListener(
            'addtrack',
            this.onEventTextTracksAddTrack
        );
    }

    setupPiPListeners(): void {
        this.videoElement.addEventListener(
            'enterpictureinpicture',
            this.onEventPiPEnter
        );
        this.videoElement.addEventListener(
            'leavepictureinpicture',
            this.onEventPiPLeave
        );

        /**
         * it's intentional that we listen to "change" rather than normalised "fs-captions-update" here as
         * this is a fix for platforms supporting pip only not CTV + it'll be temporary
         */
        this.videoElement.textTracks.addEventListener(
            'change',
            this.onEventTextTrackChange
        );
    }

    destroy(): void {
        this.textTracks = [];
        this.videoElement.textTracks.removeEventListener(
            'addtrack',
            this.onEventTextTracksAddTrack
        );
        this.videoElement.removeEventListener(
            'enterpictureinpicture',
            this.onEventPiPEnter
        );
        this.videoElement.removeEventListener(
            'leavepictureinpicture',
            this.onEventPiPLeave
        );
        this.videoElement.textTracks.removeEventListener(
            'change',
            this.onEventTextTrackChange
        );
        clearTimeout(this.textTrackTimeout);
    }

    /**
     * Handler for addtrack event, so if text tracks are available then initialise them based on config
     * Also fire a custom event 'fs-captions-updated' to notify that captions are updated, this is because
     * loadedmetadata event (which fires only once) loads captions info for Chrome/FF but not Safari :(
     *
     */
    onEventTextTracksAddTrack = (): void => {
        const newTracksList: TextTrack[] = [
            ...this.videoElement.textTracks,
        ].filter(({language}) => !!language); // video element on safari browser seems to hold on to empty text track

        if (isEqual(this.textTracksList, newTracksList)) {
            return;
        }

        this.disableTextTrack(); // force disable closed captions irrespective of what manifests tell us
        this.textTracksList = newTracksList;

        const {enableClosedCaptions, startCCAtIndex, textTracks} = this;

        // For some reason Safari doesnt display captions if track index is set along with other options.
        // setting the text track index on next tick so the caption is displayed correctly
        this.textTrackTimeout = window.setTimeout((): void => {
            if (!textTracks.length || !enableClosedCaptions) {
                return void this.dispatchCaptionsUpdatedEvent();
            }

            // If a starting text track index is not specified or out of bound, start with what is specified by manifest
            this.textTrack =
                Number.isInteger(startCCAtIndex) &&
                startCCAtIndex >= 0 &&
                startCCAtIndex < textTracks.length
                    ? startCCAtIndex
                    : DEFAULT_TRACK_INDEX_WHEN_CC_ENABLED;
        });
    };

    /** We do not want to show captions when PiP is enabled
     * So to handle that we listen to `enterpictureinpicture` and `leavepictureinpicture` events on `video` element
     * And text track `change` event on videoElement.textTracks
     * In these listener we simply hide the active text track when PiP enabled and show back when PiP disabled
     * TODO:// there are few browsers (safari, firefox) which supports captions in PiP window. And soon all the browsers
     * will start supporting captions in PiP mode, once this feature in available for all the browsers we want to rmeove
     * all these listners for hiding captions on PiP mode
     */

    onEventPiPEnter = (): void => {
        const currentTextTrack =
            this.videoElement.textTracks[this.currentTextTrackIndex];

        if (currentTextTrack) {
            currentTextTrack.mode = 'hidden';
        }
    };

    onEventPiPLeave = (): void => {
        const currentTextTrack =
            this.videoElement.textTracks[this.currentTextTrackIndex];

        if (currentTextTrack) {
            currentTextTrack.mode = 'showing';
        }
    };

    onEventTextTrackChange = (): void => {
        const currentTextTrack =
            this.videoElement.textTracks[this.currentTextTrackIndex];

        if (currentTextTrack) {
            const textTrackCurrentMode = currentTextTrack.mode;

            if (
                getIsInPopOutMode(this.videoElement) &&
                textTrackCurrentMode === 'showing'
            ) {
                currentTextTrack.mode = 'hidden';
            }
        }
    };

    /**
     * Returns a list of text tracks available for the current media
     *
     * @returns array of TextTrack object
     */
    get textTracksList(): TextTrack[] | TextTrackInfo[] {
        return this.textTracks;
    }

    set textTracksList(textTracks) {
        this.textTracks = textTracks;
    }

    /**
     * Sets the current text track based on the passed index
     *
     * @param index - the index of the closed caption to load
     */
    set textTrack(index: number) {
        const textTracks = this.textTracksList;
        const currentTextTrack = textTracks[index];

        if (!currentTextTrack) {
            return;
        }

        this.disableTextTrack();
        (currentTextTrack as TextTrack).mode = 'showing';

        this.currentTextTrackIndex = index;

        this.dispatchCaptionsUpdatedEvent();
    }

    /**
     * Disables text track for the current media
     *
     */
    disableTextTrack(): void {
        [...this.videoElement.textTracks] // Be strict and disable all video element text tracks irrespective of what textTracksList state holds
            .forEach((textTrack) => {
                textTrack.mode = 'disabled';
            });

        this.currentTextTrackIndex = -1;

        this.dispatchCaptionsUpdatedEvent();
    }

    /**
     * Dispatches captions updated event
     *
     */
    dispatchCaptionsUpdatedEvent(): void {
        this.videoElement.dispatchEvent(new CustomEvent('fs-captions-updated'));
    }

    /**
     * Returns boolean that indicate if cue element needs to be disabled
     *
     * @returns - enable/disable cue element
     */
    get shouldDisableCueElement(): boolean {
        return this.shouldHideCue;
    }

    /**
     * Sets value of shouldHideCue used to indicate if cue element needs to be disabled
     *
     * @param shouldHideCue - enable/disable cue element
     */
    set shouldDisableCueElement(shouldHideCue) {
        this.shouldHideCue = shouldHideCue;
    }
}
