import type Hls from 'hls.js';
// eslint-disable-next-line no-duplicate-imports
import {type HlsListeners, type FragBufferedData} from 'hls.js';
// eslint-disable-next-line node/no-deprecated-api
import {parse} from 'url';

import NativeDiagnostics from '../native/diagnostics';
import {Events} from './types';

export default class HlsDiagnostics extends NativeDiagnostics {
    lastBufferedFragments: FragBufferedData[] = [];

    override playbackHandlerType = 'hls';
    #container = 'HLS';
    #playbackHandlerName = 'MSE/EME';
    fragmentType: string | undefined;

    hlsInstance: Hls;

    constructor(
        hlsInstance: Hls,
        videoElement: HTMLVideoElement,
        src: string,
        cdnProvider: string,
        hasSsai: boolean
    ) {
        super(videoElement, src, cdnProvider, hasSsai);
        this.hlsInstance = hlsInstance;

        // The hlsInstance itself doesn't contain our .Events sadly, so rather than pass around the main/window's copy of Hls, just string it.
        this.hlsInstance.on(
            Events.FRAG_BUFFERED,
            this.updateLastBufferedFragments
        );
    }

    override destroy(): void {
        super.destroy();
        this.hlsInstance.off(
            Events.FRAG_BUFFERED,
            this.updateLastBufferedFragments
        );
    }

    updateLastBufferedFragments: HlsListeners[Events.FRAG_BUFFERED] = (
        _,
        data
    ) => {
        this.lastBufferedFragments.push(data);
        this.lastBufferedFragments = this.lastBufferedFragments.slice(-10);
        const fragmentUrlPathname = parse(data.frag.url).pathname as string;

        this.fragmentType = fragmentUrlPathname.split('.')?.[1] || '';
    };

    override get videoCodec(): string | undefined {
        const {levels, currentLevel} = this.hlsInstance;

        return levels[currentLevel]?.videoCodec;
    }

    get videoBitrate(): number | undefined {
        const {levels, currentLevel} = this.hlsInstance;

        return levels[currentLevel]?.bitrate;
    }

    get audioCodec(): string | undefined {
        const {levels, currentLevel} = this.hlsInstance;

        return levels[currentLevel]?.audioCodec;
    }

    get audioBitrate(): number | undefined {
        const {audioTracks, audioTrack} = this.hlsInstance;

        return audioTracks[audioTrack]?.bitrate;
    }

    get audioTrackName(): string | undefined {
        const {audioTracks, audioTrack} = this.hlsInstance;

        return audioTracks[audioTrack]?.name;
    }

    get frameRate(): number {
        const {levels, currentLevel} = this.hlsInstance;

        return Number(levels[currentLevel]?.attrs?.['FRAME-RATE']);
    }

    override get host(): string {
        return parse(this.src).host as string;
    }

    override get playbackHandlerName(): string {
        return this.#playbackHandlerName;
    }

    override get container(): string {
        return this.#container;
    }
}
