import first from 'lodash/first';
import property from 'lodash/property';

import type {SourceConfig, SupportedPlayerTech} from '../types';
import PlaybackDash from './dash';
import PlaybackHls from './hls';
import PlaybackNative from './native';
import PlaybackNativeHisenseModified from './native/hisense-modified';
import type {PlaybackHandlerArgs} from './types';

type ReturnTypes = Pick<PlaybackHandlerArgs, 'availableKeySystems'> &
    Pick<
        SourceConfig,
        | 'src'
        | 'type'
        | 'cdnProvider'
        | 'withCredentials'
        | 'hasSsai'
        | 'keySystems'
    > & {
        PlaybackHandler: SupportedPlayerTech;
        couldPlay: CanPlayTypeResult;
    };

export async function getBestPlaybackHandlerWithSource({
    sources = [],
    videoElement = null,

    // Order of preference for playback handler.
    playbackHandlers = [
        // PlaybackWebmaf,
        PlaybackDash,
        PlaybackHls,

        // Dear brave soul.  Hisense native playback bugs from 2020.
        // When hisense resolve video elements reloading their source after 'ended' event is fired, we can remove
        // this check, and NativePlaybackHisense override.

        // Make another test around #t= for startAt which is accounted there, and can be moved back to this
        // class's setup method if we want.
        PlaybackNativeHisenseModified,

        PlaybackNative,
    ],
}: {
    sources: SourceConfig[];
    videoElement: HTMLVideoElement | null;
    playbackHandlers?: SupportedPlayerTech[];
}): Promise<ReturnTypes | undefined> {
    const compatiblePlaybackAndSources = (
        await Promise.all(
            playbackHandlers.reduce<Promise<ReturnTypes>[]>(
                (acc, PlaybackHandler) => [
                    ...acc,
                    ...sources.map(
                        ({
                            src,
                            type,
                            cdnProvider,
                            hasSsai,
                            withCredentials,
                            keySystems,
                        }) =>
                            PlaybackHandler.canPlaySource({
                                src,
                                type,
                                videoElement,
                                keySystems,
                            }).then(async (couldPlay) => ({
                                availableKeySystems:
                                    await PlaybackHandler.getAvailableKeySystems(
                                        keySystems
                                    ),
                                cdnProvider,
                                couldPlay,
                                keySystems,
                                PlaybackHandler,
                                src,
                                hasSsai,
                                type,
                                withCredentials,
                            }))
                    ),
                ],
                []
            )
        )
    ).filter(property('couldPlay'));

    const couldPlayStrings = ['probably', 'maybe', ''];

    const sortedPlaybackAndSources = compatiblePlaybackAndSources.sort(
        (
            {
                couldPlay: couldPlayA,
                availableKeySystems: availableKeySystemsA = [],
            },
            {
                couldPlay: couldPlayB,
                availableKeySystems: availableKeySystemsB = [],
            }
        ) => {
            if (couldPlayA === couldPlayB) {
                // If we have the same couldPlay choose the one who has more DRM key system options
                return (
                    availableKeySystemsB.length - availableKeySystemsA.length
                );
            } else if (
                couldPlayStrings.includes(couldPlayA) &&
                couldPlayStrings.includes(couldPlayB)
            ) {
                // Otherwise, choose the one with the best couldPlay
                return (
                    couldPlayStrings.indexOf(couldPlayA) -
                    couldPlayStrings.indexOf(couldPlayB)
                );
            } else {
                return 0;
            }
        }
    );

    return first(sortedPlaybackAndSources);
}

export function triggerCustomEvent(
    element: HTMLElement | null,
    eventName: string,
    detail?: unknown
): void {
    try {
        const customEvent = new CustomEvent(eventName, {detail});

        element?.dispatchEvent(customEvent);
    } catch (e) {
        // Probably IE10 that doesn't support custom events. No bother to us.
    }
}
