import React from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';
import noop from 'lodash/noop';
import isEmpty from 'lodash/isEmpty';

import Fsvg from '@fsa-streamotion/streamotion-web-fs-ui/src/components/fsvg/fsvg';

import Faq from './faq';
import ChooseSubscription from './choose-subscription';
import MatchDetails from './match-details';
import Voucher from './voucher';

export default class OfferHome extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            voucherValue: '',
            isWaitingForVoucher: false
        };
        this.onVoucherChanged = this.onVoucherChanged.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps) { // eslint-disable-line camelcase
        if (this.state.isWaitingForVoucher && nextProps.isVoucherLoading) {
            this.setState({isWaitingForVoucher: false});
        }
    }

    onVoucherChanged(voucherValue) {
        this.setState({voucherValue, isWaitingForVoucher: true});
        this.props.onVoucherChangedArray.map((onVoucherChanged) => onVoucherChanged(voucherValue));
    }

    renderAppLink({description, url, fsvg} = {}, index) {
        if (!url) {
            return null;
        }

        return (
            <li className="fiso-hawk-offer-home__get-app-item" key={index}>
                <a href={url} title={description}>
                    <Fsvg
                        name={fsvg}
                        ariaLabel={description} />
                </a>
            </li>
        );
    }

    render() {
        // @TODO: clean up this awful, awful shit later
        // Hi from 2020!
        /* eslint-disable max-len */
        const faqData = {
            afl: [
                {
                    title: 'What is Watch AFL?',
                    content: (
                        <div className="fiso-hawk-faq__accordion-text">
                            <p>
                                Watch AFL is a subscription-based international service that delivers LIVE streams and on demand replays of
                                the Australian Football League to fans around the world.
                            </p>
                            <p>
                                It can be purchased in or outside Australia from <a href="https://www.watchafl.com.au" className="fiso-hawk-link">watchafl.com.au</a>, however the content within the Service
                                can only be viewed from outside Australia (for example, if you are living, working or holidaying outside of
                                Australia).
                            </p>
                            <p>
                                The service allows you to watch AFL matches in real-time on supported personal computers, tablets, mobile
                                devices and TVs. You can also catch up on any of the action you've missed (or want to watch again) with our
                                on demand service.
                            </p>
                            <p>
                                Watch AFL also gives you LIVE access to the 24/7 FOX FOOTY Channel for all of the latest AFL news, in-depth
                                analysis and entertainment shows.
                            </p>
                        </div>
                    )
                },
                {
                    title: 'What subscription options are available?',
                    content: (
                        <div className="fiso-hawk-faq__accordion-text">
                            <p>Every Watch AFL subscription gives you access to the same content and experience available on the service during your subscription period. Simply choose a subscription option that best suits your time overseas.</p>
                            <p>Season long access?</p>
                            <p>If you live or travel regularly outside Australia, we'd recommend the 12-month subscription. It auto renews every year on the same date unless you cancel beforehand. We'll send you an email to remind you before it rolls over and it's easy to cancel online.</p>
                            <p>Only need it for a short trip?</p>
                            <p>A weekly or monthly subscription will be best for you. These subscriptions will automatically renew until you cancel your subscription, which you can easily do online.</p>
                        </div>
                    )
                },
                {
                    title: 'How does Watch AFL work?',
                    content: (
                        <div className="fiso-hawk-faq__accordion-text">
                            <p>
                                To enjoy the Watch AFL streaming service, you require the following:
                            </p>
                            <ul>
                                <li>Watch AFL subscription</li>
                                <li>Watch AFL compatible device</li>
                                <li>an internet connection</li>
                                <li>access <a href="https://www.watchafl.com.au" className="fiso-hawk-link">watchafl.com.au</a> or download the Watch AFL App</li>
                            </ul>
                            <p>
                                Once all of the four above are in place, you are able to connect and start enjoying Watch AFL.
                            </p>
                        </div>
                    )
                },
                {
                    title: 'How do I subscribe?',
                    content: (
                        <div className="fiso-hawk-faq__accordion-text">
                            <p>
                                You can subscribe in a few simple steps.
                            </p>
                            <ol>
                                <li>Pick your subscription and price to suit your needs</li>
                                <li>Create an account by entering your details, including email and password</li>
                                <li>Enter your preferred payment method</li>
                                <li>Get watching.</li>
                            </ol>
                        </div>
                    )
                }
            ],
            nrl: [
                {
                    title: 'What is Watch NRL?',
                    content: (
                        <div className="fiso-hawk-faq__accordion-text">
                            <p>
                                Watch NRL is a subscription-based international service that delivers LIVE streams and on demand replays of
                                the National Rugby League to fans around the world.
                            </p>
                            <p>
                                It can be purchased in or outside Australia from <a href="https://www.watchnrl.com/" className="fiso-hawk-link">watchnrl.com</a>,
                                however the content within the Service can only be viewed from outside Australia, New Zealand and the
                                following Pacific Nations - Fiji, Samoa, Tonga, the Cook Islands, East Timor, Solomon Islands, Niue, Nauru,
                                Vanuatu, Kiribati, Northern Marianas, Tokelau, Tahiti, Tuvalu, New Caledonia, American Samoa, Marshall
                                Islands, Palau, and Federated States of Micronesia, Papua New Guinea, Wallis and Futuna, Iles esparses and
                                French Polynesia (for example, if you are living, working or holidaying outside of Australia).
                            </p>
                            <p>
                                The service allows you to watch NRL matches in real-time on supported personal computers, tablets, mobile
                                devices and TVs. You can also catch up on any of the action you've missed (or want to watch again) with our
                                on demand service.
                            </p>
                            <p>
                                Watch NRL also gives you LIVE access modified for international viewing to the 24/7 FOX LEAGUE Channel for all of the latest NRL news, in-depth analysis and entertainment shows.
                            </p>
                        </div>
                    )
                },
                {
                    title: 'What subscription options are available?',
                    content: (
                        <div className="fiso-hawk-faq__accordion-text">
                            <p>
                                Every Watch NRL subscription gives you access to the same content and experience available on the service during your subscription period. Simply choose a subscription option that best suits your time overseas.
                            </p>
                            <p>Season long access?</p>
                            <p>If you live or travel regularly outside Australia, New Zealand or the Pacific Islands, we'd recommend the 12-month subscription. It auto renews every year on the same date unless you cancel beforehand. We'll send you an email to remind you before it rolls over and it's easy to cancel online.</p>
                            <p>Only need it for a short trip?</p>
                            <p>A weekly or monthly subscription will be best for you. These subscriptions will automatically renew until you cancel your subscription, which you can easily do online.</p>
                        </div>
                    )
                },
                {
                    title: 'How does Watch NRL work?',
                    content: (
                        <div className="fiso-hawk-faq__accordion-text">
                            <p>
                                To enjoy the Watch NRL streaming service, you require the following:
                            </p>
                            <ul>
                                <li>an active Watch NRL subscription</li>
                                <li>Watch NRL compatible device</li>
                                <li>a strong internet connection</li>
                                <li>access <a href="https://www.watchnrl.com/" className="fiso-hawk-link">watchnrl.com</a> or download the Watch NRL App</li>
                            </ul>
                            <p>
                                Once all of the above are in place, you are able to connect and start enjoying Watch NRL.
                            </p>
                        </div>
                    )
                },
                {
                    title: 'How do I subscribe?',
                    content: (
                        <div className="fiso-hawk-faq__accordion-text">
                            <p>
                                You can subscribe in a few simple steps.
                            </p>
                            <ol>
                                <li>Pick your subscription and price to suit your needs</li>
                                <li>Create an account by entering your details, including email and password</li>
                                <li>Enter your preferred payment method</li>
                                <li>Start watching.</li>
                            </ol>
                        </div>
                    )
                }
            ]
        };
        const packageCtaText = {
            afl: (
                <p className="fiso-hawk-offer-home__choose-package-cta">
                    Living outside Australia or just away on holiday?
                    Choose the subscription that best suits your time overseas.
                    Subscribe now and start watching instantly.
                    <br />
                    Your subscription will continue to automatically renew unless you cancel before the subscription renewal date.
                </p>
            ),
            league: (
                <p className="fiso-hawk-offer-home__choose-package-cta">
                    Choose the subscription that best suits your time overseas.
                    Subscribe now and start watching instantly.
                    <br />
                    Your subscription will continue to automatically renew unless you cancel before the subscription renewal date.
                </p>
            )
        };

        const isVoucherWaitingOrLoading = this.state.isWaitingForVoucher || this.props.isVoucherLoading;
        // While the voucher is loading prevent users accidentally navigating the the pubs/venues link
        const pubsVenuesLinkOrNothing = isVoucherWaitingOrLoading ? null : this.props.pubsVenuesLink;

        return (
            /* eslint-disable multiline-ternary */
            <section
                className={classNames(
                    'fsui-normalise',
                    'fiso-hawk-offer-home',
                    {'fiso-hawk-offer-home--is-hidden': this.props.isHidden}
                )}>
                <div className="fiso-hawk-offer-home__banner">
                    {isEmpty(this.props.fixturesAndResults) ? (
                        this.props.promoHeader
                    ) : (
                        <MatchDetails
                            {...{
                                fixturesAndResults: this.props.fixturesAndResults,
                                matchStatus: this.props.matchStatus,
                                showMatchScore: this.props.showMatchScore,
                                teamStartCard: this.props.teamStartCard,
                                timerReminder: this.props.timerReminder,
                                title: this.props.title,
                                showBackLink: false,
                                onMatchCardToggleClick: this.props.onMatchCardToggleClick
                            }} />
                    )}
                </div>
                <div className="fiso-hawk-offer-home__content">
                    <div className="fiso-hawk-offer-home__content-container">
                        <div className="fiso-hawk-offer-home__content-col">
                            <h2 className="fiso-hawk-offer-home__subheading fiso-hawk-offer-home__subheading--choose-package">
                                Choose your plan
                            </h2>
                            {packageCtaText[this.props.sportDetails.apiName]}
                        </div>
                        <div className="fiso-hawk-offer-home__content-col">
                            <ChooseSubscription
                                currencySymbol={this.props.currencySymbol}
                                popularProduct={this.props.popularProduct}
                                products={this.props.products}
                                productGroupId={this.props.productGroupId}
                                isVoucherLoading={isVoucherWaitingOrLoading}
                                voucher={this.props.isVoucherValid ? this.state.voucherValue : ''}
                                sportDetails={this.props.sportDetails} />
                            <Voucher
                                success={this.props.isVoucherValid}
                                error={this.props.voucherError}
                                isLoading={this.props.isVoucherLoading}
                                initialVoucher={this.props.initialVoucher}
                                onVoucherChanged={this.onVoucherChanged} />
                            {!this.props.disableVenuesLink && (
                                <p className="fiso-hawk-offer-home__pubs-venues">
                                    <a
                                        href={pubsVenuesLinkOrNothing}
                                        className={classNames(
                                            'fiso-hawk-link',
                                            {'fiso-hawk-offer-home__pubs-venues--link-disabled': isVoucherWaitingOrLoading}
                                        )}
                                        target="_blank"
                                        rel="noopener noreferrer">
                                        Do you require a business subscription for a venue or pub?
                                    </a>
                                </p>
                            )}
                        </div>
                    </div>
                    <hr className="fiso-hawk-offer-home__separator" />
                    <div className="fiso-hawk-offer-home__content-container">
                        <div className="fiso-hawk-offer-home__content-col">
                            <h2 className="fiso-hawk-offer-home__subheading">Stream {this.props.sportDetails.label} overseas</h2>
                            <p className="fiso-hawk-offer-home__choose-package-cta">
                                Powered by FOX SPORTS, Watch {this.props.sportDetails.label} brings you:
                            </p>
                            {this.props.sportDetails.apiName === 'afl' && (
                                <ul className="fiso-hawk-offer-home__list">
                                    <li>Every current season Toyota AFL Premiership Season match and Finals Series match.</li>
                                    <li>Select classic matches.</li>
                                    <li>NAB AFLW and AAMI Community series.</li>
                                    <li>AFL news and entertainment shows from the FOX FOOTY channel.</li>
                                </ul>
                            )}
                            {this.props.sportDetails.apiName === 'league' && (
                                <ul className="fiso-hawk-offer-home__list">
                                    <li>Every current season NRL Telstra Premiership match including the Finals.</li>
                                    <li>Ampol State Of Origin Series and Women's NRL Telstra Premiership.</li>
                                    <li>Select classic matches.</li>
                                    <li>NRL news and entertainment shows from the FOX League channel.</li>
                                </ul>
                            )}
                        </div>
                        <div className="fiso-hawk-offer-home__content-col">
                            <p className="fiso-hawk-offer-home__on-devices">
                                <img src={this.props.mediaImage} aria-hidden="true" width="255" height="220" alt="" />
                            </p>
                        </div>
                    </div>
                    <hr className="fiso-hawk-offer-home__separator" />
                    <div className="fiso-hawk-offer-home__content-container">
                        <div className="fiso-hawk-offer-home__content-col">
                            <h2 className="fiso-hawk-offer-home__subheading">
                                Watch {this.props.sportDetails.label} your way
                            </h2>
                            <ul className="fiso-hawk-offer-home__list">
                                <li>Stream on your compatible phone, tablet or computer via the Watch {this.props.sportDetails.label} website or dedicated apps.</li>
                                <li>Big games deserve big screens. Watch on your TV using Chromecast, Airplay or the Apple TV app.</li>
                                <li>Download matches and shows to watch offline.</li>
                            </ul>
                            <ul className="fiso-hawk-offer-home__get-app-container">
                                {this.props.appLinks.map(this.renderAppLink)}
                            </ul>
                        </div>
                        <div className="fiso-hawk-offer-home__content-col">
                            <p className="fiso-hawk-offer-home__on-devices">
                                <Fsvg name="devices" ariaLabel="Available on Laptop, TV, Phone and Tablet" />
                            </p>
                        </div>
                    </div>
                    <hr className="fiso-hawk-offer-home__separator" />
                    <Faq faqData={faqData} sportDetails={this.props.sportDetails} />
                </div>
            </section>
            /* eslint-enable multiline-ternary,max-len */
        );
    }
}

OfferHome.defaultProps = {
    isHidden: true,
    onVoucherChangedArray: [noop]
};

OfferHome.propTypes = {
    isHidden: propTypes.bool,

    currencySymbol: propTypes.string,
    popularProduct: propTypes.number,
    products: propTypes.arrayOf(propTypes.shape({
        currency: propTypes.string,
        paymentPlan: propTypes.shape({
            name: propTypes.string
        }),
        id: propTypes.number,
        productPayments: propTypes.arrayOf(
            propTypes.shape({
                discountedPrice: propTypes.number,
                initPrice: propTypes.number,
                recurringDiscountedPrice: propTypes.number,
                recurringPrice: propTypes.number
            })
        )
    })),
    productGroupId: propTypes.number,
    disableVenuesLink: propTypes.bool,

    appLinks: propTypes.arrayOf(propTypes.shape({
        description: propTypes.string,
        fsvg: propTypes.string,
        url: propTypes.string
    })),

    initialVoucher: propTypes.string,
    isVoucherLoading: propTypes.bool,
    isVoucherValid: propTypes.bool,
    onVoucherChangedArray: propTypes.arrayOf(propTypes.func),
    voucherError: propTypes.string,

    supportedPlatformsUrls: propTypes.object,

    sportDetails: propTypes.shape({
        label: propTypes.string,
        route: propTypes.string,
        apiName: propTypes.string
    }),

    fixturesAndResults: propTypes.object,
    matchStatus: propTypes.string,
    showMatchScore: propTypes.bool,
    teamStartCard: propTypes.object,
    timerReminder: propTypes.object,
    title: propTypes.string,
    onMatchCardToggleClick: propTypes.func,
    promoHeader: propTypes.node,
    mediaImage: propTypes.string,
    pubsVenuesLink: propTypes.string
};
