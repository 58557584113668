import type {MediaInfo} from 'dashjs';
import isEmpty from 'lodash/isEmpty';

import type {AudioPreferences} from '../../types';
import sortAudioTracksByPreferences from '../../utils/sort-audio-tracks-by-preferences';
import {sortAudioTracksByCodec} from './audio';

type PreferenceTrackInfo = {
    audio?: AudioPreferences;
};

export default function createCustomInitialTrackSelectionFunction(
    preferences: PreferenceTrackInfo
) {
    /**
     * Sort tracks for initial selection
     *
     * First track will be used for initial request when period switch.
     *
     * NOTE: Dash.js probably selects a codec-incompatible audio track when switch period since it takes `audioChannelConfiguration` into accocunt rather than codec, see https://github.com/Dash-Industry-Forum/dash.js/blob/bd871c6576913181f82e1d7cd15f6d4ce3be0d6c/src/streaming/controllers/MediaController.js#L78-L85,
     * which causes the following issues when switch period:
     *  - Selected audio might be changed.
     *  - Playback stall on some CTV devices, e.g. Tizen.
     *
     * @param tracks - Supported tracks from manifest, `track` means `Period.AdaptationSet` in MPD.
     * @returns Sorted tracks
     */
    return function customInitialTrackSelection(tracks: MediaInfo[]) {
        if (tracks[0]?.type === 'audio') {
            // We got promised that we'll get audio tracks with consistent codecs and even order,
            // so keep selected track's codec consistent as much as possible when switch period,
            // in case dash.js might select a track with different codec due to its internal rules.
            if (isEmpty(preferences.audio)) {
                return sortAudioTracksByCodec(tracks);
            }

            return sortAudioTracksByPreferences<MediaInfo>(
                tracks,
                preferences.audio
            );
        }

        // NOTE: dash.js uses the setting `streaming.selectionModeForInitialTrack` to determine which track should be selected, see https://github.com/Dash-Industry-Forum/dash.js/blob/26439522db5eb3fdb2a781acc2622a58deec8a66/src/streaming/controllers/MediaController.js#L520-L548,
        // Typically, we have only one track for video, text(subtitle) and image(thumbnail), so just use the default track order from manifest.
        // If we get multiple tracks for these types one day, probably need to borrow some track selection rules from dash.js.
        return tracks;
    };
}
