import url from 'url'; // eslint-disable-line import/no-nodejs-modules
import React from 'react';
import ReactDOM from 'react-dom';
import bacon from 'baconjs';
import isEmpty from 'lodash/isEmpty';

import {handleStreamForIsoRender} from '@fsa/fs-commons/lib/streams/helper';
import {page as pageBoot}  from '@fsa/fs-commons/lib/iso/boot';
import {renderToHtml} from '@fsa/fs-commons/lib/iso/render';
import {getSportNames as getSportDetails} from '@fsa/fs-commons/lib/utils/sport-names';

import LoginComponent from '../components/login';
import loginStream from '../streams/login';
import getUser from '../../streams/user/user-get';
import getUserOrders from '../../streams/user/user-get-orders';
import getUserFutureOrders from '../../streams/user/user-get-future-orders';
import {redirectPage, getUrlParameter, getAllUrlParameters, removeQueryStringProps} from '../../utils/location';
import {DEFAULT_VIMOND_ENV, LOGIN_REQUIRED_PARAM} from '../../utils/constants';
import {trackLoggedInStatus} from '../../utils/event-tracking/authentication';

const ISO_ERROR_EXPIRES_IN = 10; // 10 secs
const ISO_SUCCESS_EXPIRES_IN = 5 * 60; // 5 minutes.

function Login(element, settings) {
    this.element  = element;
    this.settings = settings;

    this.config = {
        sport: this.settings.sport || 'afl',
        forgotPasswordUrl: this.settings.forgotPasswordUrl,
        subscribeUrl: this.settings.subscribeUrl,
        vimondEnv: this.settings.vimondEnv || DEFAULT_VIMOND_ENV
    };
}

Login.prototype.init = function (initialData = false) {
    this.closeStreams = this.getData(initialData)
        .onValue(this.render.bind(this));
};

Login.prototype.initIso = function () {
    return new Promise((onResolve, onReject) => {
        this.closeStreams = this.getData()
            .subscribe((event) => handleStreamForIsoRender({
                onReject,
                onResolve,
                event,

                identifier: 'HAWK: Login',
                expirySecondOnFailure: ISO_ERROR_EXPIRES_IN,
                expirySecondOnSuccess: ISO_SUCCESS_EXPIRES_IN,
                render: this.render.bind(this)
            }));
    });
};

Login.prototype.initComponentStream = function (initialData = false) {
    const data         = this.getData(initialData);
    const reactElement = data.map((data) => <LoginComponent {...data.view} />);
    const iso          = data.map('.iso');

    return bacon.combineTemplate({data, reactElement, iso});
};

Login.prototype.getData = function () {
    const redirectParam = 'return';
    const {sport, vimondEnv} = this.config;
    let redirectUrl = getUrlParameter(redirectParam) || '/';

    if (process && process.browser) {
        const redirectParsed = url.parse(redirectUrl);

        if (redirectParsed.hostname && (redirectParsed.hostname !== window.location.hostname)) {
            redirectUrl = '/';
        }
    }

    const newQueryString = removeQueryStringProps(redirectParam)(getAllUrlParameters());
    const doLoginBus = new bacon.Bus();

    const postLoginApiStream = doLoginBus
        .flatMapLatest((user) => loginStream(Object.assign({sport, vimondEnv}, user)))
        .startWith(null);

    const loginSuccessStream = postLoginApiStream
        .flatMapLatest(() => getUser({sport, vimondEnv}));

    const currentOrder$ = loginSuccessStream
        .flatMapLatest(({id}) => getUserOrders({
            sport,
            userId: id,
            vimondEnv
        }));

    const futureOrder$ = loginSuccessStream
        .flatMapLatest(({id}) => getUserFutureOrders({
            sport,
            userId: id,
            vimondEnv
        }));

    const activeOrder$ = bacon.combineWith(
        currentOrder$, futureOrder$,
        ((currentOrder, futureOrder) => isEmpty(currentOrder) ? futureOrder : currentOrder)
    );

    const redirectOnSuccess = activeOrder$
        // do action must wait for the previous bit to trigger/finish/getUserOrders
        .doAction(() => {
            trackLoggedInStatus(true);
            redirectPage(`${redirectUrl}${redirectUrl.indexOf('?') > -1 ? '&' : '?'}${newQueryString}`);
        })
        .startWith(null); // null to start so we have empty value to satisfy the final combineTemplate

    const loginErrorStream = postLoginApiStream
        .filter(false)
        .mapError('.message')
        .startWith(null);

    const isLoading = bacon.mergeAll(
        doLoginBus.map(true),
        postLoginApiStream.map(false),
        loginErrorStream.map(false)
    );

    return bacon.combineTemplate({
        view: bacon.combineTemplate({
            isLoading: isLoading.toProperty(false),
            forgotPasswordUrl: this.config.forgotPasswordUrl,
            subscribeUrl: this.config.subscribeUrl,
            error: loginErrorStream.toProperty(''),
            login: ({username, password, rememberMe}) => {
                doLoginBus.push({username, password, rememberMe});
            },
            isLoginRequired: !!getUrlParameter(LOGIN_REQUIRED_PARAM),
            sportDetails: getSportDetails(sport)
        }),
        iso: bacon.combineTemplate({
        }),
        // login stream isn't really listened to in react or iso,
        // but for management/subscription, it's gonna sit here
        redirectOnSuccess
    });
};

Login.prototype.render = function (data) {
    if (this.element) {
        ReactDOM.render(
            <LoginComponent {...data.view} />,
            this.element
        );
    } else {
        return renderToHtml(
            <LoginComponent {...data.view} />,
            'hawkwidgets-login',
            {
                settings:  this.settings,
                data:      data.iso
            }
        );
    }
};

Login.prototype.remove = function () {
    try {
        this.closeStreams();
    } catch (e) {} // eslint-disable-line no-empty

    try {
        if (this.element) {
            ReactDOM.unmountComponentAtNode(this.element);
        }
    } catch (e) {} // eslint-disable-line no-empty
};

export default function (element, settings) {
    return new Login(element, settings);
}

/**
 * Calls the bootloader for the widget. The bootloader name is defined in fiso.js, e.g. 'video-mosaic'
 */
pageBoot(Login, 'hawkwidgets-login');
