import React from 'react';
import ReactDOM from 'react-dom';
import bacon from 'baconjs';
import isEqual from 'lodash/isEqual';

import {initialLaterOr} from '@fsa/fs-commons/lib/streams/helper';
import {page as pageBoot}  from '@fsa/fs-commons/lib/iso/boot';
import {handleStreamForIsoRender, renderToHtml} from '@fsa/fs-commons/lib/iso/render';
import {asArray, asBool} from '@fsa/fs-commons/lib/utils/normalise-arg';
import {isPost} from '@fsa/fs-commons/lib/utils/match-status';

import MatchDetailsComponent from '../components/match-details';
import getFixturesAndResultsByFixtureId from '../streams/end-points/fixturesandresults-by-fixture-id';
import {getMassagedMatchDetailsData} from '../streams/match-details/match-details-massager';
import videoCarouselWithTabsWidget from './video-carousel-with-tabs';
import matchVideoWidget from './match-video';

const ISO_ERROR_EXPIRES_IN = 10;
const ISO_SUCCESS_EXPIRES_IN = 5 * 60;
const DELAY_SCORE_UPDATE = 15 * 1000; // 15 seconds

function MatchDetails(element, settings) {
    this.element  = element;
    this.settings = settings;

    this.config = {
        sport: this.settings.sport || 'afl',
        fixtureId: this.settings.fixtureId,
        freq: this.settings.freq || 30000,
        assetTypes: asArray(this.settings.assetTypes, null),
        displaySpoilers: asBool(this.settings.displaySpoilers, true),

        videoCarouselSettingsPreMatch: this.settings.videoCarouselSettingsPreMatch,
        videoCarouselSettingsPostMatch: this.settings.videoCarouselSettingsPostMatch,

        vimondEnv: this.settings.vimondEnv || 'prod'
    };
}

MatchDetails.prototype.init = function (initialData = false) {
    this.closeStreams = this.getData(initialData)
        .onValue(this.render.bind(this));
};

MatchDetails.prototype.initIso = function () {
    return new Promise((onResolve, onReject) => {
        this.closeStreams = this.getData()
            .take(1)
            .subscribe((event) => handleStreamForIsoRender({
                onReject,
                onResolve,
                event,

                identifier: 'HAWK: Match Details',
                expirySecondOnFailure: ISO_ERROR_EXPIRES_IN,
                expirySecondOnSuccess: ISO_SUCCESS_EXPIRES_IN,
                render: this.render.bind(this)
            }));
    });
};

MatchDetails.prototype.initComponentStream = function (initialData = false) {
    const data         = this.getData(initialData);
    const reactElement = data.map((data) => <MatchDetailsComponent {...data.view} />);
    const iso          = data.map('.iso');

    return bacon.combineTemplate({data, reactElement, iso});
};

MatchDetails.prototype.getData = function (initialData) {
    const {
        sport,
        freq,
        fixtureId,
        assetTypes,
        vimondEnv
    } = this.config;

    const getFixturesAndResults$ = initialLaterOr(
        initialData && initialData.getFixturesAndResults$,
        getFixturesAndResultsByFixtureId({
            sport,
            fixtureId,
            freq: 0,
            assetTypes,
            vimondEnv
        }))
        .flatMapLatest((fixtureAndResults) => {
            if (isPost(fixtureAndResults.match_status)) {
                return fixtureAndResults;
            }

            return bacon.later(freq || 30000, null)
                .skip(1)
                .concat(
                    getFixturesAndResultsByFixtureId({
                        sport,
                        fixtureId,
                        freq,
                        assetTypes,
                        vimondEnv
                    })
                        .delay(DELAY_SCORE_UPDATE)
                )
                .startWith(fixtureAndResults);
        });

    const fixturesAndResults$ = getFixturesAndResults$
        .flatMapLatest((fixtureResults) => getMassagedMatchDetailsData(fixtureResults, this.config));

    const videoCarousel$ = fixturesAndResults$
        .flatMapLatest((fixture) => {
            if (JSON.parse(fixture.videoCarouselSettings).length) {
                return videoCarouselWithTabsWidget(null, {
                    displaySpoilers: this.config.displaySpoilers,
                    videoCarouselSettings: fixture.videoCarouselSettings
                })
                    .initComponentStream(initialData && initialData.videoCarouselWithTabs);
            } else {
                return bacon.later(0, {});
            }
        });

    const matchVideos$ = matchVideoWidget(null, {
        sport,
        fixtureId,
        assetTypes,
        vimondEnv
    }).initComponentStream(initialData && initialData.matchVideo);

    const matchCardToggleClickBus = new bacon.Bus();
    const showMatchScore = bacon.update(
        false,
        [matchCardToggleClickBus], (toggleChecked) => !toggleChecked
    ).skipDuplicates(isEqual);

    return bacon.combineTemplate({
        view: bacon.combineTemplate({
            fixturesAndResults: fixturesAndResults$.map('.fixtureResults'),
            hasVideoPlayer: fixturesAndResults$.map('.hasVideoPlayer'),
            matchDetailType: fixturesAndResults$.map('.matchDetailType'),
            matchStatus: fixturesAndResults$.map('.matchStatus'),
            teamStartCard: fixturesAndResults$.map('.teamStartCard'),
            timerReminder: fixturesAndResults$.map('.timerReminder'),
            title: fixturesAndResults$.map('.title'),
            videoCarouselWithTabs: videoCarousel$.map('.reactElement'),
            matchVideo: matchVideos$.map('.reactElement'),

            showMatchScore,
            onMatchCardToggleClick: (toggleChecked) => {
                matchCardToggleClickBus.push(toggleChecked);
            }
        }),
        iso: bacon.combineTemplate({
            getFixturesAndResults$,
            matchVideo: videoCarousel$.map('.iso'),
            videoCarouselWithTabs: videoCarousel$.map('.iso')
        })
    }).doAction(() => {
        initialData = null; // eslint-disable-line
    });
};

MatchDetails.prototype.render = function (data) {
    if (this.element) {
        ReactDOM.render(
            <MatchDetailsComponent {...data.view} />,
            this.element
        );
    } else {
        return renderToHtml(
            <MatchDetailsComponent {...data.view}  />,
            'hawkwidgets-match-details',
            {
                settings: this.settings,
                data: data.iso
            }
        );
    }
};

MatchDetails.prototype.remove = function () {
    try {
        this.closeStreams();
    } catch (e) {} // eslint-disable-line no-empty

    try {
        if (this.element) {
            ReactDOM.unmountComponentAtNode(this.element);
        }
    } catch (e) {} // eslint-disable-line no-empty
};

MatchDetails.prototype.pageBoot = function () {
    pageBoot(MatchDetails, 'hawkwidgets-match-details');
};

pageBoot(MatchDetails, 'hawkwidgets-match-details');

export default function (element, settings) {
    return new MatchDetails(element, settings);
}
