import {
    isMsEdgeChromium,
    isWindows,
    isWebmaf,
    isPs5,
    isPs4,
    isHisenseU6,
    isTizen2022,
    isLg2020,
    isXbox,
    shouldUseDashjsEmeForHisense,
    loadScript,
    listenOnce,
} from '@fsa-streamotion/browser-utils';

import type {
    DashPeriod,
    DashManifestLoadedEvent,
    MediaPlayerClass,
    MediaPlayerSettingClass,
    PeriodSwitchEvent,
    DashContentProtection,
    FragmentLoadStartedEvent,
    FragmentLoadingCompletedEvent,
    CustomFragmentLoadingAbandonedEvent,
} from 'dashjs';
import isNumber from 'lodash/isNumber';
import merge from 'lodash/merge';
import noop from 'lodash/noop';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import result from 'lodash/result';

import type {BreakToRemove} from '../../types';
import {getShouldUseTizenAvplayer} from '../../utils/browser';
// import {ERROR_CODES} from '../../utils/error-codes';
import PlaybackNative from '../native';
import type {
    BufferRange,
    CanPlaySourceParams,
    KeySystemsOption,
} from '../types';
import {triggerCustomEvent} from '../utils';
import DashAudio from './audio';
import DashBuffer from './buffer';
import DashCaptions from './captions';
import {DEFAULT_DASHJS_CONFIG} from './constants';
import DashCustomBitrate from './custom-bitrate';
import createCustomInitialTrackSelectionFunction from './custom-initial-track-selection';
import DashDiagnostics from './diagnostics';
import DashEme from './eme';
import DashError from './error';
import DashLivestream from './livestream';
import type {DashJs, DashjsConfig} from './types';

// @TODO Investigate if this can actually be done (meta vs pre-fetch fragments), and if so, attempt to dedupe with HLS.js.
// Otherwise, leave it alone sean :P
const PRELOAD_PROGRESS = {
    NONE: 0,
    METADATA: 1,
    AUTO: 2,
};

// After upgrading to dash.js higher version than v4.3.0 (reproduced on v4.4.0 and v4.6.0), some videos may stall when seeking to the end of the video.
// This stall issue exists on the web and most CTV platforms, except for Xbox.
// Add this offset to void seeking to the end of the video
const END_SEEK_OFFSET = 1;
// Xbox note: playback might stall after seeking even though `video.currentTime` is buffered, dash.js can not break stall due to that `video.seeking` is set to true. `video.currentTime += 0` can also work around it.
const hasIntermittentStallIssue = (): boolean =>
    isHisenseU6() || isPs5() || isTizen2022() || isLg2020() || isXbox();

const fileExtensionCheck = /\.mpd($|\?|;)/i;

async function loadLibrary({
    src,
    integrity,
}: {
    src: string;
    integrity: string;
}): Promise<DashJs> {
    // eslint-disable-line import/prefer-default-export
    if (!window.dashjs) {
        await loadScript({
            url: src,
            integrity,
            crossOrigin: 'anonymous',
            id: 'smweb-playertech-dashjs-script',
        });
    }

    return window.dashjs;
}

const PLAYREADY_KEYSYSTEM_KEY = 'com.microsoft.playready';

export default class PlaybackDash extends PlaybackNative {
    static override async canPlaySource({
        src,
        type,
        keySystems,
    }: CanPlaySourceParams): Promise<CanPlayTypeResult> {
        const {MediaSource, WebKitMediaSource} = window || {};

        // by saying that we can't play source here, we ensure this playback handler is not
        // used as a fallback for devices that have poor/no support for the underlying tech
        // this handler uses
        if (isWebmaf() || getShouldUseTizenAvplayer()) {
            // can't use dash if there isn't a lib defined for it
            return '';
        }

        // We need to have MediaSource available in the browser.
        const hasDrmSupportOrIsUnnecessary =
            !keySystems ||
            (await PlaybackDash.getAvailableKeySystems(keySystems)).length > 0;

        // MediaSource is an object on PS4 (Webmaf 3) but a function on other devices
        if (
            (typeof (MediaSource || WebKitMediaSource) === 'function' ||
                typeof MediaSource === 'object') &&
            hasDrmSupportOrIsUnnecessary
        ) {
            if (result(type, 'toLowerCase') === 'application/dash+xml') {
                // Mime Type suggests dash, so we cool.
                return 'probably';
            } else if (fileExtensionCheck.test(src)) {
                // Educated guess with suggested file format being dash.
                return 'probably';
            } else {
                // Neither of these things, so... lets go with no?
                return '';
            }
        }

        return '';
    }

    static override async getAvailableKeySystems(
        keySystems: KeySystemsOption = {}
    ): Promise<MediaKeySystemAccess[]> {
        // PS4 doesn't support window.navigator.requestMediaKeySystemAccess
        // so we'll do a check to make sure it exists before calling it
        // eslint-disable-next-line compat/compat
        if (!window.navigator.requestMediaKeySystemAccess) {
            return [];
        }

        // Workaround for VPW-180 Edge dropping frames. Cause was Playready DRM, fix was to temporarily force it to use something else.
        // TODO revisit this on 4K feature story since we'll need Playready back.
        // Full context in https://foxsportsau.atlassian.net/browse/VPW-180
        const adjustedKeySystems =
            isMsEdgeChromium() && isWindows()
                ? omit(keySystems, [PLAYREADY_KEYSYSTEM_KEY])
                : keySystems;

        const requestMediaKeySystemAccessPromises = Object.entries(
            adjustedKeySystems
        ).map(
            ([
                keySystemName,
                {
                    videoContentType,
                    audioContentType,
                    videoRobustness,
                    audioRobustness,
                    ...rest
                },
            ]) => {
                const strictSupportedConfigurations = {
                    ...(audioContentType && {
                        audioCapabilities: [
                            {
                                contentType: audioContentType,
                                robustness: audioRobustness || '',
                            },
                        ],
                    }),
                    ...(videoContentType && {
                        videoCapabilities: [
                            {
                                contentType: videoContentType,
                                robustness: videoRobustness || '',
                            },
                        ],
                    }),
                    ...pick(rest, ['persistentState', 'distinctiveIdentifier']),
                };

                const looseSupportedConfigurations = {
                    ...(audioContentType && {
                        audioCapabilities: [
                            {
                                contentType: audioContentType,
                                robustness: audioRobustness || '',
                            },
                        ],
                    }),
                    ...(videoContentType && {
                        videoCapabilities: [
                            {
                                contentType: videoContentType,
                                robustness: videoRobustness || '',
                            },
                        ],
                    }),
                    ...pick(rest, ['distinctiveIdentifier']),
                };

                // eslint-disable-next-line compat/compat
                return window.navigator
                    .requestMediaKeySystemAccess(keySystemName, [
                        strictSupportedConfigurations,
                        looseSupportedConfigurations,
                    ])
                    .catch(() => null);
            }
        );

        return await Promise.all(requestMediaKeySystemAccessPromises).then(
            (keySystemAccesses) =>
                keySystemAccesses.filter(Boolean) as MediaKeySystemAccess[]
        );
    }

    dashInstance: MediaPlayerClass | null = null;
    streamLoadedState = PRELOAD_PROGRESS.NONE;
    #dashContext = {};
    #isDownloadingFragment = false;
    #removeLoadedEvent?: () => void;
    #removeTimeUpdateEvent?: () => void;
    declare controllerLivestreamInstance: DashLivestream;
    declare controllerAudioInstance: DashAudio;
    declare controllerBufferInstance: DashBuffer;
    declare controllerCaptionsInstance: DashCaptions;
    declare controllerCustomBitrateInstance: DashCustomBitrate;
    declare controllerError: DashError;
    declare controllerDiagnostics: DashDiagnostics;

    /**
     * #breaksToRemove - all breaks need to be removed
     */
    #breaksToRemove: BreakToRemove[] = [];
    private _kickStartPsStreamAfterPeriodSwitch: () => void = noop;
    private _recoverStallForStallEvent: () => void = noop;
    private _handleFragmentLoadingEvent: (
        event:
            | FragmentLoadStartedEvent
            | FragmentLoadingCompletedEvent
            | CustomFragmentLoadingAbandonedEvent
    ) => void = noop;

    private _startIntermittentStallDetect: () => void = noop;
    private intermittentStallDetector = -1;
    private _isPaused = true;
    private _duration = -1;

    _waitForInitialSeek = false;

    override async removeBreak(breakToRemove: BreakToRemove): Promise<void> {
        this.#breaksToRemove.push(breakToRemove);

        const {startTimeInSeconds, durationInSeconds} = breakToRemove;

        const timeToRestore = startTimeInSeconds + durationInSeconds;

        await this.resetAndRestore(this.#getAdjustedRestoreTime(timeToRestore));
    }

    override preloadMetadata(): void {
        if (this.streamLoadedState < PRELOAD_PROGRESS.METADATA) {
            if (this.dashInstance && this.src) {
                this.dashInstance?.attachSource(
                    this.src,
                    isNumber(this.options.startAt) && this.options.startAt >= 0
                        ? this.options.startAt
                        : undefined // undefined indicates from Live
                );
                this.streamLoadedState = PRELOAD_PROGRESS.METADATA;
            }
        }
    }

    override preloadAuto(): void {
        if (this.streamLoadedState < PRELOAD_PROGRESS.AUTO) {
            // If we don't have meta data yet, go get it.
            this.preloadMetadata();

            // @TODO This is where we would do pre-fetching of dash stream fragments and shit.
            // BUT WE'RE NOT LOOKING INTO IT TODAY OKAY!?

            this.streamLoadedState = PRELOAD_PROGRESS.AUTO;
        }
    }

    #dashjsConfig?: DashjsConfig;

    /**
     * Get the dashjs config, loading it if it hasn't already been loaded.
     *
     * This is a combination of the default dashjs config and, if provided, the
     * external dashjs config.
     *
     * @returns the combined dashjs config
     */
    async #getDashjsConfig(): Promise<DashjsConfig> {
        if (!this.#dashjsConfig) {
            // eslint-disable-next-line no-console
            console.debug(
                `[PlayerTech] Default dashjs config:\n\n${JSON.stringify(
                    DEFAULT_DASHJS_CONFIG,
                    null,
                    2
                )}`
            );

            const externalDashjsConfig = await this.options.getDashjsConfig?.();

            if (externalDashjsConfig || window.dashjsConfig) {
                // eslint-disable-next-line no-console
                console.debug(
                    `[PlayerTech] External dashjs config:\n\n${JSON.stringify(
                        externalDashjsConfig,
                        null,
                        2
                    )}`
                );

                // eslint-disable-next-line no-console
                console.debug(
                    `[PlayerTech] Window dashjs config:\n\n${JSON.stringify(
                        window.dashjsConfig,
                        null,
                        2
                    )}`
                );

                // Merge default and provided dash configs, preferring provided dash config values where there's a conflict.
                this.#dashjsConfig = merge(
                    {},
                    DEFAULT_DASHJS_CONFIG,
                    externalDashjsConfig,
                    window.dashjsConfig
                );
            } else {
                this.#dashjsConfig = DEFAULT_DASHJS_CONFIG;
            }

            // eslint-disable-next-line no-console
            console.debug(
                `[PlayerTech] Effective dashjs config:\n\n${JSON.stringify(
                    this.#dashjsConfig,
                    null,
                    2
                )}`
            );
        }

        return this.#dashjsConfig;
    }

    override async setup(): Promise<boolean> {
        const {script, settings} = await this.#getDashjsConfig();

        let dashjs: DashJs | null = null;

        if (script && script.src && script.integrity) {
            dashjs = await loadLibrary({
                src: script?.src,
                integrity: script?.integrity,
            });
        }
        // Load the dash.js library if necessary.

        if (!dashjs) {
            throw 'PlayerTech: DashJS was requested, but no suitable library was found.';
        }

        if (!this.videoElement) {
            return false;
        }

        this.dashInstance = dashjs.MediaPlayer(this.#dashContext).create(); // eslint-disable-line new-cap

        this.dashInstance.initialize(
            this.videoElement,
            undefined,
            this.options.autoPlay
        ); // element, src, autoplay

        // If withCredentials is set on this source, we need to tell dashJs to do XHR with credentials.
        // This will pass cookies along. You need to state 'which' things to do it for, unlike hlsjs which is just on all requests.
        // So make sure we do the manifest, init segments and actual media segments as well.
        if (this.options.withCredentials) {
            // Types from http://cdn.dashjs.org/latest/jsdoc/streaming_vo_metrics_HTTPRequest.js.html
            // HTTPRequest.GET = 'GET';
            // HTTPRequest.HEAD = 'HEAD';
            // HTTPRequest.MPD_TYPE = 'MPD';
            // HTTPRequest.XLINK_EXPANSION_TYPE = 'XLinkExpansion';
            // HTTPRequest.INIT_SEGMENT_TYPE = 'InitializationSegment';
            // HTTPRequest.INDEX_SEGMENT_TYPE = 'IndexSegment';
            // HTTPRequest.MEDIA_SEGMENT_TYPE = 'MediaSegment';
            // HTTPRequest.BITSTREAM_SWITCHING_SEGMENT_TYPE = 'BitstreamSwitchingSegment';
            // HTTPRequest.OTHER_TYPE = 'other';
            [
                'MPD',
                'InitializationSegment',
                'IndexSegment',
                'MediaSegment',
                'other',
            ].forEach((type) => {
                this.dashInstance?.setXHRWithCredentialsForType(type, true);
            });
        }

        const {licenseUri, getHttpRequestHeaders} =
            this.keySystems?.[PLAYREADY_KEYSYSTEM_KEY] || {};

        if (
            (shouldUseDashjsEmeForHisense() || isPs4() || isPs5()) &&
            licenseUri
        ) {
            const playReadyKeySystem =
                this.options.playReadyKeySystem || PLAYREADY_KEYSYSTEM_KEY;

            this.controllerEmeInstance = {
                activeKeySystemName: playReadyKeySystem,
                destroy: () => Promise.resolve(),
            };

            this.dashInstance.setProtectionData({
                [playReadyKeySystem]: {
                    serverURL: licenseUri,
                    httpRequestHeaders: getHttpRequestHeaders?.(),
                },
            });
        } else if (this.availableKeySystems?.length) {
            // If we have drm instructions, we'll need an EME Handler

            this.controllerEmeInstance = new DashEme({
                videoElement: this.videoElement,
                src: this.src ?? '',
                options: this.options,

                keySystems: this.keySystems,
                availableKeySystems: this.availableKeySystems,
                onError: this.onError,
            });

            // We want to noop all the DashJS Protection Controller methods since we're handling DRM ourselves in eme.js
            const protectionControllerOverride =
                this.dashInstance.getProtectionController();

            Object.getOwnPropertyNames(protectionControllerOverride).forEach(
                (method) =>
                    Object.assign(protectionControllerOverride, {
                        [method]: noop,
                    })
            );
            this.dashInstance.attachProtectionController(
                protectionControllerOverride
            );

            // MANIFEST_LOADED
            // We need to parse it, find PlayReady Licence URL (LA_URL) to hit
            // make sure we use that url into the controllerEmeInstance.
            this.dashInstance.on(
                'manifestLoaded',
                this._parseLaUrlFromManifest,
                this
            );
        }

        this.dashInstance.on(
            'manifestLoaded',
            this.#handleBreaksToRemove,
            this
        );

        // @TODO Consider propagating buffer events via callbacks in our logic over events
        // as they'd be a little bitty bit quicker. But for now, custom events are fine.
        this.controllerAudioInstance = new DashAudio({
            playbackTech: this,
            playbackHandler: this.dashInstance,
        });

        this.controllerBufferInstance = new DashBuffer(this, this.dashInstance);
        this.controllerCaptionsInstance = new DashCaptions(
            this,
            this.dashInstance
        );
        this.controllerCustomBitrateInstance = new DashCustomBitrate(
            this,
            this.dashInstance
        );
        this.controllerLivestreamInstance = new DashLivestream(
            this,
            this.dashInstance
        );
        this.controllerError = new DashError(this, this.dashInstance, dashjs);
        this.controllerDiagnostics = new DashDiagnostics(
            this.dashInstance,
            this.videoElement,
            this.src ?? '',
            this.cdnProvider,
            this.hasSsai
        );

        this.dashInstance.updateSettings({
            ...(settings as MediaPlayerSettingClass),
            debug: {
                logLevel: this.options.DEBUG_LIBS
                    ? dashjs?.Debug?.LOG_LEVEL_DEBUG
                    : dashjs?.Debug?.LOG_LEVEL_ERROR,
            },
        });

        this.setCustomInitialTrackSelectionFunction();

        this.psSetup();
        this.intermittentStallFixSetup();

        this.dashInstance.on(
            'playbackRateChanged',
            this._unstickPlaybackRate,
            this
        );
        this.dashInstance.on(
            window.dashjs.MediaPlayer.events.PERIOD_SWITCH_STARTED,
            this.#periodSwitchStarted,
            this
        );
        this.dashInstance.on(
            window.dashjs.MediaPlayer.events.PERIOD_SWITCH_COMPLETED,
            this.#periodSwitchCompleted,
            this
        );

        this.dashInstance.on(
            window.dashjs.MediaPlayer.events.STREAM_INITIALIZED,
            this.#setRefreshRateFor50FpsStream,
            this
        );

        if (
            this.options
                .enableDashSeamlessPeriodSwitchRegardlessOfDrmCompatibility
        ) {
            this.#removeLoadedEvent?.();
            this.#removeLoadedEvent = listenOnce(
                this.videoElement,
                'loadeddata',
                this.#forceSeamlessPeriodSwitch
            );
        }

        this.dashInstance.on(
            window.dashjs.MediaPlayer.events.DYNAMIC_TO_STATIC,
            this.handleDynamicToStatic,
            this
        );

        return true;
    }

    #periodSwitchCompleted = (dashPeriodInfo: PeriodSwitchEvent): void => {
        triggerCustomEvent(this.videoElement, 'fs-period-switch-completed', {
            periodInfo: {
                toStreamInfo: {
                    id: dashPeriodInfo.toStreamInfo?.id,
                    start: dashPeriodInfo.toStreamInfo?.start,
                },
            },
        });
    };

    #periodSwitchStarted = (dashPeriodInfo: PeriodSwitchEvent): void => {
        triggerCustomEvent(this.videoElement, 'fs-period-switch-started', {
            periodInfo: {
                fromStreamInfo: {
                    id: dashPeriodInfo.fromStreamInfo?.id,
                },
                toStreamInfo: {
                    id: dashPeriodInfo.toStreamInfo?.id,
                    start: dashPeriodInfo.toStreamInfo?.start,
                },
            },
        });
    };

    #getAdjustedRestoreTime = (restoreTime: number): number => {
        if (this.#breaksToRemove.length === 0) {
            return restoreTime;
        }

        // Dash will use startTime (we passed to it) + referenceTime to get a actually start time.
        // So we need to do some adjustment (startTime - referenceTime) to make sure we got a correct start time eventually.
        // https://github.com/Dash-Industry-Forum/dash.js/blob/745393012a1981101693f9dd0476ff1d15d7ea02/src/streaming/controllers/StreamController.js#L1173-L1196
        const firstBreak = this.#breaksToRemove.find(
            (item) => item.startTimeInSeconds === 0
        );

        return Math.max(restoreTime - (firstBreak?.durationInSeconds || 0), 0);
    };

    setCustomInitialTrackSelectionFunction(): void {
        this.dashInstance?.setCustomInitialTrackSelectionFunction(
            createCustomInitialTrackSelectionFunction({
                audio: this.options.audioPreferences,
            })
        );
    }

    psSetup(): void {
        if (!isPs5() && !isPs4()) {
            return;
        }

        if (this.options.startAt === -1) {
            // Our default play() call comes a little too early for PS5 live streams on Kayo so we should wait for the dash canPlay event
            const initialPlay = (): void => {
                this.play();
                this.dashInstance?.off('canPlay', initialPlay, this);
            };

            this.dashInstance?.on('canPlay', initialPlay, this);
        }

        // Playstation playback seems to stall after period-switching so this makes sure the player returns to the appropriate state
        this._kickStartPsStreamAfterPeriodSwitch = (): void => {
            if (!this.videoElement?.autoplay) {
                return;
            }

            if (this._isPaused) {
                this.pause();
            } else {
                this.play();
            }
        };

        this.dashInstance?.on(
            window.dashjs.MediaPlayer.events.PERIOD_SWITCH_COMPLETED,
            this._kickStartPsStreamAfterPeriodSwitch,
            this
        );
        this.dashInstance?.on(
            window.dashjs.MediaPlayer.events.PLAYBACK_WAITING,
            this._kickStartPsStreamAfterPeriodSwitch
        );
    }

    intermittentStallFixSetup(): void {
        if (!hasIntermittentStallIssue()) {
            return;
        }

        const seekForwardALittle = (): void => {
            if (this.#isDownloadingFragment) {
                return;
            }

            const STALL_FIX_SEEK_OFFSET = 0.2;

            this.dashInstance?.seek(
                (this.currentTime ?? 0) + STALL_FIX_SEEK_OFFSET
            );
        };

        this._recoverStallForStallEvent = () => {
            // Has Dash's internal seek failed to resolve because it's trying to seek into a gap?
            // OR have we hit a gap at the start of a video?
            if (this.currentTime === 0 || this.dashInstance?.isSeeking()) {
                seekForwardALittle();
            }
        };

        this._handleFragmentLoadingEvent = (event) => {
            const {mediaType} = event.request;

            if (mediaType === 'video') {
                this.#isDownloadingFragment =
                    event.type === 'fragmentLoadingStarted';
            }
        };

        this.dashInstance?.on(
            window.dashjs.MediaPlayer.events.PLAYBACK_STALLED,
            this._recoverStallForStallEvent,
            this
        );

        this.dashInstance?.on(
            window.dashjs.MediaPlayer.events.FRAGMENT_LOADING_STARTED,
            this._handleFragmentLoadingEvent,
            this
        );
        this.dashInstance?.on(
            window.dashjs.MediaPlayer.events.FRAGMENT_LOADING_COMPLETED,
            this._handleFragmentLoadingEvent,
            this
        );
        this.dashInstance?.on(
            window.dashjs.MediaPlayer.events.FRAGMENT_LOADING_ABANDONED,
            this._handleFragmentLoadingEvent,
            this
        );

        this._startIntermittentStallDetect = () => {
            const STALL_DETECT_INTERVAL = 3000;

            let previousTime: number | undefined;
            const tryToRecoverFromStall = (): void => {
                // The video has been stalled for STALL_DETECT_INTERVAL seconds. Try seekForwardLittle to recover it.
                if (
                    previousTime === this.currentTime &&
                    !this.videoElement?.paused &&
                    this.dashInstance?.isSeeking()
                ) {
                    seekForwardALittle();
                } else {
                    previousTime = this.currentTime;
                }
            };

            window.clearInterval(this.intermittentStallDetector);
            this.intermittentStallDetector = window.setInterval(
                tryToRecoverFromStall,
                STALL_DETECT_INTERVAL
            );
        };

        this.dashInstance?.on(
            window.dashjs.MediaPlayer.events.PLAYBACK_METADATA_LOADED,
            this._startIntermittentStallDetect,
            this
        );
    }

    // eslint-disable-next-line no-empty-function, @typescript-eslint/no-empty-function
    load(): void {}

    override pause(): void {
        this._isPaused = true; // Store this state so we know what to do after period switching (for PS5)
        super.pause();
    }

    override play(): void {
        this._isPaused = false; // Store this state so we know what to do after period switching (for PS5)

        if (this.dashInstance) {
            this.preloadAuto(); // Make sure we always have a stream ready.
            this.dashInstance.play();
        }
    }

    #isPeriodArray = (
        period: DashPeriod | DashPeriod[]
    ): period is DashPeriod[] => Array.isArray(period);

    #handleBreaksToRemove = ({data}: DashManifestLoadedEvent): void => {
        if (
            !this.#breaksToRemove.length ||
            !data ||
            !this.#isPeriodArray(data.Period)
        ) {
            return;
        }

        const clipIdsToRemove = this.#breaksToRemove.flatMap(
            ({breakClipIds}) => breakClipIds
        );

        const periodsToRemove = data.Period.filter(({id}) =>
            clipIdsToRemove.some((intervalId) => id === intervalId)
        );

        for (const periodToRemove of periodsToRemove) {
            data.Period.splice(data.Period.indexOf(periodToRemove), 1);
        }
    };

    override async destroy(): Promise<void> {
        this.#removeLoadedEvent?.();
        this.#removeTimeUpdateEvent?.();

        this.videoElement?.removeEventListener(
            'timeupdate',
            this.#dispatchEndedEventForSsaiLiveEvent
        );

        this.dashInstance?.off(
            'playbackRateChanged',
            this._unstickPlaybackRate,
            this
        );
        this.dashInstance?.off(
            'manifestLoaded',
            this._parseLaUrlFromManifest,
            this
        );
        this.dashInstance?.off(
            'manifestLoaded',
            this.#handleBreaksToRemove,
            this
        );
        this.dashInstance?.off(
            window.dashjs.MediaPlayer.events.PERIOD_SWITCH_STARTED,
            this.#periodSwitchStarted,
            this
        );
        this.dashInstance?.off(
            window.dashjs.MediaPlayer.events.PERIOD_SWITCH_COMPLETED,
            this.#periodSwitchCompleted,
            this
        );
        this.dashInstance?.off(
            window.dashjs.MediaPlayer.events.PERIOD_SWITCH_COMPLETED,
            this._kickStartPsStreamAfterPeriodSwitch,
            this
        );
        this.dashInstance?.off(
            window.dashjs.MediaPlayer.events.PLAYBACK_WAITING,
            this._kickStartPsStreamAfterPeriodSwitch,
            this
        );
        this.dashInstance?.off(
            window.dashjs.MediaPlayer.events.PLAYBACK_STALLED,
            this._recoverStallForStallEvent,
            this
        );
        this.dashInstance?.off(
            window.dashjs.MediaPlayer.events.FRAGMENT_LOADING_STARTED,
            this._handleFragmentLoadingEvent,
            this
        );
        this.dashInstance?.off(
            window.dashjs.MediaPlayer.events.FRAGMENT_LOADING_COMPLETED,
            this._handleFragmentLoadingEvent,
            this
        );
        this.dashInstance?.off(
            window.dashjs.MediaPlayer.events.FRAGMENT_LOADING_ABANDONED,
            this._handleFragmentLoadingEvent,
            this
        );
        this.dashInstance?.off(
            window.dashjs.MediaPlayer.events.PLAYBACK_METADATA_LOADED,
            this._startIntermittentStallDetect,
            this
        );
        this.dashInstance?.off(
            window.dashjs.MediaPlayer.events.DYNAMIC_TO_STATIC,
            this.handleDynamicToStatic,
            this
        );

        this.dashInstance?.off(
            window.dashjs.MediaPlayer.events.STREAM_INITIALIZED,
            this.#setRefreshRateFor50FpsStream,
            this
        );

        window.clearInterval(this.intermittentStallDetector);

        this.dashInstance?.reset();
        this.dashInstance = null;
        this._waitForInitialSeek = false;
        this.streamLoadedState = PRELOAD_PROGRESS.NONE;

        await super.destroy();
    }

    #dispatchEndedEventForSsaiLiveEvent = (): void => {
        const TIME_DIFF_THRESHOLD = 0.5;

        const currentTime = this.dashInstance?.time();
        const duration = this.dashInstance?.duration();

        // After ssai live ended and video current time about to reach to edge
        // Dash are not get correct duration and the video will infinite loading
        // To solve the issue, we'll have to manually emit an ended event and exit player
        if (
            typeof duration === 'number' &&
            typeof currentTime === 'number' &&
            duration - currentTime < TIME_DIFF_THRESHOLD
        ) {
            this.videoElement?.dispatchEvent(new Event('ended'));

            // stop listening the event and pause the video immediately to prevent multiple ended event sent out.
            this.videoElement?.removeEventListener(
                'timeupdate',
                this.#dispatchEndedEventForSsaiLiveEvent
            );
            this.pause();
        }
    };

    handleDynamicToStatic = async (): Promise<void> => {
        this.controllerLivestreamInstance?.triggerIsLive(false);

        if (this.controllerLivestreamInstance.hasMultiplePeriod) {
            this.videoElement?.addEventListener(
                'timeupdate',
                this.#dispatchEndedEventForSsaiLiveEvent
            );
        }
    };

    #getCurrentTimeToStore(): number {
        // If we reset immediately after openning the player, sometimes this.currentTime hasn't been set yet
        // In this case we should default at the original startAt. This prevents an intermittent issue with Resume Watching
        if (!this.currentTime && !this._isPaused) {
            return this.options.startAt;
        }

        // For live video, when resetAndRestore, can't directly use this.currentTime, which is relative to the DVR window
        if (this.controllerLivestreamInstance.live) {
            const metricStartTime =
                this.dashInstance?.getDashMetrics()?.getCurrentDVRInfo()?.range
                    .start ??
                this.options.startAt ??
                -1;

            return metricStartTime >= 0
                ? metricStartTime + this.currentTime
                : metricStartTime;
        }

        return Math.max(this.#getAdjustedRestoreTime(this.currentTime), 0);
    }

    override async resetAndRestore(timeToRestore?: number): Promise<void> {
        // 1. extract info so we can restore later
        const {videoElement, src, srcType} = this;

        this.options.startAt = timeToRestore ?? this.#getCurrentTimeToStore();
        this.options.autoPlay = !this._isPaused;

        // 2. destroying to reset
        await this.destroy();

        // 3. restoring
        Object.assign(this, {videoElement, src, srcType});
        await this.setup();

        if (this.options.autoPlay) {
            this.play();
        } else {
            this.preloadAuto();
        }
    }

    override get duration(): number {
        if (!this.dashInstance || !this.dashInstance.isReady()) {
            return 0;
        }

        // There's an issue with multi-period vod streams where the duration changes when
        // the last period is an ad so lets just store the duration when we have it.
        if (!this.dashInstance.isDynamic() && this._duration > 0) {
            return this._duration;
        }

        const duration = this.dashInstance.duration();

        this._duration = Number.isFinite(duration) ? duration : 0;

        return this._duration;
    }

    override get playbackRate(): number {
        return this.dashInstance?.getPlaybackRate() || 1;
    }

    override set playbackRate(newRate) {
        this.dashInstance?.setPlaybackRate(newRate);
    }

    override get bufferedTimeRanges(): BufferRange[] {
        const {buffered} = this.videoElement || {};
        const {length} = buffered || {};

        if (!length) {
            return [];
        }

        const metricStartTime = this.dashInstance?.isDynamic()
            ? this.dashInstance?.getDashMetrics()?.getCurrentDVRInfo()?.range
                  .start ?? 0
            : 0;

        return Array.from({length}, (_, i) => ({
            start: (buffered as TimeRanges).start(i) - metricStartTime,
            end: (buffered as TimeRanges).end(i) - metricStartTime,
        }));
    }

    override get currentTime(): number {
        // For the upgraded Hisense U6 device which was U5 previously, when switching period, this.dashInstance.getActiveStream() value can be null
        if (
            !this.dashInstance ||
            !this.dashInstance.isReady() ||
            !this.dashInstance.getActiveStream()
        ) {
            return this.options.startAt; // If we haven't initialized yet, assume we're at the default start time. This prevents the scrubhead from jumping around when we switch audio tracks.
        }

        const time = this.dashInstance.time();

        // When switching periods in a multiperiod stream, dashjs's `time()` can incorrectly report currenTime as `0`. in this case, we can get the real value by looking at the active stream's start time
        if (time === 0) {
            const activeStreamStart = this.dashInstance
                .getActiveStream()
                ?.getStreamInfo()?.start;

            if (activeStreamStart && activeStreamStart > 0) {
                return parseFloat(activeStreamStart.toFixed(5));
            }
        }

        return Number.isFinite(time) ? time : 0;
    }

    override set currentTime(seconds: number) {
        const parsedSeconds = parseFloat(seconds.toString()); // dash is very picky about being given a number
        const adjustedCurrentTime = this.duration - END_SEEK_OFFSET; // seeking to end of a video causes replay

        const newCurrentTime =
            parsedSeconds >= this.duration - END_SEEK_OFFSET &&
            adjustedCurrentTime > 0
                ? adjustedCurrentTime
                : parsedSeconds;

        if (this.dashInstance && this.dashInstance.isReady()) {
            this.dashInstance.seek(newCurrentTime);
        }
    }

    _unstickPlaybackRate(): void {
        // For some reason the playback rate is sometimes set to 0 after setup?
        if (this.playbackRate === 0) {
            this.playbackRate = 1;
        }
    }

    _getContentProtection = (
        period: DashPeriod | DashPeriod[]
    ): DashContentProtection[] => {
        const periodWithContentProtection = (
            this.#isPeriodArray(period) ? period : [period]
        ).find((period) => {
            const contentProtection =
                period?.AdaptationSet?.[0]?.Representation?.[0]
                    ?.ContentProtection;

            return contentProtection && contentProtection.length > 0;
        });

        return (
            periodWithContentProtection?.AdaptationSet?.[0]?.Representation?.[0]
                ?.ContentProtection ?? []
        );
    };

    _parseLaUrlFromManifest = ({data}: DashManifestLoadedEvent): void => {
        const contentProtections = this._getContentProtection(data.Period);

        const playReadyContentProtection = contentProtections.find(
            ({value = ''}) => value.startsWith('MSPR')
        ); // Full value is 'MSPR 2.0' for binge content at present.

        if (!playReadyContentProtection) {
            return;
        }

        // Found content protection detail about PlayReady.
        // Lets see if we can decode it, and update PlayReady Licence URL from the pssh base64 encoded string from the manifest.
        // If we encounter any error, don't worry about it. Just move on. This doesn't feel like a 'solid' way to handle this.
        try {
            const playReadyPsshText =
                playReadyContentProtection?.pssh?.__text || '';
            const decodedPlayReadyText = atob(playReadyPsshText);
            const cleanedDecodedText = decodedPlayReadyText.replace(
                // eslint-disable-next-line no-control-regex
                /[\x00-\x1F\x7F-\x9F]/g,
                ''
            ); // removes these invisible spaces between every character in this xml. Apparently 'normal' according to irdeto.
            const matchedLaUrl = cleanedDecodedText.match(
                /\<LA_URL\>(.+?)\<\/LA_URL\>/i
            )?.[1]; // this text is 'xml-ish', so we're regex-ing vs parsing as an xml document (has weird start before the document).

            const playReadyKeySystem =
                this.keySystems?.[PLAYREADY_KEYSYSTEM_KEY];
            const playReadyLicenseUri = playReadyKeySystem?.licenseUri;

            if (
                playReadyLicenseUri &&
                matchedLaUrl && // if we have both a matched uri from manifest, and a setting for PR uri
                playReadyLicenseUri !== matchedLaUrl // and these aren't the same, scream at the top of your lungs little one!
            ) {
                const errorDetailToLog = {
                    fromManifest: matchedLaUrl,
                    fromKeySystemLicenseUri: playReadyLicenseUri,
                    src: this.src,
                    location: window.location.href,
                    wasUsingPlayReady:
                        this.controllerEmeInstance?.activeKeySystemName ===
                        PLAYREADY_KEYSYSTEM_KEY,
                };

                console.error(
                    'VideoFS DashJs LA_URL Issue: Detected mismatched manifest LA_URL to keySystem licenseUri',
                    errorDetailToLog
                );

                if (this.keySystems?.[PLAYREADY_KEYSYSTEM_KEY]) {
                    // Update the URL in the keySystem for playready for use.
                    this.keySystems[PLAYREADY_KEYSYSTEM_KEY].licenseUri =
                        matchedLaUrl;
                }

                // NewRelic dirty log here.
                if (window.newrelic && window.newrelic.noticeError) {
                    window.newrelic.noticeError(
                        new Error(
                            'VideoFS DashJs LA_URL Issue: Detected mismatched manifest LA_URL to keySystem licenseUri'
                        ),
                        errorDetailToLog
                    );
                }
            }
        } catch (e) {
            console.error(
                'VideoFS DashJs LA_URL Issue: Unable to complete check',
                e
            );
        }
    };

    override get supportsAirPlay(): boolean {
        return false;
    }

    override showAirPlayTargetPicker(): void {
        console.warn('Unable to use showAirPlayTargetPicker for Dash.');
    }

    override get isPlaying(): boolean {
        if (!this.videoElement) {
            return false;
        }

        return !this.videoElement.paused;
    }

    /**
     * Dash.js doesn't trigger seamlesss period switch when current period is unencrypted and the upcoming one is encrypted.
     * See also https://github.com/Dash-Industry-Forum/dash.js/blob/a1cc5b2cd615e08e2d212da7b15884c54a9d646a/src/streaming/controllers/StreamController.js#L612-L650
     * It causes a delay to buffer between ad breaks and DRM protected content.
     * Allowing seamlesss period switch still works well to us.
     * Dash.js probably needs a update for this, see https://github.com/Dash-Industry-Forum/dash.js/issues/4137
     */
    #forceSeamlessPeriodSwitch = (): void => {
        // Hacky way to let Dash.js know we're ready to preload DRM periods
        const dashEventBus = window.dashjs.FactoryMaker.getSingletonInstance(
            this.#dashContext,
            'EventBus'
        );

        dashEventBus.trigger(
            window.dashjs.MediaPlayer.events.KEY_SESSION_UPDATED
        );
    };

    // We'll want to update the refresh rate if the framerate comes through as 50fsp or 25fps
    #setRefreshRateFor50FpsStream = (): void => {
        if (!this.dashInstance) {
            return;
        }

        this.dashInstance.off(
            window.dashjs.MediaPlayer.events.STREAM_INITIALIZED,
            this.#setRefreshRateFor50FpsStream,
            this
        );

        const streamInfo = this.dashInstance.getActiveStream()?.getStreamInfo();
        const dashAdapter = this.dashInstance.getDashAdapter();
        const dashMetrics = this.dashInstance.getDashMetrics();

        if (!streamInfo) {
            return;
        }

        const periodIdx = streamInfo.index;
        const repSwitch = dashMetrics.getCurrentRepresentationSwitch('video');
        const adaptation = dashAdapter.getAdaptationForType(
            periodIdx,
            'video',
            streamInfo
        );

        const currentRep = adaptation?.Representation_asArray.find(
            (rep) => rep.id === repSwitch.to
        );
        const frameRate = currentRep?.frameRate;

        if (!frameRate) {
            return;
        }

        // frameRate comes to us in a format like 50/1 so we'll need to parse the value before we can use it
        const [numerator, denominator = 1] = frameRate
            .toString()
            .split('/')
            .map((token) => parseInt(token));

        if (!numerator || !Number.isFinite(numerator)) {
            return;
        }

        const numericFrameRate = numerator / denominator;

        this.options.setRefreshRate?.(numericFrameRate);
    };
}
