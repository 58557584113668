import React from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';
import noop from 'lodash/noop';
import get from 'lodash/get';

import Fsvg from '@fsa-streamotion/streamotion-web-fs-ui/src/components/fsvg/fsvg';

import Voucher from '../voucher';

export default class VoucherProductForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isReadMoreShown: false,
            voucherValue: '',
            isWaitingForVoucher: false
        };
        this.toggleReadMore = this.toggleReadMore.bind(this);
        this.onVoucherChanged = this.onVoucherChanged.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps) { // eslint-disable-line camelcase
        if (this.state.isWaitingForVoucher && nextProps.isVoucherLoading) {
            this.setState({isWaitingForVoucher: false});
        }
    }

    onVoucherChanged(voucherValue) {
        this.setState({voucherValue, isWaitingForVoucher: true});
        this.props.onVoucherChanged(voucherValue);
    }

    toggleReadMore() {
        this.setState({isReadMoreShown: !this.state.isReadMoreShown});
    }

    wrapInParagraph(str, identifier, pClassName = 'fiso-hawk-subscribe__product-text') {
        return str.map((fragment, key) => (
            <p className={pClassName} key={`${identifier}-${key}`}>
                {fragment}
            </p>
        ));
    }

    renderProductDescription() {
        if (!this.props.productDescriptionText.productShortDescription.length) {
            return null;
        }

        const shortDescription = this.props.productDescriptionText.productShortDescription;
        const remainingDescription = this.props.productDescriptionText.productRemaningDescription;
        const additionalProductTextClass = classNames(
            'fiso-hawk-subscribe__additional-product-text',
            {'fiso-hawk-subscribe__additional-product-text--expanded': this.state.isReadMoreShown}
        );

        return (
            <div>
                {this.wrapInParagraph(shortDescription, 'short-description')}
                {!!remainingDescription.length && !this.state.isReadMoreShown && (
                    <button
                        className={classNames(
                            'fiso-hawk-subscribe__text-toggle',
                            'fiso-hawk-button',
                            'fiso-hawk-button--ghost',
                            'fiso-hawk-button--chevron-right',
                            `fiso-hawk-fill-secondary--${this.props.sportDetails.route}`)}
                        onClick={this.toggleReadMore}>
                            Read more
                        <Fsvg name="next-1" />
                    </button>
                )}
                {!!remainingDescription.length && (
                    <div className={additionalProductTextClass}>
                        {this.wrapInParagraph(remainingDescription, 'detailed-description')}
                    </div>
                )}
            </div>
        );
    }

    render() {
        const {groupDescription, voucherDescription} = this.props.productDescriptionText;
        const showSpecialOfferTitle = !(this.props.teamDetail || this.props.isPubsClubs); // show it if not pubs/venues, nor club page
        const shouldShowVoucherCopy = this.props.isVoucherValid && !this.props.voucherError && this.state.voucherValue &&
            !this.state.isWaitingForVoucher && !!voucherDescription.length;
        const groupDescriptionClass = 'fiso-hawk-subscribe__p';
        const returnUrl = this.props.initialVoucher ? `/?voucher=${this.props.initialVoucher}` : '/';

        // MW-385: the discount could be a forevermore discount (on recurringDiscountedPrice)
        // or a once-off discount (on discountedPrice) and we need to show the
        // recurringPrice or initPrice depending on if initPrice === 0 (free trial period) for the "was" value
        // and recurringDiscountedPrice or discountedPrice as the "new price"
        const {price} = this.props.currentProduct;
        const recurPrice = get(this.props.currentProduct.productPayments, '[0].recurringPrice', price);
        const recurDiscPrice = get(this.props.currentProduct.productPayments, '[0].recurringDiscountedPrice', price);
        const initDiscPrice = get(this.props.currentProduct.productPayments, '[0].discountedPrice', price);
        const initPrice = get(this.props.currentProduct.productPayments, '[0].initPrice', price);

        const isFreeTrial = initPrice === 0;
        const hasDiscount = recurPrice > recurDiscPrice || (initPrice !== 0 && initPrice > initDiscPrice);

        let oldPrice = price;
        let newPrice = price;

        if (hasDiscount && !isFreeTrial) {
            // not a free trial, and if one-off voucher, use initial/initialDiscounted price values, since recurring price values are full price
            oldPrice = initPrice;
            newPrice = initDiscPrice;
        } else if (hasDiscount && isFreeTrial) {
            // free trial means initial/initialDiscounted price values are both 0, but the voucher is a recurring kind, so use recurring/recurringDiscount prices
            oldPrice = recurPrice;
            newPrice = recurDiscPrice;
        }

        return (
            <section className="fiso-hawk-subscribe__voucher-product-form">
                {showSpecialOfferTitle && (
                    <h2 className="fiso-hawk-subscribe__heading-2">Your subscription</h2>
                )}
                {!this.props.isPubsClubs && !!groupDescription && (
                    <div>
                        {this.wrapInParagraph(groupDescription, 'productGroup-description', groupDescriptionClass)}
                    </div>
                )}
                <div
                    className={classNames(
                        'fiso-hawk-subscribe__row',
                        'fiso-hawk-subscribe__row--collapsed')}>
                    <div
                        className={classNames(
                            'fiso-hawk-subscribe__col--chosen-sub',
                            'fiso-hawk-subscribe__col--nopadding')}>
                        <p className="fiso-hawk-subscribe__call-out">
                            {this.props.currentProduct.paymentPlan.name + ' '}
                            {hasDiscount && (
                                <span className="fiso-hawk-subscribe__call-out-strikethrough">
                                    {this.props.currencySymbol + oldPrice.toFixed(2)}
                                </span>
                            )}
                            {' ' + this.props.currencySymbol + newPrice.toFixed(2)}
                        </p>
                        <p>Price displayed in {this.props.currentProduct.currency}</p>
                        {!this.props.teamDetail && <p><a href={returnUrl} className="fiso-hawk-link">Change Plan</a></p>}
                        {!this.props.isVoucherless && (
                            <Voucher
                                success={this.props.isVoucherValid}
                                error={this.props.voucherError}
                                isLoading={this.props.isVoucherLoading}
                                isVoucherOptional={this.props.isVoucherOptional}
                                initialVoucher={this.props.initialVoucher}
                                onVoucherChanged={this.onVoucherChanged} />
                        )}
                    </div>

                    {(!!this.props.productDescriptionText.productShortDescription.length || shouldShowVoucherCopy) && (
                        <div
                            className={classNames(
                                'fiso-hawk-subscribe__col--other-details',
                                'fiso-hawk-subscribe__col--nopadding')}>
                            {shouldShowVoucherCopy && this.wrapInParagraph(voucherDescription, 'voucher-description')}
                            {!shouldShowVoucherCopy && this.renderProductDescription()}
                        </div>
                    )}
                </div>
            </section>
        );
    }
}

VoucherProductForm.defaultProps = {
    isVoucherValid: false,
    onVoucherChanged: noop,
    serverErrorsNewUser: [],
    teams: []
};

VoucherProductForm.propTypes = {
    initialVoucher: propTypes.string,
    isVoucherless: propTypes.bool,
    isVoucherLoading: propTypes.bool,
    isVoucherOptional: propTypes.bool,
    isVoucherValid: propTypes.bool,
    onVoucherChanged: propTypes.func,
    voucherError: propTypes.string,

    currencySymbol: propTypes.string,
    currentProduct: propTypes.shape({
        currency: propTypes.string,
        description: propTypes.string,
        id: propTypes.number,
        paymentPlan: propTypes.shape({
            name: propTypes.string
        }),
        productPayments: propTypes.arrayOf(propTypes.shape({
            discountedPrice: propTypes.number
        })),
        price: propTypes.number,
        voucher: propTypes.string
    }),

    productDescriptionText: propTypes.shape({
        groupDescription: propTypes.oneOfType([
            propTypes.array,
            propTypes.bool
        ]),
        productShortDescription: propTypes.oneOfType([
            propTypes.array,
            propTypes.bool
        ]),
        productRemaningDescription: propTypes.oneOfType([
            propTypes.array,
            propTypes.bool
        ]),
        voucherDescription: propTypes.oneOfType([
            propTypes.array,
            propTypes.bool
        ])
    }),

    sportDetails: propTypes.shape({
        route: propTypes.string
    }),

    teamDetail: propTypes.object,

    isPubsClubs: propTypes.bool
};
