import React from 'react';
import ReactDOM from 'react-dom';
import bacon from 'baconjs';
import get from 'lodash/get';
import moment from 'moment';

import {initialLaterOr, handleStreamForIsoRender} from '@fsa/fs-commons/lib/streams/helper';
import {page as pageBoot}  from '@fsa/fs-commons/lib/iso/boot';
import {renderToHtml} from '@fsa/fs-commons/lib/iso/render';
import {displayDateFormat} from '@fsa/fs-commons/lib/utils/date';
import {getSportNames as getSportDetails} from '@fsa/fs-commons/lib/utils/sport-names';

import commingUpStreamByChannelAndSize from '../streams/tv-guide/comming-up';
import nowPlayingStreamByChannel from '../streams/tv-guide/now-playing';
import programmesStreamByChannelAndDate from '../streams/tv-guide/programmes';
import TvGuideComponent from '../components/tv-guide/upcoming';

const EXPIRY_SECONDS = 60; // 1 mins

function TvGuideSimple(element, settings) {
    this.element  = element;
    this.settings = settings;

    this.config = {
        channel: this.settings.channel,
        size: this.settings.size,
        sport: this.settings.sport || 'afl'
    };
}

TvGuideSimple.prototype.init = function (initialData = false) {
    this.closeStreams = this.getData(initialData)
        .onValue(this.render.bind(this));
};

TvGuideSimple.prototype.initIso = function () {
    return new Promise((onResolve, onReject) => {
        this.closeStreams = this.getData()
            .take(1)
            .subscribe((event) => handleStreamForIsoRender({
                onReject,
                onResolve,
                event,

                identifier:            'HAWK: TvGuideSimple',
                expirySecondOnFailure: 10,
                expirySecondOnSuccess: EXPIRY_SECONDS,
                render:                this.render.bind(this)
            }));
    });
};

TvGuideSimple.prototype.initComponentStream = function (initialData = false) {
    const data         = this.getData(initialData);
    const reactElement = data.map((data) => <TvGuideComponent {...data.view} />);
    const iso          = data.map('.iso');

    return bacon.combineTemplate({data, reactElement, iso});
};

TvGuideSimple.prototype.processTvGuideData = function (data) {
    const commingUp = get(data, 'commingUp["channel-programme"][0]', {});
    const nowPlaying = get(data, 'nowPlaying["channel-programme"][0]', {});

    return {
        commingUp: {
            id: commingUp.id,
            title: commingUp.title,
            live: commingUp.live,
            replay: (!commingUp.live && commingUp.statsFixtureId !== null),
            startTime: commingUp.startTime,
            endTime: commingUp.endTime
        },
        now: {
            id: nowPlaying.id,
            title: nowPlaying.title,
            live: nowPlaying.live,
            replay: (!nowPlaying.live && nowPlaying.statsFixtureId !== null),
            startTime: nowPlaying.startTime,
            endTime: nowPlaying.endTime
        }
    };
};

TvGuideSimple.prototype.getTvGuideStreams = function () {
    const commingUpStream = commingUpStreamByChannelAndSize({
        channel: this.config.channel,
        size:    this.config.size
    });

    const nowPlayingStream = nowPlayingStreamByChannel({
        channel: this.config.channel
    });

    return bacon.combineWith(
        [commingUpStream, nowPlayingStream],
        (commingUp, nowPlaying) => ({commingUp, nowPlaying})
    ).map(this.processTvGuideData.bind(this));
};

TvGuideSimple.prototype.processTvGuideProgrammesData = function (data) {
    const programmes = get(data, 'programmes["channel-programme"]', []).map((programme) => ({
        id: programme.id,
        programmeTitle: programme.programmeTitle,
        title: programme.title,
        live: programme.live,
        replay: (!programme.live && programme.statsFixtureId !== null),
        startTime: programme.startTime,
        endTime: programme.endTime
    }));

    return {programmes};
};

TvGuideSimple.prototype.getTvGuideProgrammesStreams = function () {
    const startDate = moment()
        .format('YYYY-MM-DD');

    const endDate = moment()
        .add(2, 'days')
        .format('YYYY-MM-DD');

    const programmesStream = programmesStreamByChannelAndDate({
        channel: this.config.channel,
        startDate,
        endDate
    });

    return bacon.combineWith(
        [programmesStream],
        (programmes) => ({programmes})
    ).map(this.processTvGuideProgrammesData.bind(this));
};

TvGuideSimple.prototype.getData = function (initialData) {
    const today = moment();
    const tomorrow = moment().add(1, 'days');

    const tvGuideTabsBus = new bacon.Bus();

    const tvGuideProgrammes = initialLaterOr(
        initialData && initialData.tvGuideProgrammes,
        this.getTvGuideProgrammesStreams()
    );

    const selectedTabIndex = bacon.update(
        0,
        [tvGuideTabsBus], (prevIndex) => (prevIndex) ? 0 : 1
    );
    const tvGuideProgrammesFilteredData = tvGuideProgrammes
        .combine(selectedTabIndex, (programmesResponse, tabIndex) => {
            const dateToFilter = (tabIndex === 0) ? today : tomorrow;

            if (programmesResponse.programmes) {
                return programmesResponse.programmes.filter((item) => moment(item.startTime).isSame(dateToFilter, 'day'));
            }
        });

    let tomorrowWasLastClick = false;

    return bacon.combineTemplate({
        view: bacon.combineTemplate({
            sportDetails: getSportDetails(this.config.sport),
            programmes: tvGuideProgrammesFilteredData,
            selectedTabIndex,
            tabs: [
                `Today (${displayDateFormat(today, 'DD/MM')})`,
                `Tomorrow (${displayDateFormat(tomorrow, 'DD/MM')})`
            ],
            onTabsClick: (isTomorrow) => {
                if ((tomorrowWasLastClick && !isTomorrow) || (!tomorrowWasLastClick && isTomorrow)) {
                    tvGuideTabsBus.push();
                }

                // @TODO, This shouldn't bleed to a scoped var holding state outside of a stream.
                // Additionally this stream-ified could have just used skipDuplicates to achieve a proper toggling.
                tomorrowWasLastClick = isTomorrow;
            }
        }),
        iso: bacon.combineTemplate({
            tvGuideProgrammes
        })
    });
};

TvGuideSimple.prototype.render = function (data) {
    if (this.element) {
        ReactDOM.render(
            <TvGuideComponent {...data.view} />,
            this.element
        );
    } else {
        return renderToHtml(
            <TvGuideComponent {...data.view} />,
            'hawkwidgets-tv-guide-simple',
            {
                settings: this.settings,
                data:     data.iso
            }
        );
    }
};

TvGuideSimple.prototype.remove = function () {
    try {
        this.closeStreams();
    } catch (e) {} // eslint-disable-line no-empty

    try {
        if (this.element) {
            ReactDOM.unmountComponentAtNode(this.element);
        }
    } catch (e) {} // eslint-disable-line no-empty
};

TvGuideSimple.prototype.pageBoot = function () {
    pageBoot(TvGuideSimple, 'hawkwidgets-tv-guide-simple');
};

pageBoot(TvGuideSimple, 'hawkwidgets-tv-guide-simple');

export default function (element, settings) {
    return new TvGuideSimple(element, settings);
}
