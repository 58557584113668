export const DEFAULT_VIMOND_ENV = 'prod';
export const DEFAULT_STREAM_TIMEOUT = 4000;
export const LIVE_VIDEO_POLL_FREQUENCY = 30 * 1000;

export const DEFAULT_MATCHES_COUNT = {
    complete: 4,
    upcoming: 4
};

/*
    Vimond Resources.
 */
export const PROXY_RESOURCE_URL = {
    prod: 'https://ottapi.foxsports.com.au/hawk-proxy-api/proxy/',
    staging: 'https://ottapi-staging.foxsports.com.au/hawk-proxy-api/proxy/'
};

/*
NOTE: we should be using the middle layer url, so try and avoid using this endpoint

export const VIMOND_API_URL = {
    prod: 'https://vmndapi.foxsports.com.au/api/',
    staging: 'https://vmndapi-staging.foxsports.com.au/api/'
};
*/

export const VIMOND_MIDDLELAYER_URL = {
    // prod: 'https://vmndmdl.foxsports.com.au/',
    // staging: 'https://vmndmdl-staging.foxsports.com.au/'
    prod: 'https://vmndapi.foxsports.com.au/',
    staging: 'https://vmndapi-staging.foxsports.com.au/'
};

export const VIMOND_IMAGE_URL = {
    prod: 'https://vmndims.foxsports.com.au/api/v2/img/',
    staging: 'https://vmndims-staging.foxsports.com.au/api/v2/img/'
};

export const VIMOND_EVENT_SERVICE_URL = {
    prod: 'https://vimond-event-service.ha.foxsport-second.vimondtv.com/external/player-events',
    staging: 'https://vimond-event-service.ha.foxsport-first.vimondtv.com/external/player-events'
};

export const MULTITENANCY_HEADER = {
    afl: {
        'x-vimond-tenant': 'foxsport'
    },
    league: {
        'x-vimond-tenant': 'watchnrl'
    }
};

/*
    Other APIs
 */
export const TV_GUIDE_API = 'https://tvguide.foxsports.com.au/granite-api/';

export const STATS_API_KEY = 'e6e36d37-8b5e-437f-9a0a-2f199bbc3425';
export const STATS_API_URL = 'https://statsapi.foxsports.com.au/3.0/api/';

/*
    Localstorage Auth details
 */
export const LOCAL_STORAGE_EXPIRES_IN = null;
export const AUTHENTICATION_LOCAL_STORAGE_KEY = 'auth';
export const USER_LOCAL_STORAGE_KEY = 'user';
export const SUBSCRIPTION_PACK_ID_LOCAL_STORAGE_KEY = 'subPackId';
export const SUBSCRIPTION_PACK_LOCAL_STORAGE_KEY = 'subPack';
export const DEVICE_UDID_LOCAL_STORAGE_KEY = 'hawkBrowserViewerId';
export const DEVICE_LABEL_STORAGE_KEY = 'hawkDeviceLabel';

/*
    Misc Images.
 */

export const ASSETS_URL = {
    prod: '/assets/',
    staging: '/assets/'
};

export const MEDIA_OTT_URL = 'https://media.foxsports.com.au/technology/resources/fe/ott';

export const CONTENT_URL = 'https://content.foxsports.com.au/ott';

export const TEAM_ID_NAME_MAP = {
    afl: [
        {id: 0,     name: 'VIP'},
        {id: 20001, name: 'Adelaide Crows'},
        {id: 20002, name: 'Brisbane Lions'},
        {id: 20003, name: 'Carlton Blues'},
        {id: 20004, name: 'Collingwood Magpies'},
        {id: 20005, name: 'Essendon Bombers'},
        {id: 20006, name: 'Fremantle Dockers'},
        {id: 20007, name: 'Geelong Cats'},
        {id: 20008, name: 'Hawthorn Hawks'},
        {id: 20009, name: 'North Melbourne Kangaroos'},
        {id: 20010, name: 'Melbourne Demons'},
        {id: 20011, name: 'Port Adelaide Power'},
        {id: 20012, name: 'Richmond Tigers'},
        {id: 20013, name: 'St Kilda Saints'},
        {id: 20014, name: 'Sydney Swans'},
        {id: 20015, name: 'West Coast Eagles'},
        {id: 20016, name: 'Western Bulldogs'},
        {id: 20017, name: 'Gold Coast Suns'},
        {id: 20018, name: 'Greater Western Sydney Giants'}
    ],
    league: [
        {id: 0, name: 'VIP'},
        {id: 55016, name: 'Brisbane Broncos'},
        {id: 55004, name: 'Canberra Raiders'},
        {id: 55005, name: 'Canterbury Bulldogs'},
        {id: 55006, name: 'Cronulla Sharks'},
        {id: 55044, name: 'Gold Coast Titans'},
        {id: 55009, name: 'Manly Sea Eagles'},
        {id: 55015, name: 'Melbourne Storm'},
        {id: 55007, name: 'Newcastle Knights'},
        {id: 55008, name: 'North Queensland Cowboys'},
        {id: 55010, name: 'Parramatta Eels'},
        {id: 55011, name: 'Penrith Panthers'},
        {id: 55017, name: 'South Sydney Rabbitohs'},
        {id: 55012, name: 'St George Illawarra Dragons'},
        {id: 55013, name: 'Sydney Roosters'},
        {id: 55003, name: 'Warriors'},
        {id: 55014, name: 'Wests Tigers'}
    ]
};

export const PAYMENT_PROVIDER_ID = {
    afl: {
        staging: 2,
        prod: 2
    },

    league: {
        staging: 25,
        prod: 25
    }
};
export const CALLBACK_URL = {
    afl: 'ott.foxsports.com.au',
    league: 'ott.foxsports.com.au'
};

export const SERIES_IDS = {
    afl: {
        afl: 1,
        pre: 2,
        aflw: 4,
        aflx: 6
    },
    league: {
        nrlPremiership:  1,
        testMatches:     2,
        stateOfOrigin:   3,
        cityCountry:     4,
        worldCup:        6,
        fourNations:     7,
        aucklandNines:   23,
        worldClubSeries: 24
    }
};

export const ROUND_TYPE = {
    rounds: 'round',    // default behaviour - group games by round
    days:   'day',      // e.g. show games on per calendar day
    none:   'none'      // e.g. show entire season at once
};

// A DIMENSION is a GA attribute that provides context to metrics
export const GOOGLE_DIMENSION_INDEX = {
    SUBSCRIPTION: 1,
    FAVOURITE_TEAM: 3,
    PAYMENT_PROCESS: 4,
    SUBSCRIPTION_CHANGE: 5,
    LOGGED_IN_STATUS: 6
};

export const LOGIN_REQUIRED_PARAM = 'required';

export const CLOSED_CAPTIONS_PREFERENCE_LOCAL_STORAGE_KEY = 'hawk-has-closed-captions-preference';
