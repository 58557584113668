import React from 'react';
import ReactDOM from 'react-dom';
import bacon from 'baconjs';
import isEqual from 'lodash/isEqual';

import {renderToHtml} from '@fsa/fs-commons/lib/iso/render';
import {page as pageBoot}  from '@fsa/fs-commons/lib/iso/boot';
import {handleStreamForIsoRender} from '@fsa/fs-commons/lib/streams/helper';
import pollUntil from '@fsa/fs-commons/lib/streams/poll-until';
import {asNumber} from '@fsa/fs-commons/lib/utils/normalise-arg';

import getAllTeamsAndRounds from '../streams/fixture-list/all-teams-and-rounds';
import getCurrentSeason from '../streams/fixture-list/current-season';
import getFixturesAndResults from '../streams/fixture-list/fixtures-and-results';
import getSeriesConfig from '../streams/fixture-list/get-series-config';
import FixtureListComponent from '../components/fixture-list';

const EXPIRY_SECONDS = 300;

function FixtureList(element, settings) {
    this.element  = element;
    this.settings = settings;

    this.config = {
        sport:        this.settings.sport || 'afl',
        seriesId:     asNumber(this.settings.seriesId, 1), // seriesId 1 is afl/nrl
        seasonId:     asNumber(this.settings.seasonId, undefined),
        currentRound: asNumber(this.settings.currentRound, undefined),
        statsApiKey:  this.settings.statsApiKey,
        vimondEnv:    this.settings.vimondEnv || 'prod'
    };
}

FixtureList.prototype.init = function (initialData = false) {
    this.closeStreams = this.getData(initialData)
        .onValue(this.render.bind(this));
};

FixtureList.prototype.initIso = function () {
    return new Promise((onResolve, onReject) => {
        this.closeStreams = this.getData()
            .take(1)
            .subscribe((event) => handleStreamForIsoRender({
                onReject,
                onResolve,
                event,

                identifier:            'HAWK: FixtureList',
                expirySecondOnFailure: 10,
                expirySecondOnSuccess: EXPIRY_SECONDS,
                render:                this.render.bind(this)
            }));
    });
};

FixtureList.prototype.initComponentStream = function (initialData = false) {
    const data         = this.getData(initialData);
    const reactElement = data.map((data) => <FixtureListComponent {...data.view} />);
    const iso          = data.map('.iso');

    return bacon.combineTemplate({data, reactElement, iso});
};

FixtureList.prototype.getData = function (initialData) {
    const toggleShowScoresClickBus = new bacon.Bus();
    const seriesIdBus = new bacon.Bus();
    const seasonIdBus = new bacon.Bus();
    const roundNumberBus = new bacon.Bus();
    const teamIdBus = new bacon.Bus();

    const {sport, vimondEnv, statsApiKey, seasonId, seriesId, currentRound} = this.config;

    const seriesList$ = pollUntil({
        streamToPoll: getSeriesConfig({sport}),
        pollWhileCallback: () => true,
        delayMs: 0,
        initialData: initialData && initialData.seriesList
    });

    const seriesSeasonId$ = bacon.update(
        {
            seriesId,
            seasonId
        },
        [seriesIdBus], (prev, seriesId) => ({seriesId, seasonId: null}), // reset season
        [seasonIdBus], (prev, seasonId) => Object.assign({}, prev, {seasonId})
    );

    const currentSeasonId$ = pollUntil({
        streamToPoll: seriesSeasonId$.flatMapLatest(({seriesId, seasonId}) => {
            if (seasonId) {
                return seasonId;
            } else {
                return getCurrentSeason({
                    sport,
                    seriesId,
                    vimondEnv
                }).map('.current_season.id');
            }
        }),
        pollWhileCallback: () => true,
        delayMs: 0,
        initialData: initialData && initialData.currentSeasonId
    });

    const currentSeriesAndSeason$ =  bacon.combineWith(
        seriesSeasonId$, currentSeasonId$,
        ({seriesId}, seasonId) => ({seriesId, seasonId})
    )
        .filter(({seriesId, seasonId}) => seriesId && seasonId)
        .skipDuplicates(isEqual);

    const allTeamsAndRounds$ = pollUntil({
        streamToPoll: currentSeriesAndSeason$.skip(initialData ? 1 : 0) // Skip the first event if we have initial data to stop us requesting twice
            .flatMapLatest(({seriesId, seasonId}) => (
                getAllTeamsAndRounds({sport, seasonId, seriesId, statsApiKey})
            )),
        pollWhileCallback: () => true,
        delayMs: 0,
        initialData: initialData && initialData.allTeamsAndRounds
    });

    const roundFromConfigOrSeriesSeasonCurrentRound$ = bacon.mergeAll(
        // The initial config MAY be set, use this value.
        bacon.later(0, currentRound)
            .skip(currentRound ? 0 : 1),

        // If we didn't have initial config, we want to use THIS round number (which will update later too)
        allTeamsAndRounds$.map('.current_season.current_round.number')
            .skip(currentRound ? 1 : 0)
    );

    const roundNumber$ = bacon.when(
        [roundFromConfigOrSeriesSeasonCurrentRound$], (number) => number,
        [roundNumberBus], (roundNumber) => roundNumber,
        [teamIdBus], () => null // You can't filter by round AND team, so these updates make it one or the other
    )
        .skipDuplicates(isEqual);

    const teamId$ = bacon.mergeAll(
        teamIdBus,
        allTeamsAndRounds$.map(null),
        roundNumberBus.map(null) // You can't filter by round AND team, so these updates make it one or the other
    )
        .toProperty(null)
        .skipDuplicates(isEqual);

    const filter$ = bacon.combineTemplate({
        seriesId: currentSeriesAndSeason$.map('.seriesId'),
        seasonId: currentSeriesAndSeason$.map('.seasonId'),
        roundNumber: roundNumber$,
        teamId: teamId$
    })
        .skipDuplicates(isEqual);

    const fixtures$ = pollUntil({
        streamToPoll: filter$.skip(initialData ? 1 : 0) // Skip the first event if we have initial data to stop us requesting twice
            .flatMapLatest(({seriesId, seasonId, roundNumber, teamId}) => (
                getFixturesAndResults({sport, seriesId, seasonId, roundNumber, teamId, vimondEnv})
            )),
        pollWhileCallback: () => true,
        delayMs: 0,
        initialData: initialData && initialData.fixturesAndResults
    });

    const roundLoading$ = bacon.mergeAll(
        filter$.map(true),
        fixtures$.map(false)
    ).toProperty(false);

    const showMatchScore$ = bacon.update(
        false,
        [toggleShowScoresClickBus], (toggleChecked) => !toggleChecked
    );

    return bacon.combineTemplate({
        view: bacon.combineTemplate({
            sport: this.config.sport,
            seriesList: seriesList$,
            currentSeriesId: filter$.map('.seriesId'),
            currentSeasonId: filter$.map('.seasonId'),
            currentRoundNumber: filter$.map('.roundNumber'),
            currentTeamId: filter$.map('.teamId'),
            roundsLoading: roundLoading$,
            onToggleShowScoresClick: (toggleChecked) => {
                toggleShowScoresClickBus.push(toggleChecked);
            },
            onClickUpdateSeries: (selectedSeriesId) => {
                seriesIdBus.push(selectedSeriesId);
            },
            onSelectTeamClick: (teamId) => {
                teamIdBus.push(teamId);
            },
            onSelectRoundClick: (roundNumber) => {
                roundNumberBus.push(roundNumber);
            },
            onSelectSeasonClick: (seasonId) => {
                seasonIdBus.push(seasonId);
            },
            showMatchScore: showMatchScore$,
            allTeamsAndRounds: allTeamsAndRounds$,
            fixturesAndResults: fixtures$
        }),
        iso: bacon.combineTemplate({
            seriesList: seriesList$,
            allTeamsAndRounds: allTeamsAndRounds$,
            currentSeasonId: currentSeasonId$,
            fixturesAndResults: fixtures$
        })
    });
};

FixtureList.prototype.render = function (data) {
    if (this.element) {
        ReactDOM.render(
            <FixtureListComponent {...data.view} />,
            this.element
        );
    } else {
        return renderToHtml(
            <FixtureListComponent {...data.view} />,
            'hawkwidgets-fixture-list',
            {
                settings: this.settings,
                data:     data.iso
            }
        );
    }
};

/**
 * Removes the widget from the page and closes streams
 *
 * @return {undefined}
 */
FixtureList.prototype.remove = function () {
    try {
        this.closeStreams();
    } catch (e) {} // eslint-disable-line no-empty

    try {
        if (this.element) {
            ReactDOM.unmountComponentAtNode(this.element);
        }
    } catch (e) {} // eslint-disable-line no-empty
};

export default function (element, settings) {
    return new FixtureList(element, settings);
}

/**
 * Calls the bootloader for the widget. The bootloader name is defined in fiso.js, e.g. 'video-mosaic'
 */
pageBoot(FixtureList, 'hawkwidgets-fixture-list');
