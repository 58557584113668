import isEqual from 'lodash/isEqual';

import type PlaybackNative from '.';
import type {
    AudioPreferences,
    AudioTrack,
    SupportedPlaybackHandler,
} from '../../types';
import sortAudioTracksByPreferences from '../../utils/sort-audio-tracks-by-preferences';
import type {DashAudioTrack} from '../dash/types';

export default class NativeAudio {
    playbackTech: PlaybackNative;
    videoElement: HTMLVideoElement;
    playbackHandler: SupportedPlaybackHandler;
    tracks: AudioTrack[] | DashAudioTrack[] = [];
    currentTrackIndex = -1;

    constructor(
        playbackTech: PlaybackNative,
        playbackHandler: SupportedPlaybackHandler
    ) {
        this.videoElement = playbackTech.videoElement as HTMLVideoElement;
        this.playbackTech = playbackTech;
        this.playbackHandler = playbackHandler;

        this.setup();
    }

    setup(): void {
        if (!this.#isAudioTrackListFeatureEnabled()) {
            return;
        }

        this.videoElement.audioTracks.addEventListener(
            'addtrack',
            this.onEventAudioTracksAddTrack
        );
    }

    destroy(): void {
        if (!this.#isAudioTrackListFeatureEnabled()) {
            return;
        }

        this.videoElement.audioTracks.removeEventListener(
            'addtrack',
            this.onEventAudioTracksAddTrack
        );
    }

    /**
     * Handler for addtrack event, so if audio tracks are available then initialise them based on config
     */
    onEventAudioTracksAddTrack = (): void => {
        if (!this.#isAudioTrackListFeatureEnabled()) {
            return;
        }

        /*
         * This is a workdaround as Video Element is not providing the channel count
         * (“English AAC”, “English AC3", “English EAC3”) are the formats that the data stream team provides
         * parse them into the expected channelCount for each audio format
         * this is not future proofed
         */
        const DEFAULT_CHANNEL_COUNTS_BY_LABEL: Record<string, number> = {
            AAC: 2,
            AC3: 6,
            EAC3: 8,
        };

        /**
         * Adding channelCount to audioTrack as this is a reference to
         * videoElement.audioTracks
         * Therefore to enable/disable any audio track we can just use
         * this.tracks[index].enable = true/false
         */
        const newTracksList = [...this.videoElement.audioTracks]
            .filter((audioTrack) => audioTrack.language)
            .map((audioTrack) => {
                const codec = audioTrack.label?.split(' ')[1] ?? 'AAC';

                const defaultChannelCount: number =
                    DEFAULT_CHANNEL_COUNTS_BY_LABEL[codec] ?? 2;

                // We can use codec to match against audio tracks for different assets so we maintain the closest one available for the user
                audioTrack.codec = codec;
                audioTrack.channelCount =
                    audioTrack.channelCount || defaultChannelCount;

                return audioTrack;
            });

        if (isEqual(this.tracks, newTracksList)) {
            return;
        }

        this.tracks = newTracksList;

        if (this.currentTrackIndex === -1) {
            if (this.playbackTech.options.audioPreferences) {
                const [preferredAudioTrack] =
                    sortAudioTracksByPreferences<AudioPreferences>(
                        this.tracks,
                        this.playbackTech.options.audioPreferences
                    );
                const preferredTrackIndex = this.tracks.findIndex(
                    (track) => track === preferredAudioTrack
                );

                this.currentAudioTrackIndex =
                    preferredTrackIndex === -1 ? 0 : preferredTrackIndex;
            } else {
                this.currentTrackIndex = 0;
            }
        }

        this.dispatchAudioTracksUpdatedEvent();
    };

    /**
     * Dispatches audio tracks updated event
     */
    dispatchAudioTracksUpdatedEvent(): void {
        this.videoElement.dispatchEvent(
            new CustomEvent('fs-audio-tracks-updated')
        );
    }

    /**
     * Returns the audio track list for the stream
     *
     * @returns The array of audio tracks minus the source buffer
     */
    get audioTracks(): AudioTrack[] {
        return this.tracks as AudioTrack[];
    }

    /**
     * Gets the active audio track's index
     *
     * @returns The index of the currently active audio track
     */
    get currentAudioTrackIndex(): number {
        return this.currentTrackIndex;
    }

    /**
     * Sets the active audio track's index
     *
     * @param index - index The index of the audio track
     */
    set currentAudioTrackIndex(index: number) {
        const currentAudioTrack = this.tracks[index];

        if (!currentAudioTrack) {
            return;
        }

        this.disableAudioTracks();

        (currentAudioTrack as AudioTrack).enabled = true;
        this.currentTrackIndex = index;

        this.dispatchAudioTracksUpdatedEvent();
    }

    /**
     * Disables audio track for the current media
     */
    disableAudioTracks(): void {
        if (!this.#isAudioTrackListFeatureEnabled()) {
            return;
        }

        [...this.videoElement.audioTracks] // Be strict and disable all video element text tracks irrespective of what textTracksList state holds
            .forEach((audioTrack) => {
                audioTrack.enabled = false;
            });

        this.currentTrackIndex = -1;

        this.dispatchAudioTracksUpdatedEvent();
    }

    /**
     * Checks if the audioTrackList feature is enabled in the browser
     *
     * TODO move to browser utils if it sticks around
     *
     * @returns The availability of theaudioTrackList feature
     */
    #isAudioTrackListFeatureEnabled(): boolean {
        return this.videoElement.audioTracks !== undefined;
    }
}
