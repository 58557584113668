import React from 'react';
import ReactDOM from 'react-dom';
import bacon from 'baconjs';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';

import {page as pageBoot}  from '@fsa/fs-commons/lib/iso/boot';
import {handleStreamForIsoRender, renderToHtml} from '@fsa/fs-commons/lib/iso/render';
import {asArray, asBool} from '@fsa/fs-commons/lib/utils/normalise-arg';

import VideoCarouselComponent from '../components/video-carousel';
import getVideosListStream from '../streams/end-points/videos-list';

import videoModalWidget from './video-modal';

const ISO_ERROR_EXPIRES_IN = 10;
const ISO_SUCCESS_EXPIRES_IN = 5 * 60;

function VideoCarousel(element, settings) {
    this.element  = element;
    this.settings = settings;
    this.config = {
        assetTypes:      asArray(this.settings.assetTypes, null),
        categoryIds:     asArray(this.settings.categoryIds, null),
        displaySpoilers: asBool(this.settings.displaySpoilers, true),
        fixtureId:       this.settings.fixtureId,
        groupBy:         this.settings.groupBy,
        matchId:         this.settings.matchId,
        page:            this.settings.page,
        roundNumber:     this.settings.roundNumber,
        seasonId:        this.settings.seasonId,
        seriesId:        this.settings.seriesId,
        size:            this.settings.size,
        sport:           this.settings.sport,
        supportUrl:      this.settings.supportUrl,
        teamIds:         asArray(this.settings.teamIds, null),
        text:            this.settings.text,
        title:           this.settings.title,

        vimondEnv:       this.settings.vimondEnv || 'prod'
    };

    // Get the modal ready (yay 1 click)
    videoModalWidget();

    if (this.settings.onClickVideo) {
        this.config.onClickVideo = this.settings.onClickVideo;
    } else {
        this.config.onClickVideo = (videoDetails) => {
            videoModalWidget().play({
                sport: this.config.sport,
                supportUrl: this.config.supportUrl,
                videoDetails,
                vimondEnv: this.config.vimondEnv
            });
        };
    }
}

VideoCarousel.prototype.init = function (initialData = false) {
    this.closeStreams = this.getData(initialData)
        .onValue(this.render.bind(this));
};

VideoCarousel.prototype.initIso = function () {
    return new Promise((onResolve, onReject) => {
        this.closeStreams = this.getData()
            .take(1)
            .subscribe((event) => handleStreamForIsoRender({
                onReject,
                onResolve,
                event,

                identifier: 'HAWK: Video-Carousel',
                expirySecondOnFailure: ISO_ERROR_EXPIRES_IN,
                expirySecondOnSuccess: ISO_SUCCESS_EXPIRES_IN,
                render: this.render.bind(this)
            }));
    });
};

VideoCarousel.prototype.initComponentStream = function (initialData = false) {
    const data         = this.getData(initialData);
    const reactElement = data.map((data) => <VideoCarouselComponent {...data.view} />);
    const iso          = data.map('.iso');

    return bacon.combineTemplate({data, reactElement, iso});
};

VideoCarousel.prototype.getData = function (initialData) {
    const {
        assetTypes,
        categoryIds,
        fixtureId,
        groupBy,
        matchId,
        page,
        roundNumber,
        seasonId,
        seriesId,
        size,
        sport,
        teamIds,
        text,
        title,

        vimondEnv
    } = this.config;

    const requestNewPage = new bacon.Bus();
    const currentPage = bacon.update(
        page || 0,
        [requestNewPage], (prevPage, maxPage) => {
            if (prevPage + 1 < maxPage) {
                return (prevPage + 1);
            }

            return prevPage;
        }
    ).skipDuplicates(isEqual);

    const requestVideosStream = currentPage.flatMapLatest((page) => {
        let returnStream;

        if (initialData && initialData.requestVideosStream) {
            returnStream = bacon.later(0, Object.assign({}, initialData.requestVideosStream));
        } else {
            returnStream = getVideosListStream({
                assetTypes,
                categoryIds,
                fixtureId,
                groupBy,
                matchId,
                page,
                roundNumber,
                seasonId,
                seriesId,
                size,
                sport,
                teamIds,
                text,
                title,

                vimondEnv
            }).map(({videos, videosPages}) => ({
                videosList: videos,
                videosPages
            }));
        }

        return returnStream;
    });

    const videosList = requestVideosStream.map('.videosList');
    const maximumPage = requestVideosStream.map('.videosPages');

    const videos = bacon.update(
        [],
        [videosList], (videoList, newVideos) => videoList.concat(newVideos)
    ).skip(process.browser ? 0 : 1); // don't let this stream through until it's populated on the server-side.

    const isLoading = bacon.mergeAll(
        currentPage.map(true),
        videos.map(false)
    ).toProperty(false);

    return bacon.combineTemplate({
        view: bacon.combineTemplate({
            isLoadingMoreContent: isLoading,
            videoThumbnailList: videos,
            isContentVisible: this.config.displaySpoilers,
            maximumPage,
            loadMoreContentFunc: (maxPage) => {
                requestNewPage.push(maxPage);
            },
            onClickVideo: this.config.onClickVideo
        }),
        iso: bacon.combineTemplate({
            requestVideosStream
        })
    }).doAction(() => {
        initialData = null; // eslint-disable-line no-param-reassign
    });
};

VideoCarousel.prototype.render = function (data) {
    if (this.element) {
        ReactDOM.render(
            <VideoCarouselComponent {...data.view} />,
            this.element
        );
    } else {
        return renderToHtml(
            <VideoCarouselComponent {...data.view} />,
            'hawkwidgets-video-carousel',
            {
                settings: omit(this.settings, ['onClick']),
                data:     data.iso
            }
        );
    }
};

VideoCarousel.prototype.remove = function () {
    try {
        this.closeStreams();
    } catch (e) {} // eslint-disable-line no-empty

    try {
        if (this.element) {
            ReactDOM.unmountComponentAtNode(this.element);
        }
    } catch (e) {} // eslint-disable-line no-empty
};

VideoCarousel.prototype.pageBoot = function () {
    pageBoot(VideoCarousel, 'hawkwidgets-video-carousel');
};

pageBoot(VideoCarousel, 'hawkwidgets-video-carousel');

export default function (element, settings) {
    return new VideoCarousel(element, settings);
}
