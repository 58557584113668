/**
 * Function to find out if avplayer should be used for tizen playback.
 *
 * @remarks
 * 2020 TVs which are tizen version 5.5 has issue with drm content playback and thus we will need to rely on EME/MSE
 * This check will be removed when Samsung have fixed their firmware.
 *
 * @param  isDrmContent - Flag indicating if content is DRM content
 * @returns whether avplayer should be used
 */
export function getShouldUseTizenAvplayer(): boolean {
    return false;
}

function _supportsPresentationMode(videoElement: HTMLVideoElement): boolean {
    // Note that at time of writing, Safari desktop supports it,
    // and Safari iOS has the webkitSupportsPresentationMode function, but doesn't support picture-in-picture
    // Also note that this support isn't available *immediately* - it does take a little while
    return (
        typeof videoElement.webkitSupportsPresentationMode === 'function' &&
        videoElement.webkitSupportsPresentationMode('picture-in-picture') &&
        typeof videoElement.webkitSetPresentationMode === 'function'
    );
}

function _supportsPictureInPicture(videoElement: HTMLVideoElement): boolean {
    // For other browsers, like Chrome
    return (
        typeof videoElement.requestPictureInPicture === 'function' &&
        !!document.pictureInPictureEnabled // eslint-disable-line compat/compat
    );
}

export function supportsPopOutPlayer(videoElement: HTMLVideoElement): boolean {
    return (
        _supportsPictureInPicture(videoElement) ||
        _supportsPresentationMode(videoElement)
    );
}

export function getIsInPopOutMode(videoElement: HTMLVideoElement): boolean {
    if (_supportsPresentationMode(videoElement)) {
        return videoElement.webkitPresentationMode === 'picture-in-picture';
    } else if (_supportsPictureInPicture(videoElement)) {
        return videoElement === document.pictureInPictureElement;
    } else {
        return false;
    }
}

export function setIsInPopOutMode(
    videoElement: HTMLVideoElement,
    shouldPopOut: boolean
): void {
    if (_supportsPresentationMode(videoElement)) {
        videoElement.webkitSetPresentationMode?.(
            shouldPopOut ? 'picture-in-picture' : 'inline'
        );
    } else if (_supportsPictureInPicture(videoElement)) {
        // Note these functions actually return Promises, but since we're in a `set` method we can't return them
        if (shouldPopOut) {
            // We choose to blindly request picture in picture rather than first exiting the current PiP session because
            // Chrome has issues with document.exitPictureInPicture() when the DOM element no longer exists (the promise never resolves)
            videoElement
                .requestPictureInPicture()
                .catch((err) =>
                    console.error(
                        'PlayerTech: Unable to enter PictureInPicture',
                        err
                    )
                );
        } else {
            // If we're exiting pop out mode we can indiscriminately close the document's PiP - even if it isn't our video element
            document
                .exitPictureInPicture()
                .catch((err) =>
                    console.error(
                        'PlayerTech: Unable to exit PictureInPicture',
                        err
                    )
                );
        }
    } else {
        console.warn(
            'PlayerTech: Unable to open a pop out player with either webkitPresentationMode.'
        );
    }
}
