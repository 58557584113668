import bacon from 'baconjs';
import get from 'lodash/get';
import noop from 'lodash/noop';

import {StreamotionWebPlayer} from '@fsa-streamotion/player-skin';
import BaseBaconWidget from '@fsa-streamotion/streamotion-web-bacon-widget';
import {getLocalStorageValue, setLocalStorageValue} from '@fsa/fs-commons/lib/utils/storage';

import {version as hawkWidgetsVersion} from '../../../package.json';
import PlayerMount from '../components/player-mount';
import {getDeviceUdid} from '../utils/api';
import {CLOSED_CAPTIONS_PREFERENCE_LOCAL_STORAGE_KEY, USER_LOCAL_STORAGE_KEY} from '../utils/constants';
import videoNormalisedEvents from '../utils/video-normalised-events';

const theme = {
    afl: {
        brandColor: '#cf0a2c',
        brandForegroundColor: '#fff',
        brandColorVariant: '#061321',
    },
    league: {
        brandColor: '#8af800',
        brandForegroundColor: '#002544',
        brandColorVariant: '#002544',
    },
};

const DEFAULT_PLAYER_SETTINGS = {
    numScreens: 1,
    customCallbacks: {
        onPlayerClosed: noop,
        onClickChromecast: noop,
        onRequestRelatedVideos: () => [],
        updateUpNextData: () => null,
        getAutoplayUpNextPreference: () => (0), // TODO: implement this callback in WEB-1947
        getCaptionsPreference: () => {
            const user = getLocalStorageValue({key: USER_LOCAL_STORAGE_KEY});

            if (!user) {
                return -1;
            }

            const captionPreference = get(
                getLocalStorageValue({key: CLOSED_CAPTIONS_PREFERENCE_LOCAL_STORAGE_KEY}),
                user.id,
                false,
            );

            return captionPreference ? 0 : -1;
        },
        setCaptionsPreference: (newIndex) => {
            const user = getLocalStorageValue({key: USER_LOCAL_STORAGE_KEY});

            if (!user) {
                return;
            }

            const captionPreference = newIndex >= 0;

            setLocalStorageValue({
                key: CLOSED_CAPTIONS_PREFERENCE_LOCAL_STORAGE_KEY,
                value: {
                    ...getLocalStorageValue({key: CLOSED_CAPTIONS_PREFERENCE_LOCAL_STORAGE_KEY, defaultValue: {}}),
                    [user.id]: captionPreference,
                },
            });
        },
        onScreenInteractionByType: noop,
        onPlayerInteractionByType: noop,
        onPlayingScreenClosed: noop,
        onRequestHudContent: noop,
        onUserInteraction: noop,
        onLayoutStyleChange: noop,
        onTrackOztamSeekAction: noop,
        onTrackOztamPlaybackRateChangeAction: noop,
    },
    theme: {
        brandColor: '#1787f7',
        brandColorVariant: '#050a19',
        brandForegroundColor: '#fff',
    },
    copy: {},
    uniqueDeviceId: '',
    canChangePlaybackSpeed: true,
    canHaveRelatedVideos: false,
    isHudEnabled: false,
    canUserViewHd: true,
    canUseAirplay: false,
    commonErrorData: {
        additionalErrorData: {
            appVersion: hawkWidgetsVersion,
            udid: getDeviceUdid(),
            player: 'VideoFS',
        },
    },
};

class StandalonePlayerWidget extends BaseBaconWidget {
    static widgetName = 'videofs-standalone-player';
    component = PlayerMount;
    player = null;
    playerTechRegisteredBus = new bacon.Bus();

    constructor(settings, element) {
        super(settings, element);

        Object.assign(this.config, {
            playbackData: settings?.sources?.length ? {
                id: settings.assetId,
                assetPlay: settings.assetId,
                options: {
                    // only when assets is live and liveFromStart is true we tell hls to play from start
                    // when startAt = -1, hls will start from edge for live and 0 for vod by default
                    startAt: settings.liveFromStart ? 0 : -1,
                    autoPlay: true
                },
                sources: settings.sources,
                markers: [],
                metadata: {
                    title: settings.title
                },
                thumbnailBIFs: {},
                contentItem: {},
                isClosedCaptionsEnabledForAsset: true,
            } : null

        });
    }

    getData() {
        this.player = new StreamotionWebPlayer({
            ...DEFAULT_PLAYER_SETTINGS,
            theme: theme[this.settings.sport] ?? theme.afl,
            customCallbacks: {
                ...DEFAULT_PLAYER_SETTINGS.customCallbacks,
                onPlayerClosed: this.settings.onClose,
            },

            // Temporary config override to address geo-blocking of resources.streamotion.com.au
            // TODO: Make player-tech and player-skin configurable to allow for custom config base URLs
            getHlsjsConfig: () => Promise.resolve({
                script: {
                    src: 'https://media.foxsports.com.au/watch/hls.min.js',
                    integrity: 'sha512-+63m9h8jLKfrwlgSH9QcP5YZR+MCvU1jfSZ4n3ICVv23ggU0/gqDU+4co3nBj3ZHBE8T5kcm/r1f8aOwZ5QIdQ==',
                    version: '1.4.10',
                },
            }),
        });

        const props$ = {
            onMount: (el) => { this.player.mountNode = el; },
            playerInstance: this.player,
            playbackData: this.config.playbackData,
            setVideoElementAndPlayerTech: ({videoElement, playerTech}) => {
                this.playerTechRegisteredBus.push({videoElement, playerTech});
            },
        };

        return bacon.combineTemplate({
            props: props$,
            hydration: {
                rendered: true,
            },
        });
    }

    getVideoNormalisedEvents() {
        return this.playerTechRegisteredBus.flatMapLatest(videoNormalisedEvents);
    }
}

export default function streamotionPlayer(settings = {}, element = null) {
    if (!Object.keys(settings).length) { // TODO: remove this check once we know all our implementations have been updated
        console.warn('No settings provided. Factory function now accepts args in order (settings, element)');
    }

    return new StandalonePlayerWidget(settings, element);
}

StandalonePlayerWidget.pageBoot();
