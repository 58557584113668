import React from 'react';
import propTypes from 'prop-types';
import classnames from 'classnames';
import isEqual from 'lodash/isEqual';
import noop from 'lodash/noop';

import {loadStyle} from '@fsa-streamotion/streamotion-web-browser-utils/src/lazy-load';

import ScrollList from '@fsa-streamotion/streamotion-web-fs-ui/src/components/scroll-list/scroll-list';
import Tabs from '@fsa-streamotion/streamotion-web-fs-ui/src/components/tabs/tabs';

import HawkMatchCard from './match-card';
import VideoCarouselSpoilerOverlay from './video-carousel-spoiler-overlay';

export default class ScoresPrePost extends React.Component {
    UNSAFE_componentWillMount() { // eslint-disable-line camelcase, react/sort-comp
        this.loadTeamLogoStylesheet(this.props.seriesList, this.props.sport);
    }

    UNSAFE_componentWillUpdate(newProps) { // eslint-disable-line camelcase
        if (!isEqual(newProps.seriesList, this.props.seriesList)) {
            this.loadTeamLogoStylesheet(newProps.seriesList, newProps.sport);
        }
    }

    loadTeamLogoStylesheet(seriesList, sport) {
        seriesList
            .forEach(function (seriesId) {
                loadStyle(`/assets/images/team-logos/${sport}/${seriesId}/208x156/${seriesId}.css`);
            });
    }

    renderMatchCard(item, index) {
        return (
            <li key={index} className="fiso-hawk-scores-pre-post__card">
                <HawkMatchCard {...item} />
            </li>
        );
    }

    renderCompletedMatches() {
        if (!Array.isArray(this.props.completedMatches) || !this.props.completedMatches.length) {
            return null;
        }

        const completedMatchesClassNames = classnames(
            'fiso-hawk-completed-matches__wrapper',
            {'fiso-hawk-completed-matches__wrapper--visible': (this.props.selectedTab === 1)}
        );

        return (
            <div className="fiso-hawk-completed-matches">
                <div className="fiso-hawk-scores-pre-post-tabs__non-mobile">
                    <Tabs
                        tabs={['Match Replays']}
                        selectedTabIndex={this.props.selectedTab} />
                </div>
                <div className={completedMatchesClassNames}>
                    <ScrollList
                        direction="horizontal"
                        iconNext="next-1"
                        iconPrevious="back-1">
                        <ul className="fiso-hawk-scores-pre-post__list fiso-hawk-completed-matches__list">
                            {this.props.completedMatches.map(this.renderMatchCard)}
                        </ul>
                    </ScrollList>
                    <div className="fiso-hawk-completed-matches__link-container">
                        <a
                            className="fiso-hawk-completed-matches__link"
                            href="/match-centre">
                            View All Match Replays
                        </a>
                    </div>
                </div>
            </div>
        );
    }

    renderUpcomingMatches() {
        if (!Array.isArray(this.props.upcomingMatches) || !this.props.upcomingMatches.length) {
            return null;
        }

        const upcomingMatchesClassNames = classnames(
            'fiso-hawk-upcoming-matches__wrapper',
            {'fiso-hawk-upcoming-matches__wrapper--visible': (this.props.selectedTab === 0)}
        );

        return (
            <div className="fiso-hawk-upcoming-matches">
                <div className="fiso-hawk-scores-pre-post-tabs__non-mobile">
                    <Tabs
                        tabs={['Upcoming Matches']}
                        selectedTabIndex={this.props.selectedTab} />
                </div>
                <div className={upcomingMatchesClassNames}>
                    <ScrollList
                        direction="horizontal"
                        iconNext="next-1"
                        iconPrevious="back-1">
                        <ul className="fiso-hawk-scores-pre-post__list fiso-hawk-upcoming-matches__list">
                            {this.props.upcomingMatches.map(this.renderMatchCard)}
                        </ul>
                    </ScrollList>
                    <div className="fiso-hawk-upcoming-matches__link-container">
                        <a
                            className="fiso-hawk-upcoming-matches__link"
                            href="/match-centre">
                            View All Upcoming Matches
                        </a>
                    </div>
                    {!this.props.isContentVisible && (
                        <VideoCarouselSpoilerOverlay
                            buttonText="Finals are here! Click to view upcoming matches"
                            onShowSpoilers={this.props.onShowSpoilers} />
                    )}
                </div>
            </div>
        );
    }

    renderTabsForMobile() {
        const tabsList = [];

        if (this.props.upcomingMatches) {
            tabsList.push('Upcoming Matches');
        }

        if (this.props.completedMatches) {
            tabsList.push('Match Replays');
        }

        if (!tabsList.length) {
            return null;
        }

        return (
            <div className="fiso-hawk-scores-pre-post-tabs__mobile">
                <Tabs
                    tabs={tabsList}
                    onTabClick={this.props.onTabClick}
                    selectedTabIndex={this.props.selectedTab} />
            </div>
        );
    }

    render() {
        return (
            <div className="fsui-normalise fiso-hawk-scores-pre-post">
                {this.renderTabsForMobile()}
                {this.renderUpcomingMatches()}
                {this.renderCompletedMatches()}
            </div>
        );
    }
}

ScoresPrePost.defaultProps = {
    completedMatches: [],
    onTabClick: noop,
    selectedTab: 0,
    seriesList: [],
    upcomingMatches: []
};

ScoresPrePost.propTypes = {
    completedMatches: propTypes.arrayOf(propTypes.object),
    isContentVisible: propTypes.bool,
    onTabClick: propTypes.func,
    onShowSpoilers: propTypes.func,
    selectedTab: propTypes.number,
    seriesList: propTypes.arrayOf(propTypes.number),
    sport: propTypes.string,
    upcomingMatches: propTypes.arrayOf(propTypes.object)
};
