import React from 'react';
import ReactDOM from 'react-dom';
import bacon from 'baconjs';
import get from 'lodash/get';

import pollUntil from '@fsa/fs-commons/lib/streams/poll-until';

import {page as pageBoot}  from '@fsa/fs-commons/lib/iso/boot';
import {renderToHtml, handleStreamForIsoRender} from '@fsa/fs-commons/lib/iso/render';

import AlertNoticeComponent from '../components/alert-notice';
import getAlertNoticeConfig from '../streams/alert-notice-config/get-alert-notice-config';

const ISO_ERROR_EXPIRES_IN = 10; // 10 secs
const ISO_SUCCESS_EXPIRES_IN = 1 * 60; // 1 minute.

const POLL_FREQ_MS = 30 * 1000;

function AlertNotice(element, settings) {
    this.element  = element;
    this.settings = settings;

    this.config = {
        alertNoticeConfigPath: settings.alertNoticeConfigPath,
        fixtureId: settings.fixtureId,
        sport: settings.sport || 'afl',

        vimondEnv: settings.vimondEnv || 'prod'
    };
}

AlertNotice.prototype.init = function (initialData = false) {
    this.closeStreams = this.getData(initialData)
        .onValue(this.render.bind(this));
};

AlertNotice.prototype.initIso = function () {
    return new Promise((onResolve, onReject) => {
        this.closeStreams = this.getData()
            .take(1)
            .subscribe((event) => handleStreamForIsoRender({
                onReject,
                onResolve,
                event,

                identifier: 'HAWK: Banner',
                expirySecondOnFailure: ISO_ERROR_EXPIRES_IN,
                expirySecondOnSuccess: ISO_SUCCESS_EXPIRES_IN,
                render: this.render.bind(this)
            }));
    });
};

AlertNotice.prototype.initComponentStream = function (initialData = false) {
    const data         = this.getData(initialData);
    const reactElement = data.map((data) => <AlertNoticeComponent {...data.view} />);
    const iso          = data.map('.iso');

    return bacon.combineTemplate({data, reactElement, iso});
};

AlertNotice.prototype.getData = function (initialData = false) {
    const alertNoticeConfig = pollUntil({
        streamToPoll: getAlertNoticeConfig({
            alertNoticeConfigPath: this.config.alertNoticeConfigPath,
            freq: POLL_FREQ_MS,
            sport: this.config.sport,
            vimondEnv: this.config.vimondEnv
        }),
        pollWhileCallback: () => true, // just keep pollin always!
        initialData: get(initialData, 'alertNoticeConfig'),
        delayMs: POLL_FREQ_MS
    });

    const matchedConfiguration = alertNoticeConfig
        .map((config) => get(config, this.config.fixtureId) || get(config, 'global'));

    const headerText = matchedConfiguration.map('.headerText');
    const bodyHtml = matchedConfiguration.map('.bodyHtml');

    return bacon.combineTemplate({
        view: bacon.combineTemplate({
            headerText,
            bodyHtml
        }),
        iso: bacon.combineTemplate({
            alertNoticeConfig
        })
    });
};

AlertNotice.prototype.render = function (data) {
    if (this.element) {
        ReactDOM.render(
            <AlertNoticeComponent {...data.view} />,
            this.element
        );
    } else {
        return renderToHtml(
            <AlertNoticeComponent {...data.view} />,
            'hawkwidgets-alert-notice',
            {
                settings: this.settings,
                data:     data.iso
            }
        );
    }
};

AlertNotice.prototype.remove = function () {
    try {
        this.closeStreams();
    } catch (e) {} // eslint-disable-line no-empty

    try {
        if (this.element) {
            ReactDOM.unmountComponentAtNode(this.element);
        }
    } catch (e) {} // eslint-disable-line no-empty
};

export default function (element, settings) {
    return new AlertNotice(element, settings);
}

/**
 *  * Calls the bootloader for the widget. The bootloader name is defined in fiso.js, e.g. 'video-mosaic'
 *   */
pageBoot(AlertNotice, 'hawkwidgets-alert-notice');
