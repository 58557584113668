import {parse} from 'url'; // eslint-disable-line import/no-nodejs-modules
// ^ this is browserify jerky jerk, this stuff exists!

import bacon from 'baconjs';
import get from 'lodash/get';
import noop from 'lodash/noop';
import youbora from 'youboralib';
import youboraAdapterHtml5 from 'youbora-adapter-html5';

import {filterEventOn} from './video-normalised-events';
import {getDeviceUdid} from './api';
import {getUserDetail} from './auth';

// import {getDeviceType} from './browser';
import {DEFAULT_VIMOND_ENV} from './constants';
// import {getSubscriptionPackId} from './auth';

/**
 * Fire seek begin when seek happens, and not buffer
 *
 * @this youboraAdapterHtml5
 * @returns {undefined}
 */
function handleSeekBegin() {
    if (this.flags.isBuffering) {
        return; // if it is a buffer event, don't fire seek
    }

    this.fireSeekBegin();
}

export function getYouboraTracking({
    sport = 'afl',
    videoNormalisedEvents = new bacon.Bus(),
    videoDetails = {},
    vimondEnv = DEFAULT_VIMOND_ENV
}) {
    const youboraPlugin$ = videoNormalisedEvents
        .filter(filterEventOn(['sourceupdate']))
        .flatMapLatest(({playerTech}) => {
            // Only track if we have a source.
            // Mounted _may_ have an initial source, but it's probably very unlikely (whole get the key thing)
            if (!playerTech.currentSource) {
                return bacon.never();
            }

            return bacon.fromBinder((sink) => {
                const {videoElement} = playerTech;
                const youboraPlugin = new youbora.Plugin({
                    // accountCode: 'foxsportsaustraliadev'
                    'accountCode': vimondEnv === 'prod' ? 'foxsportsaustralia' : 'foxsportsaustraliadev',
                    'content.transactionCode': getDeviceUdid()
                });

                const videoErrorHandler = (event) => {
                    // Custom error fs-fatal-error-retry is in magical detail object from the error. This is because videoFS won't store the error
                    // during a retry, and this is the only time we'll see what we're retrying.
                    // If it's not set, go to the playerTech.error as you would normally.
                    const error = get(event, 'detail.error') || playerTech.error;

                    // fireFatalError means it'll also do .stopMonitor() and make the 'level' fatal vs what fireError() does.
                    // Neither calls fireStop() though. The params remain the same between the two functions.
                    // (code, msg, metadata, level)
                    youboraPlugin.fireFatalError(
                        error?.code || 0,
                        error?.message || 'unknown',
                        {
                            fsFatalErrorRetry: event.type === 'fs-fatal-error-retry'
                        }
                    );

                    // Fire Stop right now. And if/when this stream closes, and it attempts to hit fireStop again, noop it so it's not fired twice.
                    // We're doing this because otherwise the fireStop waits for the stream to close from user interaction (say close the modal it's in).
                    youboraPlugin.fireStop();
                    youboraPlugin.fireStop = noop;
                };

                const videoBufferHandler = (event) => {
                    const {isBuffering = false, isSeeking = false} = get(event, 'detail', {});

                    if (isSeeking) {
                        return; // Handled by seek handler don't fire buffer
                    }

                    if (isBuffering) {
                        youboraPlugin.getAdapter().fireBufferBegin();
                    } else {
                        youboraPlugin.getAdapter().fireBufferEnd();
                    }
                };

                // Extend the HTML5 adapter with our fancy things for bitrate and error handling.
                const VideoFsHtmlAdapter = youboraAdapterHtml5.extend({
                    getPlayerName: () => 'VideoFS',
                    // Technically the version of videoFS we state here isn't very relevant, but we include it to be consistent with what we send to vimond and browser-youbora
                    getPlayerVersion: () => '4',
                    getResource: () => playerTech.currentSource,
                    getRendition: () => {
                        const {bitrateLevels, bitrateCurrentIndex} = playerTech;
                        const currentLevel = get(bitrateLevels, `[${bitrateCurrentIndex}]`, {});
                        const currentBitrate = get(bitrateLevels, `[${bitrateCurrentIndex}].bitrate`);

                        if (currentBitrate) {
                            if (currentLevel.name) {
                                return currentLevel.name;
                            } else {
                                return youbora.Util.buildRenditionString(currentLevel.width, currentLevel.height, currentLevel.bitrate);
                            }
                        } else {
                            return null;
                        }
                    },
                    getBitrate: () => {
                        const {bitrateLevels, bitrateCurrentIndex} = playerTech;
                        const currentBitrate = get(bitrateLevels, `[${bitrateCurrentIndex}].bitrate`);

                        if (currentBitrate) {
                            return currentBitrate;
                        } else {
                            return null;
                        }
                    },
                    getPlayrate: () => { // In before Ming comments on their invalid camelCase.
                        // During pause apparently our playRate needs to say 0.
                        if (!playerTech.isPlaying) {
                            return 0;
                        }

                        // Otherwise return the current playbackRate.
                        return playerTech.playbackRate;
                    },
                    getDroppedFrames: () => get(playerTech, 'diagnostics.droppedFrames'), // fantastically, this value resets when the source changes :D
                    seekingListener: handleSeekBegin,
                    errorListener: videoErrorHandler
                });

                // Manually listen to the error retry event, and fire off like we would on a full .error.
                videoElement.addEventListener('fs-fatal-error-retry', videoErrorHandler);
                videoElement.addEventListener('fs-stalled-buffering', videoBufferHandler);

                youboraPlugin.setOptions(getYouboraTrackingMetaData(videoDetails, sport, playerTech.currentSource));
                youboraPlugin.setAdapter(new VideoFsHtmlAdapter(videoElement));
                youboraPlugin.fireInit(); // about to start loading video;

                sink(youboraPlugin);

                return () => {
                    videoElement.removeEventListener('fs-fatal-error-retry', videoErrorHandler);
                    videoElement.removeEventListener('fs-stalled-buffering', videoBufferHandler);

                    // Youbora kill method.
                    youboraPlugin.fireStop();
                    youboraPlugin.removeAdapter();
                };
            });
        });

    // const youboraWindowUnload$ = youboraPlugin$.combine(
    //     process.browser ? bacon.fromEvent(window.document, 'beforeunload') : bacon.never(),
    //     (youboraPlugin) => youboraPlugin
    // ).doAction((youboraPlugin) => {
    //     youboraPlugin.fireStop();
    // });

    // @TODO Need clarification on handling the 'end' event for modals and things.
    // Akamai only really wants this once per 'session'.  Not multiple views.
    const stopped$ = videoNormalisedEvents
        .filter(filterEventOn(['stopped']))
        .combine(youboraPlugin$, (event, youboraPlugin) => ({event, youboraPlugin}))
        .doAction(({youboraPlugin}) => {
            youboraPlugin.fireStop();
        });

    return bacon.combineTemplate({
        youboraPlugin$,
        // youboraWindowUnload$,

        stopped$
    }).startWith(null);
}

export function getYouboraTrackingMetaData(videoData, sport, videoSource) {
    const userDetail = getUserDetail();
    const site = sport === 'afl' ? 'watchafl' : 'watchnrl';
    const assetTypeName = getVideoAssetType(videoData);
    const videoDeliveryType = getVideoDeliveryType(videoData);
    const videoSourceDetails = parse(videoSource);
    const resourceHostname = get(videoSourceDetails, 'hostname');

    return {
        'username': userDetail.id,

        'content.title': get(videoData, 'title', ''),
        'content.duration': get(videoData, 'accurateDuration', 0),
        'content.cdn': 'AKAMAI',
        'content.isLive': get(videoData, 'assetTypeName') === 'live',
        'content.metadata': {
            content_id: get(videoData, 'id', '')
        },

        'extraparam.1': site,

        'extraparam.6': get(videoData, 'categoryTitle', ''), // category
        'extraparam.7': get(videoData, 'metadata.fixture-id.content', ''), // eventName
        'extraparam.8': assetTypeName, // show
        'extraparam.9': videoDeliveryType, // deliveryType
        'extraparam.10': resourceHostname, // resourceHostname
        'extraparam.13': get(videoData, 'id') || ''
    };
}

export function getVideoAssetType(videoData) {
    switch (get(videoData, 'assetTypeName')) {
        case 'episode':
            return get(videoData, 'categoryTitle', '');

        default:
            return '';
    }
}

/**
 * Returns
 *     T for 24/7 channel (Fox Footy)
 *     L for live stream
 *     O for onDemand (VOD)
 *
 * @param  {Object} videoData Video Object from Vimond
 * @return {String}           Delivery Type mapped to Akamai tags.
 */
export function getVideoDeliveryType(videoData) {
    const videoTitle = get(videoData, 'title');
    const assetTypeName = get(videoData, 'assetTypeName');
    const linearChannelNames = ['Fox Footy 24/7', 'Fox League 24/7'];

    if (linearChannelNames.includes(videoTitle) && assetTypeName === 'live') {
        return 'T';
    } else if (assetTypeName === 'live') {
        return 'L';
    } else {
        return 'O';
    }
}
