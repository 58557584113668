import React from 'react';
import ReactDOM from 'react-dom';
import bacon from 'baconjs';

import {handleStreamForIsoRender} from '@fsa/fs-commons/lib/streams/helper';
import {page as pageBoot}  from '@fsa/fs-commons/lib/iso/boot';
import {renderToHtml} from '@fsa/fs-commons/lib/iso/render';
import {getSportNames as getSportDetails} from '@fsa/fs-commons/lib/utils/sport-names';

import GetAppComponent from '../components/get-app';

const ISO_ERROR_EXPIRES_IN = 10; // 10 secs
const ISO_SUCCESS_EXPIRES_IN = 5 * 60; // 5 minutes.

function GetApp(element, settings) {
    this.element  = element;
    this.settings = settings;

    this.config = {
        sport: this.settings.sport || 'afl',
        links: JSON.parse(this.settings.links)
    };
}

GetApp.prototype.init = function (initialData = false) {
    this.closeStreams = this.getData(initialData)
        .onValue(this.render.bind(this));
};

GetApp.prototype.initIso = function () {
    return new Promise((onResolve, onReject) => {
        this.closeStreams = this.getData()
            .take(1)
            .subscribe((event) => handleStreamForIsoRender({
                onReject,
                onResolve,
                event,

                identifier: 'HAWK: GetApp',
                expirySecondOnFailure: ISO_ERROR_EXPIRES_IN,
                expirySecondOnSuccess: ISO_SUCCESS_EXPIRES_IN,
                render: this.render.bind(this)
            }));
    });
};

GetApp.prototype.initComponentStream = function (initialData = false) {
    const data         = this.getData(initialData);
    const reactElement = data.map((data) => <GetAppComponent {...data.view} />);
    const iso          = data.map('.iso');

    return bacon.combineTemplate({data, reactElement, iso});
};

GetApp.prototype.getData = function () {
    return bacon.combineTemplate({
        view: bacon.combineTemplate({
            links: this.config.links,
            sportDetails: getSportDetails(this.config.sport)
        }),
        iso: bacon.combineTemplate({
        })
    });
};

GetApp.prototype.render = function (data) {
    if (this.element) {
        ReactDOM.render(
            <GetAppComponent {...data.view} />,
            this.element
        );
    } else {
        return renderToHtml(
            <GetAppComponent {...data.view} />,
            'hawkwidgets-get-app',
            {
                settings: this.settings,
                data:     data.iso
            }
        );
    }
};

GetApp.prototype.remove = function () {
    try {
        this.closeStreams();
    } catch (e) {} // eslint-disable-line no-empty

    try {
        if (this.element) {
            ReactDOM.unmountComponentAtNode(this.element);
        }
    } catch (e) {} // eslint-disable-line no-empty
};

GetApp.prototype.pageBoot = function () {
    pageBoot(GetApp, 'hawkwidgets-get-app');
};

export default function (element, settings) {
    return new GetApp(element, settings);
}

/**
 * Calls the bootloader for the widget. The bootloader name is defined in fiso.js, e.g. 'video-mosaic'
 */
pageBoot(GetApp, 'hawkwidgets-get-app');
